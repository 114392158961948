/* .signinPage {
  min-height: 100vh;
}
.signinPage .signinForm input {
  border: 1px solid #cfa79d !important;
}
.signinPage a {
  color: #cfa79d;
}

.signinPage a:hover {
  color: #cfa79d;
  text-decoration: none;
}

.instagramBtn {
  background: none;
  border: none;
}

@media (max-width: 991px) {
  .signinPage .signinForm .signinButton .signinIcon {
    display: inline-block;
    color: #cfa79d;
  }
  .signinPage .btn {
    background-color: #efefef;
    width: 75% !important;
    border-radius: 21px;
    margin: 15px 0;
  }
  .signinPage .logoLg {
    display: none;
  }
  .signinPage .clouds {
    display: none;
  }

  .signinPage .signinForm {
    text-align: center;
    width: 100%;
  }
  .signinPage .signinForm .helloWord {
    font-size: 61px;
    margin-top: 30px;
    font-weight: bold;
  }
  .signinPage .signinForm .signinWord {
    font-size: 15px;
    transform: translateY(-20px);
    margin-bottom: 30px;
  }
  .signinPage .signinForm .passwordContainer {
    width: 87%;
    max-width: 300px;
    margin: 0 auto;
  }

  .signinPage .signinForm input {
    width: 87%;
    height: 8vh;
    margin: 10px auto;
    border-radius: 21px;
    border: none;
    padding-left: 10px;
    max-width: 300px;
    padding: 10px;
  }

  .signinPage .signinForm input[type="password"],
  .signinPage .signinForm input[type="text"] {
    display: inline-block;
    width: 100% !important;
  }
  .signinPage .signinForm .signinEye {
    float: right;
    cursor: pointer;
    margin-right: 10px;
    transform: translateY(-8vh);
  }

  .signinPage .signinForm input::placeholder {
    padding: 10px;
    color: #cfa79d;
    font-size: 13px;
  }
  .signinPage .signinForm .forgetPassword {
    font-size: 14px;
    color: #cfa79d;
    margin-left: 15%;
    cursor: pointer;
  }
  .signinPage .signinForm .signinButton {
    font-weight: bold;
    font-size: 20px;
    margin: 20px 30px;
    cursor: pointer;
  }

  .signinPage .signinForm .or {
    font-weight: bold;
    font-size: 12px;
    margin-top: 10px;
    color: #3c3c3b;
  }

  .signinPage .signinForm .or::before,
  .signinPage .signinForm .or::after {
    content: "";
    display: inline-block;
    width: 12px;
    margin-right: 5px;
    margin-left: 5px;
    height: 1px;
    background-color: #d6d6d5;
    transform: translateY(-2px);
  }

  .signinPage .socialMediaIconsContainer {
    max-width: 400px;
    margin: 10px auto;
  }

  .signinPage .signinForm .socialMediaIconsContainer .socialMediaIcons {
    cursor: pointer;
  }
  .signinPage .noAccount {
    font-size: 16px;
  }
  .signinPage .noAccount .create {
    font-weight: bold;
    color: #cfa79d;
    text-decoration: underline;
    font-size: 19px;
  }
  .signinPage .logoSm {
    display: inline-block;
    margin: 20px auto;
  }

  .signinPage .SigninMan {
    display: none;
  }
}
@media (min-width: 992px) {
  .signinPage .logoLg {
    padding: 10px;
  }
  .signinPage .logoLg ~ div {
    height: 1px;
    background-color: #edeeef;
  }
  .signinPage .clouds {
    height: 13.5%;
    transform: translate(1%, 35vh) scale(0.8);
  }
  .signinPage .signinForm {
    text-align: center;
    padding: 0px;
    margin: 25px auto 0;
    border-radius: 70px;
    box-shadow: 0 -5px 24px rgba(113, 113, 113, 0.13);
    padding-bottom: 10px;
  }
  .signinPage .signinForm .helloWord {
    font-size: 50px;
    font-weight: bold;
  }
  .signinPage .signinForm .signinWord {
    font-size: 13px;
    transform: translateY(-15px);
  }
  .signinPage .signinForm .passwordContainer {
    width: 87%;
    margin: 0 auto;
  }
  .signinPage .signinForm input {
    width: 87%;
    height: 8vh;
    margin: 5px auto;
    border-radius: 21px;
    border: none;
    padding-left: 10px;
    padding: 10px;
  }

  .signinPage .signinForm input[type="password"],
  .signinPage .signinForm input[type="text"] {
    display: inline-block;
    width: 100% !important;
  }
  .signinPage .signinForm .signinEye {
    float: right;
    cursor: pointer;
    margin-right: 10px;
    transform: translateY(-6.5vh);
  }
  .signinPage .signinForm input::placeholder {
    color: #cfa79d;
    font-size: 13px;
  }
  .signinPage .signinForm .forgetPassword {
    font-size: 14px;
    color: #cfa79d;
    margin-top: 5px;
    margin-right: 12%;
    text-align: right;
    cursor: pointer;
  }
  .signinPage .signinForm .signinButton {
    font-weight: bold;
    font-size: 20px;
    cursor: pointer;
  }
  .signinPage .signinForm .signinButton .signinIcon {
    display: inline-block;
    margin-left: 1px;
    color: #cfa79d;
  }
  .signinPage .signinForm .or {
    font-weight: bold;
    font-size: 12px;
    color: #3c3c3b;
  }

  .signinPage .signinForm .or::before,
  .signinPage .signinForm .or::after {
    content: "";
    display: inline-block;
    width: 12px;
    margin-right: 5px;
    margin-left: 5px;
    height: 1px;
    background-color: #d6d6d5;
    transform: translateY(-2px);
  }

  .signinPage .signinForm .socialMediaIconsContainer .socialMediaIcons {
    cursor: pointer;
  }
  .signinPage .noAccount {
    font-size: 14px;
  }
  .signinPage .noAccount .create {
    font-weight: bold;
    color: #cfa79d;
    text-decoration: underline;
    font-size: 19px;
  }
  .signinPage .logoSm {
    display: none;
  }
  .signinPage .SigninMan {
    height: 54vh;
    transform: translate(50%, 50%);
  }

}
.signinPage .btn {
  background-color: #efefef;
  width: 75%;
  border-radius: 21px;
  margin: 15px 0;
} */


.signPage .signInlogo{
	width: 80px;
	margin: auto;
}

.signPage .authForm{
  padding-top: 0 !important;
}
.signPage .signinButton{
	background: #EFEFEF !important;
	width: 70%;
	text-align: center;
	margin: auto;
	border-radius: 20px !important;
	text-align: center !important;
  }
  .signPage .create,  .signinPage .create:hover{
    border-bottom: 1px solid;
    color:#CFA79D
  }
  @media (max-width:768px){
  .signPage .guestNav{
    display: none;
  }
}
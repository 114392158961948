
	.profInfo .subSpecializationContainer .subspecialization {
		height: 40px;
		padding-left: 7px;
		line-height: 40px;
		background-color: #cfa79d;
		border-radius: 21px;
		font-size: 14px;
		text-align: center;
		display: inline-block;
		margin: 10px 5px;
		color: #fff;
	}
	.profInfo .subSpecializationContainer .subspecialization .Cross {
		margin-left: 20px;
		margin-right: 10px;

		cursor: pointer;
	}

/* @media (max-width: 768px) {
	.profInfo {
		padding-bottom: 5px;
	}
	.profInfo .logo.sm {
		margin: 20px;
		text-align: center;
	}
	.profInfo .logo.lg {
		display: none;
	}
	.profInfo .progressBar.lg {
		display: none;
	}
	.profInfo .profInfoHeading {
		font-size: 22px;
		font-weight: bold;
		margin-left: 20px;
		margin-bottom: 20px;
	}
	.profInfo label,
	.profInfo .textareaHeading {
		font-weight: bold;
		font-size: 16px;
		margin-left: 30px;
	}
	.profInfo select {
		color: #b2b2b2;
		font-size: 13px;
	}

	.profInfo .covidAssesmentBox {
		margin-left: 30px;
		font-size: 16px;
		font-weight: bold;
	}
	.profInfo .covidAssesmentBox > span {
		display: inline-block;
		cursor: pointer;
		transform: scale(0.5);
	}
	.profInfo .errMsg {
		color: red;
		margin-left: 5px;
	}
	.profInfo label > span {
		color: #b9b9b9;
	}
	.profInfo .form-control {
		width: 80%;
		height: 40px;
		border: 1px solid #cfa79d;
		margin: 0px auto 10px;
		border-radius: 6px;
	}

	.profInfo .subSpecializationContainer {
		margin: 0 20px;
	}

	.profInfo .subSpecializationContainer .subspecialization {
		height: 40px;
		padding-left: 7px;
		line-height: 40px;
		background-color: #cfa79d;
		border-radius: 21px;
		font-size: 14px;
		text-align: center;
		display: inline-block;
		margin: 10px 5px;
		color: #fff;
	}
	.profInfo .subSpecializationContainer .subspecialization .Cross {
		margin-left: 20px;
		margin-right: 10px;

		cursor: pointer;
	}

	.profInfo .textareaHeading {
		font-size: 10px;
		margin-bottom: 10px;
	}
	.profInfo .form-control.textBox {
		height: 191px;
		resize: none;
	}
	.profInfo .ck-editor {
		width: 80%;
		margin: 0 auto;
	}

	.profInfo .btnContainer {
		text-align: center;
		margin: 20px;
	}

	.profInfo button {
		border: 1px solid #cfa79d;
		width: 130px;
		font-size: 13px;
		font-weight: bold;
		margin: 50px 10px 0;
		border-radius: 19px;
		color: #cfa79d;
		height: 30px;
	}

	.profInfo button:hover {
		background-color: #cfa79d;
		color: #fff;
	}
	.profInfo .progressBar.sm {
		width: 296px;
		height: 7px;
		border-radius: 7px;
		background: #d5d5d5;
		margin: 30px auto 0;
		margin-bottom: 20px;
	}
	.profInfo .progressBar.sm .value {
		background: #cfa79d;
		border-radius: 7px;
		height: 7px;
		width: 249px;
	}
	.profInfo .required {
		border-color: red;
	}
}
@media (min-width: 769px) {
	.profInfo {
		padding-bottom: 5px !important;
	}
	.profInfo .logo.lg {
		width: 20px;
		height: 20px;
		margin-top: 10px;
		margin-left: 20px;
		margin-bottom: 20px;
		padding: 10px;
	}
	.profInfo .logo.sm {
		display: none;
	}
	.profInfo .divider {
		height: 1px;
		background-color: #edeeef;
	}
	.profInfo .progressBar.lg {
		width: 297px;
		height: 7px;
		margin: 0px auto;
		background-color: #d5d5d5;
		transform: translateY(-3vh);
		border-radius: 7px;
	}
	.profInfo .progressBar.lg .value {
		background: #cfa79d;
		border-radius: 7px;
		height: 7px;
		width: 249px;
	}

	.profInfo .profInfoHeading {
		font-size: 22px;
		font-weight: bold;
		text-align: left;
		margin-left: 20px;
		margin-top: 20px;
		color: #707070;
		position: absolute;
		top: 80px;
	}
	.profInfo .profInfoForm {
		width: 32%;
		margin: 20px auto 0;
		padding: 20px;
		border-radius: 61px;
		box-shadow: 0 -5px 24px rgba(113, 113, 113, 0.13);
	}
	.profInfo label,
	.profInfo .textareaHeading {
		font-weight: bold;
		font-size: 16px;
		margin-left: 30px;
	}
	.profInfo select {
		color: #b2b2b2;
		font-size: 16px;
	}

	.profInfo .covidAssesmentBox {
		margin-left: 30px;
		font-size: 16px;
		font-weight: bold;
	}
	.profInfo .covidAssesmentBox > span {
		display: inline-block;
		cursor: pointer;
		transform: scale(0.5);
	}
	.profInfo .errMsg {
		color: red;
		margin-left: 5px;
	}
	.profInfo label > span {
		color: #b9b9b9;
	}
	.profInfo .form-control {
		width: 80%;
		height: 40px;
		border: 1px solid #cfa79d;
		margin: 0px auto 10px;
		border-radius: 6px;
	}

	.profInfo .subSpecializationContainer {
		text-align: left;
		padding-left: 20px;
	}
	.profInfo .subSpecializationContainer .subspecialization {
		display: inline-block;
		padding: 5px 10px;
		margin: 10px;
		text-align: center;
		background-color: #cfa79d;
		border-radius: 17px;
		font-size: 15px;
		font-weight: 600;
		color: #fff;
		text-align: center;
	}
	.profInfo .subSpecializationContainer .subspecialization .Cross {
		cursor: pointer;
		transform: scale(0.8);
		margin-left: 5px;
	}

	.profInfo .textareaHeading {
		font-size: 10px;
		margin-bottom: 10px;
	}
	.profInfo .form-control.textBox {
		height: 191px;
		resize: none;
	}
	.profInfo .ck-editor {
		width: 80%;
		margin: 0 auto;
	}

	.profInfo .btnContainer {
		text-align: center;
		margin: 20px;
	}

	.profInfo button {
		border: 1px solid #cfa79d;
		width: 130px;
		color: #cfa79d;
		font-size: 13px;
		font-weight: bold;
		margin: 50px 10px 0;
		border-radius: 19px;
		height: 30px;
	}

	.profInfo button:hover {
		background-color: #cfa79d;
		color: #fff;
	}
	.profInfo .progressBar.sm {
		display: none;
	}
	.profInfo .required {
		border-color: red;
	}
}
@media (min-width: 769px) and (max-width: 991px) {
	.profInfo .profInfoForm {
		width: 55%;
	}
}
@media (min-width: 992px) and (max-width: 1199px) {
	.profInfo .profInfoForm {
		width: 40%;
	}
}

.profInfoForm select {
	color: #000 !important;
} */

.termsAndCond .logo.sm ,.privacypolicy .logo.sm,.refundPolicy .logo.sm{
	display: none;
}
.termsAndCond .logo.lg ,.privacypolicy .logo.lg , .refundPolicy .logo.lg{
	margin: 20px;
}
.termsAndCond .divider ,.privacypolicy .divider,.refundPolicy .divider{
	background-color: #edeeef;
	height: 2px;
	width: 99%;
	margin: 20px auto;
}

.termsAndCond .main-title,.privacypolicy .main-title,.refundPolicy .main-title{
	font-weight: bold;
}
.termsAndCond ul{
	list-style:unset !important
}
.termsAndCond .logo{
	border-bottom: 2px solid #E2E2E2;
}
@media (max-width: 769px) {
	.termsAndCond .logo.lg,.privacypolicy .logo.lg ,.refundPolicy .logo.lg{
		display: none;
	}
	.termsAndCond .logo.sm ,.privacypolicy .logo.sm, .refundPolicy .logo.sm{
		display: block;
		margin: 10px 20px;
	}
}

.HomePageCalendar {
	font-size: 15px;
	font-weight: 500;
}
.HomePageCalendar .calendarHeaderTitle {
	margin: 20px 0 20px auto;
	text-align: center;
}
.HomePageCalendar .calendarHeaderContainer .CalendarControls {
	cursor: pointer;
	transform: translateY(10px);
}
.HomePageCalendar .divider {
	height: 2px;
	background-color: #edeeef;
}
.HomePageCalendar .timezoneNote {
	display: flex;
	align-items: center;
	font-size: 19px;
	font-weight: 500;
}
.HomePageCalendar .col-2{
	padding: 0 !important;
}
@media (max-width: 880px) {
	.HomePageCalendar .calendarHeaderContainer {
		display: none;
	}
}

.small-card__container {
  padding: 0 !important;
  border: 1px solid #fbf7ec;
  margin: 0 auto 50px;
  width: 100%;
}
.small-card__container .lastSeatsAlert {
  color: #f30e0e;
  font-weight: 600;
}
.small-card {
  width: 100%;
  height: 310px;
  display: flex;
}
.small-card__details {
  /* width: 100%; */
  width: 50%;
  flex: 1;
  padding: 20px;
  background-color: #fbf7ec;
}
.small-card__name {
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
}
.small-card__description {
  max-width: 100%;
}
.small-card__banner {
  /* min-width: 303px; */
  width: 303px;
  height: 100%;
  min-height: 310px;
}
.small-card__banner img {
  width: 100%;
  height: 100%;
}
.avatar {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: 20px solid #fbf7ec;
}
/* .small-card__container .date-container {
  width: 50% !important;
  text-align: end !important;
  background-color: #e6e6e1;
  border-radius: 50px 50px 0 0;
  font-size: 14px;
} */
.event-card-sm .event-container .event-content-container {
  position: relative;
}
.event-container .banner-container {
  margin: 0;
  width: 303px;
  height: 350px;
}
.event-container .banner-container > img {
  width: 100%;
  height: 100%;
}
/* @font-face {
  font-family: "GreekHouseSymbolized";
  src: url("../../../../../Fonts/GreekHouseSymbolized (2).ttf")
    format("truetype");
} */
.event-Name-container {
  /* font-family: "GreekHouseSymbolized"; */
  /* position: absolute; */
  word-break: break-all;
}
.event-container .divider {
  width: 100%;
  height: 1px;
  background-color: #cfa79d;
}
.event-container .guru-details {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}
.center-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.event-date-item {
  color: #cfa79d;
  font-size: 16px;
}

@media (max-width: 767px) {
  .event-date-item {
    font-size: 12px;
    font-weight: bold;
  }
}

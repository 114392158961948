.zoomControls{
    position: absolute;
    bottom: 0;
    color:#ABACAC;
    background: #212325;
    width: 100%;
}
.zoomControls .item{
    border-radius:10px;
    width:90px;
    margin: 2px;
    text-align: center;
    padding: 2px;
    position: relative;
}
.zoomControls .item:hover{
    background: #424345;
}

.zoomControls .item .icon{
    width: 30px;
    filter: invert(78%) sepia(6%) saturate(51%) hue-rotate(169deg) brightness(89%) contrast(84%);
}
.zoomControls .icon-info{
    font-size: 14px;
}
.zoomControls .arrowUp{
    position: absolute;
    top:5px;
    right:5px;
    width: 20px;
    filter: invert(78%) sepia(6%) saturate(51%) hue-rotate(169deg) brightness(89%) contrast(84%);
}
.zoomControls .unreadMsgCount{
    position: absolute;
    top:5px;
    right:10px;
    background-color: red;
    padding:0px 10px;
    border-radius:10px;
    font-size: 12px;
}
.zoomControls .endButton{
    background-color: red !important;
    border-radius: 10px;
    color:#fff;
    margin:0 10px;
    outline: none;
    border:none;
    padding: 5px 15px;
}

.zoomControls .lastUnreadMsgBox{
    background: #2D3035;
    color: #fff;
    position: absolute;
    bottom:105%;
    border-radius: 5px;
    z-index: 999999;
}
.zoomControls .lastUnreadMsgBox .title{
    font-weight: 500;
}
.zoomControls .lastUnreadMsgBox .message{
    font-size: 14px;
}
.zoomControls .closeIconContainer{
    transform: scale(0.8);
    cursor: pointer;
    margin: 0 10px;
}
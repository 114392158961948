.docAndVid .uploadVidText {
	font-size: 13px;
	color: #b9b9b9;
	margin: 0;
	width: 50%;
}
.docAndVid .uploadButton {
	background-color: #fff;
	border: 1px solid #cfa79d;
	color: #cfa79d;
	border-radius: 25px;
	font-weight: bold;
	cursor: pointer;
	padding: 3px 35px;
	text-transform: capitalize;
	font-size: 14px;
	width: fit-content;
}
.docAndVid .tipsContainer {
	color: #cacaca;
	font-size: 16px;
	margin-left: 20px;
}
.docAndVid .tipsContainer span {
	color: #cfa79d;
	font-weight: bold;
}
.docAndVid .itemName {
	display: flex !important;
	flex-direction: row !important;
	align-content: space-between;
	justify-content: space-between;
	max-width: max-content;
	padding: 5px 10px;
	margin: 5px 10px;
	text-align: center;
	background-color: #cfa79d;
	border-radius: 17px;
	font-size: 15px;
	font-weight: 600;
	color: #fff;
	text-align: center;
}
.docAndVid .itemName .Cross {
	width: 15px !important;
	height: 15px !important;
	margin-top: 7px;
	margin-left: 7px;
	cursor: pointer;
}
.docAndVid .loaderPercent {
	color: #cfa79d;
}

/* .docAndVid .itemName {
	display: flex !important;
	flex-direction: row !important;
	align-content: space-between;
	justify-content: space-between;
	max-width: max-content;
	padding: 5px 10px;
	margin: 5px 10px;
	text-align: center;
	background-color: #cfa79d;
	border-radius: 17px;
	font-size: 15px;
	font-weight: 600;
	color: #fff;
	text-align: center;
}
.docAndVid .loaderPercent {
	color: #cfa79d;
}
.docAndVid .itemName .Cross {
	width: 15px !important;
	height: 15px !important;
	margin-top: 7px;
	margin-left: 7px;
	cursor: pointer;
}
@media (max-width: 768px) {
	.docAndVid .logo.sm {
		margin: 20px;
		text-align: center;
	}
	.docAndVid .logo.lg {
		display: none;
	}
	.docAndVid .progressBar.lg {
		display: none;
	}
	.docAndVid .docVidHeading {
		font-size: 22px;
		font-weight: bold;
		margin-left: 20px;
		margin-bottom: 20px;
	}
	.docAndVid label {
		font-weight: bold;
		font-size: 16px;
		margin-left: 20px;
		text-transform: capitalize;
	}

	.docAndVid .itemName {
		display: inline-block;
		padding: 5px 10px;
		margin: 5px 10px;
		text-align: center;
		background-color: #cfa79d;
		border-radius: 17px;
		font-size: 15px;
		font-weight: 600;
		color: #fff;
		text-align: center;
	}

	.docAndVid .errMsg {
		color: red;
		margin-left: 5px;
	}
	.docAndVid .imageUploader {
		position: relative;
		margin-left: 20px;
	}
	.docAndVid .uploadButton {
		background-color: #fff;
		border: 1px solid #cfa79d;
		color: #cfa79d;
		border-radius: 25px;
		font-weight: bold;
		cursor: pointer;
		padding: 3px 35px;
		text-transform: capitalize;
		font-size: 14px;
	}

	.docAndVid #videoUploaderContainer,
	.docAndVid .videoUploader {
		cursor: pointer;
		text-align: center;
	}
	.docAndVid .uploadVidText {
		font-size: 13px;
		padding-right: 40%;
		margin-top: 0;
		color: #b9b9b9;
		transform: translateY(-10px);
		margin-left: 20px;
	}
	.docAndVid .tipsContainer {
		color: #cacaca;
		font-size: 16px;
		margin-left: 20px;
	}
	.docAndVid .tipsContainer span {
		color: #cfa79d;
		font-weight: bold;
	}
	.docAndVid .btnContainer {
		text-align: center;
		margin: 0 20px;
	}

	.docAndVid button {
		border: 1px solid #cfa79d;
		width: 130px;
		font-size: 13px;
		font-weight: bold;
		margin: 50px 10px 10px;
		border-radius: 19px;
		height: 30px;
		color: #cfa79d;
	}

	.docAndVid button:hover {
		background-color: #cfa79d;
		color: #fff;
	}

	.docAndVid .progressBar.sm {
		width: 296px;
		height: 7px;
		border-radius: 7px;
		background: #d5d5d5;
		margin: 30px auto 0;
		margin-bottom: 20px;
	}
	.docAndVid .progressBar.sm .value {
		background: #cfa79d;
		border-radius: 7px;
		height: 7px;
		width: 265px;
	}
}
@media (min-width: 769px) {
	.docAndVid .logo.lg {
		width: 20px;
		height: 20px;
		margin-top: 10px;
		margin-left: 20px;
		margin-bottom: 20px;
		padding: 10px;
	}
	.docAndVid .logo.sm {
		display: none;
	}
	.docAndVid .divider {
		height: 1px;
		background-color: #edeeef;
	}
	.docAndVid .progressBar.lg {
		width: 297px;
		height: 7px;
		margin: 0px auto;
		background-color: #d5d5d5;
		transform: translateY(-3vh);
		border-radius: 7px;
	}
	.docAndVid .progressBar.lg .value {
		background: #cfa79d;
		border-radius: 7px;
		height: 7px;
		width: 249px;
	}
	.docAndVid .docVidHeading {
		font-size: 22px;
		font-weight: bold;
		text-align: left;
		margin-left: 70px;
		margin-top: 20px;
		color: #707070;
	}
	.docAndVid .DocAndVidContainer {
		width: 32%;
		margin: 0px auto;
		padding: 20px;
		padding-top: 40px;
		border-radius: 61px;
		box-shadow: 0 -5px 24px rgba(113, 113, 113, 0.13);
	}
	.docAndVid label {
		font-weight: bold;
		font-size: 16px;
		margin-left: 20px;
		text-transform: capitalize;
	}
	.docAndVid label.label {
		margin-top: 60px;
	}
	.docAndVid .itemName {
		display: inline-block;
		padding: 5px 24px;
		margin: 5px 10px;
		text-align: center;
		background-color: #cfa79d;
		border-radius: 17px;
		font-size: 15px;
		font-weight: 600;
		color: #fff;
		text-align: center;
		position: relative;
	}

	.docAndVid .errMsg {
		color: red;
		margin-left: 5px;
	}
	.docAndVid .imageUploader {
		position: relative;
		margin-left: 20px;
	}
	.docAndVid .uploadButton {
		background-color: #fff;
		border: 1px solid #cfa79d;
		color: #cfa79d;
		border-radius: 25px;
		font-weight: bold;
		cursor: pointer;
		padding: 3px 35px;
		text-transform: capitalize;
		font-size: 14px;
	}
	.docAndVid .uploadButton:hover {
		background-color: #cfa79d;
		color: #fff;
	}
	.docAndVid #videoUploaderContainer,
	.docAndVid .videoUploader {
		cursor: pointer;
		text-align: center;
	}
	.docAndVid .uploadVidText {
		font-size: 13px;
		padding-right: 40%;
		margin-top: 0;
		color: #b9b9b9;
		transform: translateY(-10px);
		margin-left: 20px;
	}
	.docAndVid .tipsContainer {
		color: #cacaca;
		font-size: 16px;
		margin-left: 20px;
	}
	.docAndVid .tipsContainer span {
		color: #cfa79d;
		font-weight: bold;
	}
	.docAndVid .btnContainer {
		text-align: center;
		margin: 0 20px;
	}

	.docAndVid button {
		border: 1px solid #cfa79d;
		width: 130px;
		font-size: 13px;
		font-weight: bold;
		margin: 50px 10px 10px;
		color: #cfa79d;
		border-radius: 19px;
		height: 30px;
	}

	.docAndVid button:hover {
		background-color: #cfa79d;
		color: #fff;
	}

	.docAndVid .progressBar.sm {
		display: none;
	}
}
@media (min-width: 769px) and (max-width: 991px) {
	.docAndVid .DocAndVidContainer {
		width: 55%;
	}
}
@media (min-width: 992px) and (max-width: 1199px) {
	.docAndVid .DocAndVidContainer {
		width: 40%;
	}
} */

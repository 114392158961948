/*Start global styles*/

#ratingModal a {
	color: #CFA79D;
}
#ratingModal a:hover {
	color: #CFA79D;
	text-decoration: none;
}
#ratingModal .modal-content {
	height: 550px;
}
#ratingModal .modal-content .modal-header {
	border: none;
	padding: 20px 50px;
}

#ratingModal .modal-body {
	padding: 0;
	width: 100%;
	margin: 0;
}
#ratingModal .modal-header {
	border-bottom: none;
}

#ratingModal .title {
	font-size: 23px;
	font-weight: 600;
}

#ratingModal .modal-header .title span {
	font-weight: 300;
	display: block;
}
#ratingModal .modal-header .subTitle {
	text-align: center;
	color: #CFA79D;
	font-size: 20px;
	font-weight: 600;
}
#ratingModal .modal-body {
	background-color: #e8e8e8;
	padding: 20px 40px;
}
#ratingModal .modal-body .coachInfo .initials {
	display: inline-block;
	border-radius: 50%;
	background-color: #d4f0d4;
	width: 70px;
	height: 70px;
	line-height: 60px;
	text-align: center;
	display: inline-block;
	margin-right: 5px;
}
#ratingModal .modal-body .coachInfo .info {
	display: inline-block;
	transform: translateY(14px);
}

#ratingModal .modal-body .coachInfo .info .name {
	color: #CFA79D;
	font-size: 18px;
	font-weight: 600;
}

#ratingModal .coachRate {
	margin-left: 60px;
}
#ratingModal .coachRate > div {
	transform: scale(2);
	display: inline-block;
	margin: 10px;
	cursor: pointer;
}
#ratingModal .commentBox {
	margin: 10px 60px;
}
#ratingModal .commentBox > div {
	font-size: 18px;
	font-weight: 500;
}
#ratingModal .commentBox textarea {
	margin: 10px auto;
	resize: none;
	width: 100%;
	display: block;
	outline: none;
	height: 120px;
	border: 1px solid #CFA79D;
}

#ratingModal .btnContainer {
	text-align: center;
}

#ratingModal .btn {
	border-radius: 26px;
	padding: 0;
	width: 100px;
	display: inline-block;
	color: #CFA79D;
	background-color: #fff;
	margin: 10px;
	text-align: center;
}

#ratingModal .btn:hover {
	border: 1px solid #CFA79D;
}

/*End global styles*/
/*Start Review Container styles*/
#ratingModal .modal-header .reviewContainer {
	width: 100%;
}

#ratingModal .reviewContainer .btn.report {
	color: red;
	border: 1px solid red;
}

#ratingModal .reviewContainer .btn.report:hover {
	border: 1px solid red;
}
#ratingModal .reviewContainer .btn.report.sm {
	display: none;
}
#ratingModal .reviewContainer .anonTitle {
	text-align: center;
	color: #CFA79D;
}

#ratingModal .reviewContainer .anonTitle > span {
	margin-right: 5px;
	cursor: pointer;
}
/*End Review Container styles*/
/*Start report Box styles*/
#ratingModal .modal-header .reportBox {
	width: 100%;
}

#ratingModal .modal-header .reportBox .subTitle {
	color: #000;
}

#ratingModal .reportBox input {
	border: 1px solid red;
}
#ratingModal .reportBox textarea {
	margin: 20px auto;
	resize: none;
	width: 100%;
	display: block;
	outline: none;
	height: 160px;
	border: 1px solid red;
}
/* Ebd report Box styles*/

@media (max-width: 768px) {
	#ratingModal {
		max-width: 100%;
	}
	#ratingModal .modal-content {
		height: 100vh;
		width: 100%;
		border-radius: 0;
		border: none;
	}
	#ratingModal .modal-body {
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
	}
	#ratingModal .modal-body .coachInfo .initials {
		width: 60px;
		height: 60px;
		line-height: 60px;
	}
	#ratingModal .modal-body .coachInfo .info .name {
		font-size: 14px;
	}

	#ratingModal .modal-body .coachInfo .specialization {
		font-size: 13px;
	}

	#ratingModal .coachRate {
		margin-left: 0px;
	}
	#ratingModal .commentBox {
		margin: 10px 0px;
	}
	#ratingModal .btn {
		width: 80px;
		font-size: 14px;
	}
	#ratingModal .btn.report.sm {
		display: block;
		margin: 0 auto;
	}
	#ratingModal .btn.report.lg {
		display: none;
	}
}

.imageModal .crop-container {
	position: relative;
	width: 100%;
	height: 300px;
}

.imageModal .controls {
	display: flex;
	justify-content: center;
	margin-top: 10px;
}
.imageModal .tab {
	cursor: pointer;
}

.imageModal .reactEasyCrop_CropArea.reactEasyCrop_CropAreaGrid {
	border-radius: 50%;
	width: 200px !important;
	height: 200px !important;
}

.imageModal .btnContainer .btn {
	border: 1px solid #cfa79d;
	border-radius: 26px;
	border: 1px solid #cfa79d;
	padding: 1px 30px;
	margin: 10px;
	color: #cfa79d;
	font-weight: lighter;
}
.imageModal .btnContainer .btn:nth-of-type(2) {
	background-color: #cfa79d;
	color: #fff;
}

.homePage .LandingPage {
	margin-top: 90px;
}
ul {
	list-style: none;
}
.filterPageActive {
	position: fixed;
	width: 100%;
}
.homePage .landingPage {
	background: linear-gradient(rgba(73, 72, 72, 0.7), rgba(73, 72, 72, 0.2)),
		url("../../Assets/Welo-graphicBanner-tree.jpg") top center;
	background-size: cover;
	width: 100%;
	height: 450px;
	margin-top: 55px;
	position: relative;
	display: flex;
}
.homePage .landingPage .landingText{
	width: 50%;
	text-align: center;
	flex: 1;
}
.homePage .landingPage .landingText .textContainer {
	font-size: 30px;
	font-weight: 800;
	color: #ffffff;
	text-transform: uppercase;
	font-family: "Montserrat", sans-serif;	
	height: 80%;
	margin: 10px;
	display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.homePage .landingPage .events-section{
	color: #fff;
	flex: 1;
	width: 50%;
	display: flex;
	justify-content: center;
	margin: 20px 10px;
}
.homePage .landingPage .events-section .avatar-container{
	position: relative;
	width:fit-content;
	height: fit-content;
}
.homePage .landingPage .events-section .avatar{
	width: 100px;
	border-radius: 50%;
}
.homePage .landingPage .events-section .watchVideo{
	width: 34px;
    position: absolute;
    bottom: 0;
    right: 0;
	cursor: pointer;
}
.homePage .landingPage .events-section .content-section{
	background: rgba(255, 255, 255, 0.7);
	box-shadow: 0px 3px 6px #FFFFFF;
	border-radius: 20px;
	padding:10px 20px;
	color: #000;
	width: 300px;
	height: fit-content;
	margin-top: 30px;
	margin-left: 20px;
}
.homePage .landingPage .events-section .content-section .content{
	text-overflow: ellipsis;
	height: 150px;
	overflow: hidden;
}
.homePage .landingPage .landingText .textContainer .bannerLogo {
	width: 350px;
}

.homePage .landingPage .tabs {
	height: 75px;
	width: 100%;
	position: absolute;
	bottom: 0;
	left: 0;
	background-color: rgba(255, 255, 255, 0.7);
}
.homePage .landingPage .tabs .tab {
	text-align: center;
	text-transform: uppercase;
	font-size: 25px;
	font-weight: 500;
	height: 75px;
	position: relative;
	cursor: pointer;
	border-left: 1px solid #cfa79d;
	color: #000000;
}

.homePage .landingPage .tabs .tab:hover {
	background: rgba(207, 167, 157, 0.8);
	color: #ffff !important;
}

.homePage .landingPage .tabs .tab > div {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
.homePage .col-4 {
	padding: 0 !important;
	/* padding-right: 15px !important; */
}
.homePage .ant-cascader-picker {
	height: 88% !important;
}
.homePage .divider {
	height: 2px;
	background-color: #edeeef;
}
.homePageCalendarscroll {
	width: 100%;
	background-color: #fff;
	position: fixed;
	z-index: 40;
	right: 0;
	top: 0;
}

.homePage .landingPage .tabs .tab:hover .float-right svg {
	fill: #fff !important;
}

.videoPlayer {
	position: fixed;
	background-color: rgba(0, 0, 0, 0.7);
	width: 100%;
	height: 100%;
	z-index: 20;
}
.videoPlayer video {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 20;
	width: 80%;
}
.videoPlayer span {
	display: inline-block;
	color: #fff;
	background-color: #fff;
	width: 25px;
	height: 25px;
	position: absolute;
	top: 25px;
	right: 10px;
	transform: translate(-30%, -67%);
	z-index: 25;
	cursor: pointer;
	line-height: 25px;
	text-align: center;
	border-radius: 50%;
}
.homePage .pagination {
	display: table;
	margin: 0 auto;
}
.ant-cascader-menu li {
	padding: 22px 40px !important;
	font-size: 19px;
	font-weight: 700;
	text-transform: uppercase;
	font-family: "Montserrat", sans-serif;
}
.ant-cascader-menu li:hover {
	background-color: #d3b3ab !important;
}
.homePage .pagination > span {
	margin: 5px;
	width: 25px;
	height: 25px;
	line-height: 25px;
	display: inline-block;
	border-radius: 50%;
	text-align: center;
	cursor: pointer;
}
.homePage .pagination .active {
	background-color: #f8f2df;
}
.homePage .pagination .arrowLeft,
.homePage .pagination .arrowRight {
	transform: scale(0.8);
	cursor: pointer;
}
.homePage .ant-cascader-picker-label {
	font-family: "Montserrat", sans-serif;
	font-size: 22px !important;
	font-weight: 700;
	padding-right: 29px;
	padding-top: 5px;
}
/* .homePage .ant-cascader-menus .ant-cascader-menus-placement-bottomLeft  {
  font-family: "Montserrat", sans-serif !important;
  font-size: 19px !important;
  font-weight: 700 !important;
} */

@media (max-width:768px){
	.homePage .landingPage .events-section{
		display: none;
	}
}
@media (max-width: 880px) {
	.homePage .landingPage{
		margin-top: 0;
	}
	.homePage {
		margin-bottom: 100px;
	}

	.homePage .ant-cascader-picker-label {
		font-family: "Montserrat", sans-serif;
		font-size: 13px !important;
	}
	.homePage .landingPage .tabs svg {
		width: 0.8rem !important;
		height: 0.8rem !important;
	}
	.homePage .anticon {
		position: absolute;
		top: 55%;
	}
	.ant-cascader-menus .ant-cascader-menu li {
		font-size: 10px !important;
		padding: 22px 15px !important;
		white-space: pre-line !important;
	}

	.ant-cascader-picker-label {
		font-size: 16px !important;
		padding-left: 5px !important;
	}

	.homePage .landingPage .landingText .textContainer > div:first-of-type {
		font-size: 25px;
		padding-top: 5%;
	}
	.homePage .landingPage .landingText .textContainer > div:nth-of-type(2) {
		font-size: 35px;
		/* top: 39%; */
		margin: 0 10%;
	}

	.homePage .landingPage .tabs .tab {
		font-size: 14px;
		font-weight: 500;
	}

	.homePage .landingPage .tabs svg {
		width: 10px;
		height: 10px;
	}

	.homePage .SpecializationCard {
		margin: 10px auto;
		width: 50%;
		cursor: pointer;
		display: block;
		transition: all 0.4s ease-in-out;
		display: block;
	}

	.homePage .Specializationmenu {
		display: none;
	}
	.homePage .SpecializationCard:hover img {
		transform: scale(1.1);
	}
	.homePage .SpecializationCard:hover .Specializationmenu {
		display: block;
	}
	.homePage .SpecializationCard .SpecializationmenuItem:first-of-type {
		margin-top: 10px;
	}
	.homePage .SpecializationCard .SpecializationmenuItem {
		background-color: #ededed;
		color: #888888;
		margin-bottom: 2px;
		cursor: pointer;
		text-align: center;
	}
}
@media screen and (max-width: 992px) {
	.ant-cascader-menu {
		margin-right: 0px !important;
	}
	#three .row .contant,
	#three .row .contant h2 {
		font-size: 28px;
	}

	/* .ant-cascader-menus {
   position: absolute;
   top: 692px !important;
  width: 33% !important;
 } */
}

/* .anticon
{
    display: none !important;
} */
.homePage .anticon-close-circle {
	display: none;
}
.homePage .ant-cascader-picker-arrow svg {
	width: 1.2rem !important;
	height: 1.2rem !important;
	fill: black !important;
}
.homePage .ant-input {
	border: 0;
}
.homePage .ant-input:focus-visible {
	border: 0 !important;
	outline: none !important;
}
.homePage .ant-input:focus {
	box-shadow: none !important;
	border: none !important;
}
.ant-cascader-picker:focus {
	box-shadow: none !important;
	border: none !important;
}
.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled),
.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled):hover {
	background-color: transparent !important;
}

.ant-cascader-menu-item:hover {
	background: none !important;
}
.homePage .ant-cascader-menu-item {
	margin-bottom: 5px;
	padding: 20px 0px !important;
}
/* .ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled), .ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled):hover {
 
    background-color: transparent !important;
} */

.ant-cascader-menu {
	border: none !important;
	background-color: #f6f0dd !important;
	min-width: 100% !important;
}

.ant-cascader-picker {
	background-color: transparent !important;
	width: 100%;
}

.ant-cascader-menus {
	background-color: transparent !important;
	box-shadow: none !important;
}
.ant-cascader-menus .ant-cascader-menu {
	width: 100% !important;
	height: 5%;
}
.ant-cascader-menus ul {
	width: 100% !important;
}
.ant-cascader-picker-label {
	text-align: center;
	text-transform: uppercase;
	font-size: 25px;
	font-weight: 500;
	height: 67% !important;
}
.ant-cascader-picker-label:hover
	+ .ant-cascader-input:not(.ant-cascader-picker-disabled
		.ant-cascader-picker-label:hover
		+ .ant-cascader-input) {
	border: none !important;
}
@media (min-width: 881px) and (max-width: 991px) {
	.homePage .landingPage .landingText .textContainer > div:first-of-type {
		font-size: 25px;
	}
	.homePage .landingPage .landingText .textContainer > div:nth-of-type(2) {
		font-size: 35px;
	}

	.homePage .landingPage .tabs .tab {
		font-size: 20px;
		font-weight: 500;
	}
}

.ant-cascader-picker:focus .ant-cascader-input {
	box-shadow: none !important;
}
.ant-cascader-menus {
	box-shadow: none;
	width: 33%;
}

.category-button-container {
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
}

.category-button {
	margin: 1px;
	color: #000;
	font-size: 21px;
	font-weight: 700;
	text-transform: uppercase;
	font-family: "Montserrat", sans-serif;
	flex: 1;
	outline: none;
	border: none;
	opacity: 0.6;
}

.category-button:hover,
.category-button.selected {
	background-color: #cfa79d;
	opacity: 0.6;
	color: #fff;
}

.loader {
	border: 16px solid #f3f3f3;
	border-radius: 50%;
	border-top: 16px solid #cfa79d;
	width: 100px;
	height: 100px;
	-webkit-animation: spin 2s linear infinite; /* Safari */
	animation: spin 2s linear infinite;
	margin: 15px auto;
}
.loaderContainer {
	position: relative;
}
.loaderPercent {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	-webkit-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	font-size: 20px;
	font-weight: 600;
	color: #fff;
}

/* Safari */
@-webkit-keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
	}
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

#sessionDetails .close:focus {
	outline: none;
	border: none;
}
#sessionDetails .modal-title .viewSessionDetails > div,
#sessionDetails .modal-title .cancelSession > div,
#sessionDetails .modal-title .modifySession > div {
	font-weight: 300;
}
#sessionDetails .modal-content {
	/* border-radius: 82px;
	height: 550px; */
   border: none;
}
#sessionDetails .modal-content .modal-header {
	/* border: none;
	padding: 20px 50px; */
	padding: 20px 20px 20px 30px;
}
#sessionDetails .close {
	/* padding: 50px !important; */
	padding: 1rem 1rem;
margin: -1rem -1rem -1rem auto;
}

#sessionDetails .modal-body {
	padding: 0;
	width: 100%;
	margin: 0;
}
#sessionDetails .modal-body .viewSessionDetails,
#sessionDetails .modal-body .cancelSession,
#sessionDetails .modal-body .modifySession {
	/* height: 100%;
	background-color: #e8e8e8;
	border-radius: 82px;
	padding: 20px;
	width: 100%; */
	height: 100%;
	/* background-color: #171717; */
   background-color: #e8e8e8;
	/* border-radius: 82px; */
	border-radius: 0.3rem;
	padding: 20px;
	width: 100%;
}
#sessionDetails .modal-body .viewSessionDetails {
	text-align: center;
}

#sessionDetails .modal-body .PersonInfo {
	/* margin-bottom: 10px; */
	text-align: left;
margin: 20px 0 0 20px;
}
#sessionDetails .modal-body .PersonInfo .initials {
	background-color: red;
	width: 70px;
	height: 70px;
	line-height: 70px;
	text-align: center;
	border-radius: 50%;
	display: inline-block;
	background-color: #f6f0dd;
	color: #898989;
	font-weight: 500;
}
#sessionDetails .modal-body .PersonInfo .info {
	display: inline-block;
	text-align: left;
	margin-left: 10px;
	transform: translateY(10px);
}
#sessionDetails .modal-body .PersonInfo .info .name {
	color: #cfa79d;
	font-weight: bold;
}
#sessionDetails .modal-body .PersonInfo .info .name.client {
	transform: translateY(-15px);
}

#sessionDetails .modal-body .PersonInfo .info .specialization {
	font-weight: 400;
	font-size: 13px;
}
#sessionDetails .modal-body .sessionInfo {
	background-color: #fff;
	border-radius: 5px;
	text-align: left;
	padding: 10px 30px;
	/* margin-bottom: 30px; */
	font-size: 13px;
	font-weight: 500;
	box-shadow: 6px 10px 15px rgba(40, 40, 40, 0.1);
	cursor: pointer;
}
#sessionDetails .modal-body .sessionInfo .time,
#sessionDetails .modal-body .sessionInfo .date {
	display: inline-block;
}

#sessionDetails .modal-body .sessionInfo .time {
	float: right;
	transform: translateY(20px);
}
#sessionDetails .modal-body .viewSessionDetails .clientBox .title,
#sessionDetails .modal-body .viewSessionDetails .mentorBox .title {
	font-weight: bold;
	text-align: left;
	margin: 20px 0 10px 15px;
	color: #707070;
}
#sessionDetails .modal-body textarea {
	resize: none;
	height: 140px;
	width: 90%;
	margin: auto;
	border: 1px solid #cfa79d;
}

#sessionDetails .modal-body .viewSessionDetails .btn {
	/* display: block; */
	/* margin: auto; */
	width: 150px;
	height: 30px;
	line-height: 30px;
	padding: 0;
	/* border-radius: 20px; */
	font-size: 14px;
	font-weight: 500;
	box-shadow: 4px 6px 10px rgba(186, 186, 184, 0.4);
	margin: 10px 10px 0;
}
#sessionDetails .modal-body .viewSessionDetails .btn:first-of-type {
	background-color: #fe0100;
	color: #fff;
	/* margin-top: 40px; */
}


#sessionDetails .modal-body .viewSessionDetails .disable:first-of-type {
	background-color: #a8a8a8;
	color: #dddddd;
	/* margin-top: 10px; */
}

#sessionDetails .disable {
	background-color: yellow;
}
/*Ending viewSessionDetails styles*/
/*Start  cancel session page*/
#sessionDetails .modal-content .modal-header .cancelSession {
	margin-bottom: 50px;
}
#sessionDetails .modal-body .cancelSession .sessionInfo {
	width: 60%;
	margin: 10px auto;
}
#sessionDetails .modal-body .cancelSession .title {
	text-align: center;
	margin: 50px auto;
	font-size: 23px;
	font-weight: 500;
	color: #707070;
}

#sessionDetails .modal-body .cancelSession .btnContainer {
	text-align: center;
	margin-top: 10px;
}
#sessionDetails .modal-body .cancelSession .btn {
	width: 150px;
	height: 30px;
	line-height: 30px;
	padding: 0;
	border-radius: 20px;
	font-size: 14px;
	font-weight: 500;
	box-shadow: 4px 6px 10px rgba(186, 186, 184, 0.4);
	margin: 0 10px;
}
#sessionDetails .modal-body .cancelSession .btn:first-of-type {
	background-color: #fff;
	color: #cfa79d;
}

#sessionDetails .modal-body .cancelSession .btn:nth-of-type(2) {
	background-color: #fe0100;
	color: #fff;
}

/*Ending  cancel session page*/
/*Starting  Confirm cancel session page*/

#sessionDetails .modal-body .confirmCancelSession img {
	margin: 0 auto;
	display: block;
}

#sessionDetails .modal-body .confirmCancelSession .sessionInfo {
	width: 350px;
	margin: 10px auto;
}
#sessionDetails .modal-body .confirmCancelSession .PersonInfo {
	/* margin: 10px auto;
	text-align: center; */
	text-align: center;
	margin: 20px 0 0 20px;
}

#sessionDetails .modal-body .confirmCancelSession .PersonInfo .info {
	display: block;
	margin: 0px auto;
	text-align: center;
}
#sessionDetails .modal-body .confirmCancelSession .priceAndBtn {
	text-align: center;
}
#sessionDetails
	.modal-body
	.confirmCancelSession
	.priceAndBtn
	> span:first-of-type {
	color: #707070;
	font-weight: bold;
}
#sessionDetails
	.modal-body
	.confirmCancelSession
	.priceAndBtn
	> span:nth-of-type(2) {
	color: #cfa79d;
}
#sessionDetails .modal-body .confirmCancelSession .priceAndBtn .btn {
	display: block;
	border: 1px solid #cfa79d;
	color: #fff;
	background-color: #cfa79d;
	border-radius: 60px;
	font-size: 14px;
	padding: 0;
	width: 120px;
	height: 27px;
	margin: 10px auto;
}
/*Ending   Confirm cancel session page*/
/*Start modify session Page*/
#sessionDetails .modal-body .modifySession {
	padding: 20px 50px;
}

#sessionDetails .modal-body .modifySession .dateTimeTitle {
	color: #cfa79d;
	font-weight: 600;
	font-size: 23px;
	margin: 20px;
}
/*Start calendar customization*/

#sessionDetails .modal-body .modifySession .datepicker-header {
	display: none;
}
#sessionDetails .modal-body .modifySession .dayName,
#sessionDetails .modal-body .modifySession .nextDayName,
#sessionDetails .modal-body .modifySession .prevDayName {
	position: absolute;
	font-size: 22px;
	color: #474747;
	z-index: 3;
	top: 115px;
}
#sessionDetails .modal-body .modifySession .nextDayName {
	top: 155px;
	opacity: 0.4;
}
#sessionDetails .modal-body .modifySession .prevDayName {
	top: 80px;
	opacity: 0.4;
}
#sessionDetails .modal-body .modifySession .datePicker .pickTimeSlot.lg {
	display: inline-block;
	width: 47%;
	text-align: center;
	font-weight: bold;
	transform: translateY(20px);
}
#sessionDetails .modal-body .modifySession .datepicker {
	background-color: #e8e8e8 !important;
	position: relative;
	top: -20px;
	width: 100% !important;
}

#sessionDetails
	.modal-body
	.modifySession
	.datepicker.ios
	.datepicker-viewport::after {
	background: linear-gradient(
		#e8e8e8,
		rgba(245, 245, 245, 0) 60%,
		rgba(245, 245, 245, 0) 30%,
		#e8e8e8
	);
}
#sessionDetails .modal-body .modifySession .datepicker.ios {
	height: 250px;
}
#sessionDetails .modal-body .modifySession .datepicker.ios .datepicker-navbar {
	border: none;
}

#sessionDetails .modal-body .modifySession .datepicker-navbar-btn:hover,
#sessionDetails .modal-body .modifySession .backButton:hover {
	border: 1px solid #cfa79d !important;
	background-color: #e8e8e8 !important;
	text-decoration: none;
}

#sessionDetails
	.modal-body
	.modifySession
	.datepicker-navbar-btn:nth-of-type(2) {
	display: none;
}
#sessionDetails .modal-body .modifySession .datePicker .divider:nth-of-type(2) {
	margin-top: 37px;
}
/*End calendar customiztion*/
/*Start time table*/
#sessionDetails .modal-body .modifySession .timeStamp {
	width: 20%;
}
#sessionDetails .modal-body .modifySession .timeSlots {
	height: 220px;
	overflow: scroll;
	overflow-y: none;
	transform: translateY(-20px);
}
#sessionDetails .modal-body .modifySession .timeSlots::-webkit-scrollbar-thumb {
	background: #cfa79d;
}
#sessionDetails .modal-body .modifySession .timeSlots::-webkit-scrollbar {
	width: 5px;
	height: 5px;
}
#sessionDetails .modal-body .modifySession .timeSlot {
	width: 100px !important;
	height: 40px;
	font-size: 14px;
	background-color: #dbdbdb;
	text-align: center;
	line-height: 40px;
	border-radius: 6px;
	margin: 10px auto;
	cursor: pointer;
	transition: all 0.3s ease-in-out;
}
.sessioncalendar .active {
	background-color: #cfa79d !important;
	color: #000 !important;
}

#sessionDetails .modal-body .modifySession .timeSlot:hover {
	background-color: #cfa79d;
	color: #707070;
}

#sessionDetails .modal-body .modifySession .pickTimeSlot.sm {
	display: none;
}
/*End time table*/
#sessionDetails .modal-body .modifySession .btnContainer {
	text-align: center;
}
#sessionDetails .modal-body .modifySession .btn {
	width: 150px;
	height: 30px;
	line-height: 30px;
	padding: 0;
	border-radius: 20px;
	font-size: 14px;
	font-weight: 500;
	box-shadow: 4px 6px 10px rgba(186, 186, 184, 0.4);
	margin: 0 10px;
	background-color: #fff;
	color: #cfa79d;
	transform: translateY(-20px);
}
#sessionDetails .modal-body .modifySession .btn:hover {
	border: 1px solid #cfa79d;
}

#sessionDetails .chatting-box{
	height: 200px;
	overflow-y: auto;
	width: 80%;
	margin: 10px auto;
	background: #fff;
	border-radius: 10px;
	border: 1px solid #CFA79D;
	text-align:left;
	padding: 1rem;
	-ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}

#sessionDetails .chatting-box::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}
#sessionDetails .chatting-box .initials{
	width: 40px;
	height: 40px;
	display: inline-block;
	border-radius: 50%;
	background: #e8e8e8;
	line-height: 40px;
    font-weight: 500;
	text-align: center;
}
#sessionDetails .chatting-box  .chatting-slot{
	display: flex;
	align-items: self-start;
}
#sessionDetails .send-box{
	width: 80%;
	margin: 10px auto;
	background: #fff;
	border-radius: 10px;
	border: 1px solid #CFA79D;
}
#sessionDetails .send-box textarea{
	height: 50px;
	margin-top: 10px;
	border: none;
	outline:none;
	border-radius: 15px;
	color: #CFA79D;
}
#sessionDetails .isNotSender .msg-container{
	background: #CFA79D;
	color: #fff;
	border:1px solid #fff;
	overflow: auto;
}

#sessionDetails .send-box .send-button{
	border: none;
	background: #fff;
	outline: none;
}
#sessionDetails .send-box textarea::-webkit-scrollbar {
	display: none;
  }
  
/* Hide scrollbar for IE, Edge and Firefox */
#sessionDetails .send-box textarea {
	-ms-overflow-style: none;  /* IE and Edge */
	scrollbar-width: none;  /* Firefox */
}
#sessionDetails .send-box .divider{
	border:1px solid #EDDEDA
}


#sessionDetails .msg-container{
	height: fit-content;
	border-radius: 10px;
	border: 1px solid #CFA79D;
	display: flex;
	flex: 1;
}
@media (max-width: 769px) {
	#sessionDetails .chatting-box,#sessionDetails .send-box{
		width:100%;
	}
	#sessionDetails {
		max-width: 100%;
	}
	.modal-dialog {
		margin: 0 !important;
	}
	#sessionDetails .modal-content {
		border-radius: 25px;
		height: 100vh;
		width: 100%;
		border: none;
	}

	#sessionDetails .modal-body .cancelSession .sessionInfo {
		width: 80%;
	}
	#sessionDetails .modal-body .viewSessionDetails,
	#sessionDetails .modal-body .cancelSession,
	#sessionDetails .modal-body .confirmCancelSession,
	#sessionDetails .modal-body .modifySession {
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
	}

	#sessionDetails .modal-body .modifySession {
		padding: 20px;
	}

	#sessionDetails .modal-body .modifySession .timeSlots {
		transform: translateY(0px);
	}
	/* .viewSessionDetails {
		margin-top: 87px;
	} */
}

.myDocuments {
	overflow-y: scroll;
	height: 75vh;
}
.myDocuments .heading .icon {
	transform: scale(0.8);
}

.myDocuments .heading > div {
	font-weight: 700;
	font-size: 23px;
}
.myDocuments .documentsContent {
	margin-left: 20px;
}

.myDocuments .imageIcon,
.myDocuments .pdfIcon {
	cursor: pointer;
}
.myDocuments .text-btn {
	font-size: 12px;
	font-weight: 500;
	text-decoration: underline;
	cursor: pointer;
}
.myDocuments .deleteSign {
	font-size: 12px;
	font-weight: 600;
	text-decoration: underline;
	color: red;
	cursor: pointer;
}

.myDocuments .btn {
	width: unset !important;
	padding: 2px 30px;
}
.myDocuments .btn:hover {
	color: #fff;
}

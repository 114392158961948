.blog-details .cover-figure{
    width: 100%;
    height: 250px;
    background-size: cover;
    border-bottom-left-radius: 40px;
    border-bottom-right-radius: 40px;
}

.blog-details .cover-figure .title{
    color: #fff;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;    
    font-size: 20px;
    font-weight: bold;
}
.blog-details .cover-figure .likes-count{
    color: #fff;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;    
    font-weight: bold;
}
.blog-details .cover-figure .color-white{
    color: #fff;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;    
}
.blog-details .guruImage-figure{
    width:100px;
    height:100px;
}
.blog-details .main-title{
    color: #727271;
    font-weight: bold;
    font-size: 45px;
}

.blog-details .guruImage-figure img{
    border-radius: 50%;
    width: 100%;
    height: 100%;
        
}
.blog-details .guruName{
    color: #fff;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}

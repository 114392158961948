
.blogCard .category{
    color:#CFA79D;
    font-weight:600;
}
.blogCard .createdAt{
    color:#D8D8D8;
    font-weight:600;
}
.blogCard .title{
    color:#000 !important;
    font-size:25px;
    font-weight: bold;
}
.blogCard .description{
    font-weight: 500;
    color:#000;
    max-height: 26px;
    white-space: pre-wrap;
    overflow: hidden;
}
.blogCard .divider{
    width: 100%;
    height: 2px;
    background-color: #F3F3F3;
}

.blogCard .cover-container{
    position: relative;
}
.blogCard .cover-container .cover{
    width: 100%;
    height: 350px;
}
.blogCard .likeIcon{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 40px;
    height: 40px;
}
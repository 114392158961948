.accountProfession {
	height: calc(100vh - 165px);
	overflow-y: scroll;
}
.accountProfession::-webkit-scrollbar {
	display: none;
}
.accountProfession .heading {
	font-weight: bold;
	font-size: 23px;
	margin-top: 20px;
}
.accountProfession form {
	width: 30%;
	margin: 20px auto 0;
}
.accountProfession label {
	font-weight: bold;
	font-size: 17px;
	margin-top: 5px;
}

.accountProfession .prcContainer {
	width: 95%;
	height: 50px;
	font-weight: 500;
	font-size: 16px;
	color: #cfa79d;
	line-height: 40px;
	margin-left: 5px;
	border: 1px solid #cfa79d;
	border-radius: 6px;
	text-align: center;
	display: flex;
	position: relative;
}
.accountProfession .prcContainer.inactiveColor {
	color: #000 !important;
background-color: #E9ECEF;
}

.accountProfession #pricePerHour {
	height: 100%;
	padding: 20px;
}
.accountProfession .prcContainer .arrowLeft {
	position: absolute;
	top: 5px;
	left: 0px;
	cursor: pointer;
	padding: 0 20px;
}
.accountProfession .prcContainer .arrowRight {
	position: absolute;
	top: 5px;
	right: 0px;
	cursor: pointer;
	padding: 0 20px;
}

.accountProfession .prcContainer #pricePerHour {
	border: none;
	height: 30px;
	outline: none;
	box-shadow: none;

	margin-top: 5px;
	width: 100%;
	text-align: center;
}
.accountProfession .prcContainer #pricePerHour:focus-visible {
	outline: none;
}
.accountProfession .subSpecializationContainer {
	text-align: left;
	padding-left: 20px;
}
.accountProfession .subSpecializationContainer .subspecialization {
	display: flex;
	flex-direction: row !important;
	align-content: space-between;
	justify-content: space-between;
	max-width: max-content;
	padding: 5px 10px;
	margin: 5px 10px;
	text-align: center;
	background-color: #cfa79d;
	border-radius: 17px;
	font-size: 15px;
	font-weight: 600;
	color: #fff;
	text-align: center;
}
.accountProfession .subSpecializationContainer .subspecialization .Cross {
	cursor: pointer;
	/* transform: scale(0.8); */
	width: 15px !important;
	height: 15px !important;
	margin-top: 7px;
	margin-left: 7px;
}

.accountProfession .covidAssesmentBox {
	font-size: 16px;
	font-weight: bold;
}
.accountProfession .covidAssesmentBox > span {
	display: inline-block;
	cursor: pointer;
	transform: scale(0.5);
}

.accountProfession .textareaHeading {
	font-size: 15px;
	margin-bottom: 10px;
}
.accountProfession .form-control.textBox {
	height: 191px;
	resize: none;
	border: 1px solid #cfa79d;
}
.accountProfession .form-control,.accountProfession .ant-input-password {
	border: 1px solid #cfa79d;
}

.accountProfession .required {
	border-color: red;
}

@media (max-width: 768px) {
	.accountProfession form {
		width: 100%;
	}
}

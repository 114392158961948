.accountSideMenu {
	position: absolute;
	top: 0px;
	bottom: 0px;
	left: 0px;
	right: 0px;
	overflow-y: auto;
	margin: 0;
	padding: 0;
	-webkit-overflow-scrolling: touch;
}

.accountSideMenu::-webkit-scrollbar {
	width: 10px;
}
.accountSideMenu::-webkit-scrollbar-track {
	background: #f1f1f1 !important;
}
.accountSideMenu::-webkit-scrollbar-thumb {
	background: #cfa79d !important;
}
.accountSideMenu .scaleIcon{
	transform: scale(1.5);
    margin: 8px;
}
.accountSideMenu::-webkit-scrollbar {
	width: 5px !important;
}
.accountSideMenu label {
	padding: 10px;
	font-weight: 500;
	font-size: 18px;
	width: 100%;
	background-color: #fafafa;
}

.accountSideMenu ul li {
	font-weight: 500;
	padding: 10px;
	cursor: pointer;
}
.accountSideMenu ul li span:first-of-type {
	transform: scale(0.6) translateX(-10px);
	display: inline-block;
}

.accountSideMenu ul li.transactionBox span:first-of-type {
	transform: scale(0.6) translateX(5px);
	margin-right: 20px;
}
.accountSideMenu .list-unstyled .active {
	background-color: #f6f0dd;
}
.accountSideMenu .active {
	background-color: #f6f0dd;
}
.accountSideMenu .list-unstyled {
	padding-left: 0;
}

#accountDrawer .ant-drawer-body {
	padding: 0 !important;
}
/* @media (max-width: 768px) {
	.accountSideMenu {
		margin: 70px -15px 50px;
		padding: 0;
		padding-bottom: 120px;
		text-align: center;
		height: calc(100vh - 250px);
	}
	.accountSideMenu ul li span:nth-of-type(2) {
		font-size: 10px;
		display: block;
	}
	.accountSideMenu label {
		font-size: 11px;
		font-weight: bold;
	}
} */

@media (max-width: 768px) {
}

.hamburgerListPage {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow-y: scroll;
  height: 100vh;
  background-color: #fff;
  /* z-index: 20; */
  z-index: 9999999;
}
.hamburgerList {
  overflow: scroll;
}
.HamburgerListfooter a {
  color: #000 !important;
}
.hamburgerListPage .cross {
  float: right;
  margin: 10px 20px;
  cursor: pointer;
  padding: 30px;
}
.hamburgerListPage .accountContainer {
  width: 55%;
  box-shadow: 55px 55px 144px rgba(0, 0, 0, 0.13);
  border-bottom-right-radius: 40px;
  padding: 20px;
}
.hamburgerListPage .initials {
  border-radius: 50%;
  background-color: #edeeef;
  color: #898989;
  width: 60px;
  text-align: center;
  line-height: 60px;
  height: 60px;
  display: inline-block;
  font-weight: 500;
}
.hamburgerListPage .name {
  color: #707070;
  margin-left: 5px;
  display: inline-block;
  transform: translateY(10px);
}
.hamburgerListPage .menuItems {
  margin-top: 20px;
  padding: 20px;
  cursor: pointer;
}

.hamburgerListPage .menuItems ul li,
.hamburgerListPage .menuItems a,
.hamburgerListPage .menuItems a:hover {
  padding: 10px 0;
  cursor: pointer;
  color: #000;
  text-decoration: none;
}
.HamburgerListfooter {
  padding: 0 20px;
}
.HamburgerListfooter span,
.HamburgerListfooter a,
.HamburgerListfooter a:hover {
  font-weight: bold;
  padding: 0 0 5px;
  cursor: pointer;
  display: block;
  color: #000;
  text-decoration: none;
}

.HamburgerListfooter div {
  font-size: 14px;
  color: #939393;
  cursor: pointer;
  margin-top: 10px;
}
@media (min-width: 769px) {
  .hamburgerListPage {
    display: none;
  }
}

.packages {
	height: calc(100vh - 165px);
	overflow-y: scroll;
}
.packages .heading{
    font-weight: bold;
	font-size: 23px;
	padding-left: 20px;
	padding-top: 30px;
}

.packages::-webkit-scrollbar {
	width: 8px;
}
.packages::-webkit-scrollbar-track {
	background: #f1f1f1 !important;
}
.packages::-webkit-scrollbar-thumb {
	background: #cfa79d !important;
}
.accountAvailability {
	height: calc(100vh - 165px);
	overflow-y: scroll;
}
.accountAvailability::-webkit-scrollbar {
	display: none;
}

.accountAvailability .heading {
	font-weight: bold;
	font-size: 23px;
	margin-top: 20px;
}

.accountAvailability form {
	width: 100%;
	margin: 20px auto 0;
}

.accountAvailability .dayName {
	width: 103px;
	line-height: 23px;
	text-align: center;
	margin: 10px auto;
	border-radius: 7px;
	font-size: 12px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}

.accountAvailability .timeTableCounter {
	margin-top: 20px;
	text-align: center;
}
.accountAvailability .timeTableCounter div:first-of-type,
.accountAvailability .timeTableCounter div:nth-of-type(2),
.accountAvailability .timeTableCounter div:nth-of-type(3) {
	display: inline-block;
	margin: 20px 5px;
}
.accountAvailability .timeTableCounter > div:first-of-type,
.accountAvailability .timeTableCounter > div:nth-of-type(3) {
	cursor: pointer;
}
.accountAvailability .periodPicker {
	text-align: left;
}
.accountAvailability h6 {
	font-size: 16px;
	color: #8e8e8e;
	font-weight: bold;
}
.accountAvailability .periodPicker > div {
	display: block;
	cursor: pointer;
}

.accountAvailability .endTimeContainer {
	font-size: 14px;
	font-weight: 500;
	color: #8e8e8e;
	margin-top: 5px;
}
.accountAvailability .endTimeContainer > span {
	margin-left: 10px;
	font-weight: lighter;
}

.accountAvailability .timeTable {
	text-align: left;
	min-height: 28vh;
	overflow-y: scroll;
	flex-wrap: wrap;
}
.accountAvailability .timeTable::-webkit-scrollbar-track {
	background: #f1f1f1;
}
.accountAvailability .timeTable::-webkit-scrollbar-thumb {
	background: #cfa79d;
}
.accountAvailability .timeTable::-webkit-scrollbar {
	width: 5px;
}
.accountAvailability .timeTable .timeSlot {
	/* height: 75px !important; */
	/* padding-top: 3px; */
	margin-top: 10px;
	margin-bottom: 10px;
	/* display: flex !important; */
	align-items: center;
	cursor: pointer;
	height: fit-content;
	padding: 2px 10px;
	display: inline-block;
	font-size: 14px;
	text-align: center;
	border-radius: 11px;
	background-color: #cfa79d;
	color: #fff;
}

.accountAvailability .timeTable .timeSlot > img {
	cursor: pointer;
}

@media (max-width: 768px) {
	.accountAvailability {
		height: calc(100vh - 250px);
	}

	.accountAvailability form {
		width: 100%;
		padding-bottom: 30px;
	}
}

.ant-picker-dropdown {
	position: absolute;
	z-index: 9999999999999999999999999999;
}
.ant-picker-panel,
.ant-picker-time-panel {
	width: 100%;
}

.rc-time-picker-panel-input {
	visibility: hidden;
}

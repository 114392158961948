.full-screen-loading{
    width: 100%;
    height: 100vh;
    background: #eee;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999999;
    overflow: hidden;
}
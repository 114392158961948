@media (max-width: 768px) {
	.userType {
		text-align: center;
	}
	.userType .logoLg {
		display: none;
	}
	.userType .logoSm {
		margin: 20px auto;
		transform: scale(1.3);
	}
	.userType .wellcome {
		font-size: 22px;
		text-align: left;
		margin: 20px 20px;
		font-weight: bold;
	}
	.userType .userTypeBox {
		margin: 0 auto 30px;
	}
	.userType .userTypeBox .hereFor {
		font-size: 16px;
		
		font-weight: bold;
	}
	.userType .coaching,
	.userType .client {
		width: 185px;
		height: 80px;
		line-height: 80px;
		font-size: 16px;
		font-weight: bold;
		text-transform: uppercase;
		background-color: #fff;
		box-shadow: 0 2px 6px rgba(44, 39, 56, 0.08);
		margin: 10px auto;
		border: 1px solid #CFA79D;
		color: #CFA79D;
		border-radius: 16px;
		cursor: pointer;
	}
	.userType .coaching {
		margin-top: 20px;
	}
	.userType .active {
		background-color: #CFA79D;
		color: #fff !important;
	}

	/* .userType button {
		border: 1px solid #CFA79D;
		width: 130px;
		font-size: 13px;
		color: #CFA79D;
		transform: translateY(4vh);
		font-weight: bold;
		background-color: #fff;
		margin: 120px 10px 20px;
		border-radius: 19px;
		height: 30px;
	}
	.userType button:hover {
		background: #CFA79D;
		color: #fff;
	} */
	.userType .progressBar.sm {
		width: 296px;
		height: 7px;
		margin: 30px auto;
		background-color: #d5d5d5;
		border-radius: 7px;
	}
	.userType .progressBar.sm .value {
		width: 69px;
		height: 7px;
		border-radius: 7px;
		background-color: #CFA79D;
	}
	.userTypeBox .nextCancelContainer {
		margin:50px 0;
	}
}

@media (min-width: 769px) {
	.userType .logoSm {
		display: none;
	}

	.userType .logoLg {
		padding: 10px;
	}

	.userType .progressBar.lg {
		width: 297px;
		height: 7px;
		margin: 0px auto;
		transform: translateY(-3vh);
		background-color: #d5d5d5;
		border-radius: 7px;
	}
	.userType .progressBar.lg .value {
		width: 69px;
		height: 7px;
		border-radius: 7px;
		background-color: #CFA79D;
	}

	.userType .divider {
		height: 1px;
		background-color: #edeeef;
	}

	.userType .wellcome {
		font-size: 22px;
		font-weight: bold;
		text-align: left;
		margin-left: 70px;
		margin-top: 20px;
		color: #707070;
		position: absolute;
		top: 100px;
	}
	.userType .userTypeBox {
		width: 32%;
		text-align: center;
		padding-top: 50px;
		border-radius: 61px;
		margin: 20px auto 0;
		box-shadow: 0 -5px 24px rgba(113, 113, 113, 0.13);
	}
	.userType .userTypeBox .hereFor {
		font-size: 20px;
		font-weight: bold;
	}
	.userType .coaching,
	.userType .client {
		width: 75%;
		height: 17vh;
		line-height: 17vh;
		font-size: 17px;
		font-weight: bold;
		text-transform: uppercase;
		background-color: #fff;
		box-shadow: 0 2px 6px rgba(44, 39, 56, 0.08);
		margin: 20px auto;
		border: 1px solid #CFA79D;
		color: #CFA79D;
		border-radius: 16px;
		cursor: pointer;
	}

	.userType .coaching {
		margin-top: 50px;
	}
	.userType .active {
		background-color: #CFA79D;
		color: #fff !important;
	}
	/* .userType button {
		border: 1px solid #CFA79D;
		width: 130px;
		font-size: 13px;
		color: #CFA79D;
		font-weight: bold;
		background-color: #fff;
		margin: 50px 10px 10px;
		border-radius: 19px;
		height: 30px;
	}
	.userType button:hover {
		background: #CFA79D;
		color: #fff;
	} */
	
}
@media (min-width: 769px) and (max-width: 991px) {
	.userType .userTypeBox {
		width: 40%;
	}
}

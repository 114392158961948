.eventDetails {
  margin-top: 80px;
  color: #707070;
}

.eventDetails .details {
  border-radius: 200px 0 0 0;
  border: 1px solid #fbf7ec;
}
.eventDetails .details .titles-container {
  background: #fbf7ec;
  border-radius: 200px 0 0 0;
}
.eventDetails .rose-bg {
  background-color: #fbf7ec;
  display: inline-block;
}
.eventDetails .guru-avatar {
  width: 120px;
  height: 120px;
  border-radius: 50%;
}
.eventDetails .client-avatar {
  width: 91px;
  height: 91px;
  border-radius: 50%;
}
.eventDetails .chat-icon {
  position: fixed;
  top: 80%;
  right: 0px;
  transform: translateY(-80%);
}

.eventDetails .time-list {
  list-style: none;
  padding-left: 0;
}

.eventDetails .header {
  text-align: center;
  padding: 50px;
  margin-bottom: -50px;
}

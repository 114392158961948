.addPhoto .photoUploader.error {
	border: 4px dashed red;
}
.addPhoto .photoUploaderContainer {
	position: relative !important;
}
.addPhoto .photoUploader svg {
	color: #cfa79d !important;
}
.addPhoto .photoUploader svg ~ div {
	display: none;
}
.addPhoto .closeIconn {
	position: absolute;
	top: -20px;
	right: 30px;
	cursor: pointer;
}

.addPhoto .coachImgInstrc .photoTips {
	font-size: 16px;
	font-weight: bold;
}
.addPhoto .coachImgInstrc > div:nth-of-type(2),
.addPhoto .coachImgInstrc > div:nth-of-type(3) {
	font-size: 16px;
	color: #cacaca;
}
.addPhoto .coachImgInstrc > div:nth-of-type(2) > span,
.addPhoto .coachImgInstrc > div:nth-of-type(3) > span {
	color: #cfa79d;
}
.addPhoto .photoUploader {
	width: 208px;
	height: 208px;
	border-radius: 7px !important;
	margin: auto;
	border: 4px dashed #cfa79d;
	margin-bottom: 40px;
	padding: 5px;
}

.addPhoto .avatarCropper > div:nth-of-type(2) {
	position: absolute;
	top: 330px !important;
	background-color: #fff !important;
}

.addPhoto .avatarCropper > div:nth-of-type(2) span {
	background-color: #cfa79d !important;
}
div[name] {
	position: absolute;
	left: -50px !important;
	top: 73px !important;
}

/* @media (max-width: 768px) {
	.addPhoto {
		text-align: center;
	}
	.addPhoto .logo.sm {
		margin-top: 20px;
	}
	.addPhoto .logo.lg {
		display: none;
	}

	.addPhoto .photoUploader svg {
		color: #cfa79d !important;
	}
	.addPhoto .photoUploader svg ~ div {
		display: none;
	}
	.addPhoto .photoUploaderContainer {
		position: relative;
	}
	.addPhoto .closeIconn {
		position: absolute;
		top: -20px;
		right: 30px;
		cursor: pointer;
	}
	.addPhoto .addPhotoTitle {
		font-size: 22px;
		font-weight: bold;
		text-align: left;
		margin-left: 20px;
		margin-bottom: 10px;
		color: #707070;
	}
	.addPhoto .photoUploaderContainer {
		transform: translateY(60px);
	}

	.addPhoto .coachImgInstrc {
		text-align: left;
		font-size: 15px;
		margin-bottom: 40px;
		margin-left: 40px;
	}
	.addPhoto .coachImgInstrc .photoTips {
		font-size: 16px;
		font-weight: bold;
	}
	.addPhoto .coachImgInstrc > div:nth-of-type(2),
	.addPhoto .coachImgInstrc > div:nth-of-type(3) {
		font-size: 16px;
		color: #cacaca;
	}
	.addPhoto .coachImgInstrc > div:nth-of-type(2) > span,
	.addPhoto .coachImgInstrc > div:nth-of-type(3) > span {
		color: #cfa79d;
	}
	.addPhoto .photoUploader {
		width: 208px;
		height: 208px;
		border-radius: 7px !important;
		margin: auto;
		border: 4px dashed #cfa79d;
		margin-bottom: 40px;
		padding: 5px;
	}

	.addPhoto .avatarCropper > div:nth-of-type(2) {
		position: absolute;
		top: 330px !important;
		background-color: #fff !important;
	}

	.addPhoto .avatarCropper > div:nth-of-type(2) span {
		background-color: #cfa79d !important;
	}
	div[name] {
		position: absolute;
		left: -50px !important;
		top: 73px !important;
	}
	.addPhoto .back,
	.addPhoto .next {
		color: #cfa79d;
		background-color: #fff;
		width: 129.44px;
		height: 30.44px;
		margin-left: 10px;
		font-size: 13px;
		font-weight: bold;
		border: 1px solid #cfa79d;
		border-radius: 19px;
	}
	.addPhoto .back:hover,
	.addPhoto .next:hover {
		color: #fff;
		background-color: #cfa79d;
	}

	.addPhoto .progressBar {
		margin: 150px auto 10px;
		width: 279px;
		border-radius: 21px;
		height: 7px;
		background-color: #d5d5d5;
	}
	.addPhoto .progressBar.sm {
		transform: translateY(30px);
		margin-bottom: 100px;
	}
	.addPhoto .progressBar.lg {
		display: none;
	}

	.addPhoto .progressBar .value {
		background-color: #cfa79d;
		border-radius: 21px;
		width: 75% !important;
		height: 100%;
	}
	.addPhoto .KeyPage {
		position: relative !important;
		top: 164px !important;
		left: -6px !important;
	}
}
@media (min-width: 769px) {
	.addPhoto {
		text-align: center;
	}
	.addPhoto .logo.sm {
		display: none;
	}
	.addPhoto .logo.lg {
		width: 20px;
		height: 20px;
		margin-top: 10px;
		margin-left: 20px;
		margin-bottom: 20px;
		padding: 10px;
	}
	.addPhoto .divider {
		height: 1px;
		background-color: #edeeef;
	}

	.addPhoto .photoUploader svg {
		color: #cfa79d !important;
	}
	.addPhoto .photoUploader svg ~ div {
		display: none;
	}

	.addPhoto .closeIconn {
		position: absolute;
		top: 30px;
		right: 30px;
		cursor: pointer;
	}
	.addPhoto .addPhotoTitle {
		font-size: 22px;
		font-weight: bold;
		text-align: left;
		margin-left: 70px;
		margin-top: 20px;
		color: #707070;
		position: absolute;
		top: 80px;
	}
	.addPhoto .coachImgInstrc {
		text-align: left;
		font-size: 15px;
		margin-bottom: 40px;
		margin-left: 40px;
	}
	.addPhoto .coachImgInstrc .photoTips {
		font-size: 16px;
		font-weight: bold;
	}
	.addPhoto .coachImgInstrc > div:nth-of-type(2),
	.addPhoto .coachImgInstrc > div:nth-of-type(3) {
		font-size: 16px;
		color: #cacaca;
	}
	.addPhoto .coachImgInstrc > div:nth-of-type(2) > span,
	.addPhoto .coachImgInstrc > div:nth-of-type(3) > span {
		color: #cfa79d;
	}

	.addPhoto .photoUploaderContainer {
		padding: 50px 0;
		width: 40%;
		height: 81vh;
		border-radius: 61px;
		margin: 20px auto 0;
		box-shadow: 0 -5px 24px rgba(113, 113, 113, 0.13);
	}
	.addPhoto .photoUploader {
		width: 208px;
		height: 208px;
		border-radius: 7px !important;
		margin: auto;
		border: 4px dashed #cfa79d;
		margin-bottom: 40px;
		padding: 5px;
	}
	.addPhoto .lookingGreat {
		font-size: 16px;
		font-weight: bold;
		color: #707070;
		transform: translateY(60px);
	}
	.addPhoto .avatarCropper > div:nth-of-type(2) {
		position: absolute;
		top: 330px !important;
		background-color: #fff !important;
	}
	.addPhoto .avatarCropper > div:nth-of-type(2) span {
		background-color: #cfa79d !important;
	}
	div[name] {
		position: absolute;
		left: -50px !important;
		top: 60px !important;
	}
	.addPhoto .back,
	.addPhoto .next {
		color: #cfa79d;
		background-color: #fff;
		width: 129.44px;
		height: 30.44px;
		margin-left: 10px;
		font-size: 13px;
		font-weight: bold;
		border: 1px solid #cfa79d;
		border-radius: 19px;
	}

	.addPhoto .back:hover,
	.addPhoto .next:hover {
		color: #fff;
		background-color: #cfa79d;
	}

	.addPhoto .progressBar {
		width: 279px;
		border-radius: 21px;
		height: 7px;
		background-color: #d5d5d5;
	}
	.addPhoto .progressBar.sm {
		display: none;
	}

	.addPhoto .progressBar.lg {
		margin: 0px auto;
		transform: translateY(-3vh);
	}
	.addPhoto .progressBar .value {
		background-color: #cfa79d;
		border-radius: 21px;
		width: 75%;
		height: 100%;
	}
	.addPhoto .KeyPage {
		position: relative !important;
		top: 151px !important;
		left: -6px !important;
	}
} */


.NavBarSm {
  padding: 10px;
  text-align: center;
}
.NavBarSm .HamburgerIcon {
  display: inline-block;
  cursor: pointer;
  position: absolute;
  left: 20px;
}
.NavBarSm .HamburgerIcon a {
  color: #000;
  font-weight: 600;
}
.NavBarSm .HamburgerIcon a:hover {
  text-decoration: none;
}

.NavBarSm .logo {
  margin: 5px auto;
  display: inline-block;
}
.NavBarSm .searchLogo {
  background-color: transparent !important;
  border: none !important;
}
.NavBarSm input::-webkit-calendar-picker-indicator {
  display: none !important;
}
.NavBarSm .filtersIcon {
  display: inline-block;
  cursor: pointer;
  margin-left: 20px;
  cursor: pointer;
  position: absolute;
  right: 20px;
}

.NavBarSm .dropDownIcon {
  transform: scale(0.7);
}
.NavBarSm .searchBox {
  position: relative;
}
.NavBarSm .searchBox > input {
  border-radius: 26px;
  border: none;
  background-color: #ededed;
  width: 100%;
  height: 40px;
  padding: 5px 15px 5px 20px;
}
.NavBarSm .searchBox > input::-webkit-calendar-picker-indicator {
  display: none;
}
.NavBarSm .searchBox > input:focus {
  outline: none !important;
}
.NavBarSm .searchBox > input::placeholder {
  font-size: 12px;
  color: #898989;
  font-weight: 600;
}
.NavBarSm .searchBox .searchLogo {
  position: absolute;
  top: 8px;
  right: 0px;
  transform: scale(0.7);
  cursor: pointer;
  margin: 0 10px;
}

@media (min-width: 769px) {
  .NavBarSm {
    display: none;
  }
}

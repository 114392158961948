.accountPage {
	height: 100vh;
	overflow: hidden;
}
.accountPage .col-3 {
	padding-left: 0px !important;
}
.accountPage .col-3 label {
	padding-left: 15px !important;
}

.leave {
	border-radius: 26px !important;
	background-color: #cfa79d !important ;
	color: #fff !important;
	/* width: 40% !important;
	height: 40px !important; */
	line-height: 15px !important;
	font-weight: bold !important;
	/* margin: 20px auto 0; */
	margin-top: 8px !important;
	margin-bottom: 8px !important;
	border: none !important;
	padding: 8px 20px !important;

	box-shadow: 4px 6px 10px rgba(186, 186, 186, 0.4) !important;
}
.Cancel {
	border-radius: 26px !important;
	background-color: #fff !important ;
	color: #fff !important;
	/* width: 40% !important;
	height: 40px !important; */
	line-height: 15px !important;
	font-weight: bold !important;
	/* margin: 20px auto 0; */
	margin-top: 8px !important;
	margin-bottom: 8px !important;
	border: 1px solid #cfa79d !important;
	padding: 8px 20px !important;
	color: #cfa79d !important;
	box-shadow: 4px 6px 10px rgba(186, 186, 186, 0.4) !important;
}

.accountPage .btn {
	border-radius: 26px;
	background-color: #cfa79d;
	color: #fff;
	width: 150px;
	height: 36px;
	line-height: 15px;
	font-weight: bold;
	/* margin: 20px auto 0; */
	margin-top: 8px;
	margin-bottom: 8px;
	box-shadow: 4px 6px 10px rgba(186, 186, 186, 0.4);
}
.accountPage .btn:hover{
	color:#fff
}
@media (max-width: 769px) {
	.accountPage {
		/* height: 85vh; */
		overflow: hidden;
	}

	.accountPage .additionalHeader {
		height: 70px;
		background-color: #cfa79d;
	}

	.accountPage .container-fluid {
		background-color: #fff;
	}

	.account-side-menu {
		display: none;
	}
}

.heading .btn {
	margin-left: 25px;
}

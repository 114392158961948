
.personalInfo .show {
  opacity: 1;
  visibility: visible;
  text-align: left;
}
.personalInfo  .hide {
  opacity: 0;
  visibility: hidden;
  text-align: left;
}
.personalInfo  .passwordContainer {
  position: relative;
}
.personalInfo  .floatingInfo {
  position: absolute;
  width: 267px !important;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  border-radius: 20px;
  top: 30px;
  z-index: 3;
  width: 400px;
  padding: 20px;
  transition: all 0.4s ease-in-out;
}
.personalInfo form{
  width: 100% !important;
}

.personalInfo .icon {
    cursor: pointer;
    display: inline-block;
    transform: scale(0.7) !important;
    }
.personalInfo .agreeTerms {
  color: #d5d5d5;
}

.personalInfo .agreeTerms a,
.personalInfo .agreeTerms a:hover {
  color: #CFA79D;
  text-decoration: underline !important;
}

.personalInfo input::-webkit-validation-bubble-message, 
.personalInfo select::-webkit-validation-bubble-message,
.personalInfo textarea::-webkit-validation-bubble-message {
    display:none;
} 
.personalInfo .dateOfBirthContainer,.personalInfo .dateOfBirth{
  width: 100%;
}
@media (max-width:768px){
  .personalInfo .genderDateBox{
  flex-direction: column;
  }
  .personalInfo .dateOfBirth{
    margin-top: 20px;
  }
  .personalInfo .dateOfBirthContainer{
    text-align: start;
  }
  .personalInfo .agreeTerms {
    font-size: 13px;
  }
}
/* 
peronalInfo .floatingInfo{
  width: 267px !important;
}
.personalInfo label{
  font-size: 18px;
  font-weight: 500;
}
.personalInfo .personalInfoForm .genderBox {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

@media (min-width: 769px) {
  .personalInfo .ant-picker-input > input:focus {
    border: none !important;
    margin: 0 !important;
  }
  .personalInfo .ant-space-vertical {
    width: 100%;
  }
  .personalInfo .ant-picker-input > input:placeholder-shown {
    border: none !important;
    margin: 0 !important;
  }
  .personalInfo .ant-picker-suffix {
    display: none !important;
  }
  .personalInfo .ant-picker-clear {
    display: none !important;
  }
  .personalInfo .ant-picker-input input {
    border: none !important;
  }
  .personalInfo .logo.lg {
    padding: 10px;
  }
  .personalInfo .logo.sm {
    display: none;
  }
  
  .personalInfo .divider {
    height: 1px;
    background-color: #edeeef;
  }
  .personalInfo .progressBar.sm {
    display: none;
  }

  .personalInfo .personalInfoHeading {
    margin-left: 70px;
    font-size: 25px;
    margin-top: 20px;
    font-weight: bold;
    position: absolute;
    top: 80px;
  }
  .personalInfo .personalInfoForm {
    width: 32%;
    padding: 20px;
    margin: 0 auto 30px;
    padding-top: 50px;
    padding-bottom: 50px;
    border-radius: 61px;
    margin: 20px auto;
    box-shadow: 0 -5px 24px rgba(113, 113, 113, 0.13);
  }
  .personalInfo label {
    font-weight: 500;
    font-size:18px;
  }
  .personalInfo .personalInfoForm label > span {
    font-size: 18px;
    font-weight: lighter;
    margin-left: 10px;
    cursor: pointer;
  }
  .personalInfo .personalInfoForm input {
    border: 1px solid #cfa79d;
  }.personalInfo .personalInfoForm .emailContainer {
    position: relative;
  }

  .personalInfo .personalInfoForm .flag-dropdown {
    height: 39px;
    border: 1px solid #cfa79d;
  }
  .personalInfo .personalInfoForm .show {
    opacity: 1;
    visibility: visible;
    text-align: left;
  }
  .personalInfo .personalInfoForm .hide {
    opacity: 0;
    visibility: hidden;
    text-align: left;
  }
  .personalInfo .ant-picker-input > input:placeholder-shown {
    border: none !important;
    margin: 0 !important;
  }
  .personalInfo .ant-picker-suffix {
    display: none !important;
  }
  .personalInfo .ant-picker-clear {
    display: none !important;
  }
  .personalInfo .ant-picker-input input {
    border: none !important;
  }
  .personalInfo .input:placeholder-shown:hover {
    border: none !important;
    margin: 0 !important;
  }
  .personalInfo .ant-picker-input > input:focus {
    border: none !important;
    margin: 0 !important;
  }
  .personalInfo .ant-space-vertical {
    width: 100%;
  }

  .personalInfo .personalInfoForm label .PasswordIcon {
    margin-left: 10px;
    cursor: pointer;
  }
  .personalInfo .personalInfoForm .passwordContainer {
    position: relative;
  }
  .personalInfo .personalInfoForm .passwordContainer > div {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.7);
    color: #fff;
    border-radius: 20px;
    top: 30px;
    z-index: 3;
    width: 400px;
    padding: 20px;
    transition: all 0.4s ease-in-out;
  }
  .personalInfo .personalInfoForm .optionalLang span {
    font-weight: lighter;
    color: #b9b9b9;
  }
  .personalInfo .languageContainer {
    text-align: left;
  }
  .languageContainer .lang {
    width: 120px;
    height: 45px;
    line-height: 45px;
    background-color: #cfa79d;
    border-radius: 21px;
    font-size: 14px;
    display: inline-block;
    margin: 10px 5px;
    color: #fff;
  }

  .languageContainer .lang > img {
    margin-left: 20px;
    cursor: pointer;
  }


  .personalInfo .personalInfoForm .genderBox .genderSection {
    display: inline-block;
    cursor: pointer;
    margin: 20px 10px;
  }
  .personalInfo
    .personalInfoForm
    .genderBox
    .genderSection:nth-of-type(4)
    .icon {
    transform: translateX(-20px) scale(0.7);
  }
  .personalInfo .personalInfoForm .genderBox  .icon {
    transform: scale(0.7);
  cursor: pointer;
  }

  .personalInfo .agreeTermsContainer {
    text-align: left !important;
    margin-top: 20px;
  }
  .personalInfo .personalInfoForm .agreeTerms {
    font-size: 16px;
    color: #d5d5d5;
  }

  .personalInfo .personalInfoForm .agreeTermsIcon {
    cursor: pointer;
    display: inline-block;
    transform: scale(0.7) !important;
  }
  .personalInfo .personalInfoForm .agreeTerms span a {
    cursor: pointer;
    text-decoration: underline !important;
    color: #cfa79d !important;
  }

  .personalInfo button,
  .personalInfo input[type="button"] {
    border: 1px solid #cfa79d;
    width: 130px;
    font-size: 13px;
    color: #cfa79d;
    font-weight: bold;
    margin: 30px 10px 0;
    border-radius: 19px;
    height: 30px;

  }
  .personalInfo button:hover,
  .personalInfo input[type="button"]:hover {
    background: #cfa79d;
    color: #fff;
  }
}
@media (min-width: 769px) and (max-width: 1199px) {
  .personalInfo .personalInfoForm {
    width: 50%;
  }
  .personalInfo .date{
    position: absolute;
    top: 0;
  }
  
}


@media (max-width: 769px) {
  .personalInfo{
    padding-bottom: 5px;
  }

  .personalInfo .logo.lg {
    display: none;
  }
  .personalInfo .logo.sm {
    margin: 10px auto;
    text-align: center;
  }

  .personalInfo .progressBar {
    margin: 20px auto 20px;
    width: 279px;
    border-radius: 7px;
    height: 7px;
    background-color: #d5d5d5;
  }
  .personalInfo .progressBar .value {
    background-color: #cfa79d;
    border-radius: 7px;
    width: 50%;
    height: 100%;
  }

  .personalInfo .progressBar.lg {
    display: none;
  }
  .personalInfo .personalInfoHeading {
    margin-left: 20px;
    font-size: 25px;
    margin-bottom: 20px;
    font-weight: bold;
  }
  .personalInfo .personalInfoForm {
    width: 80%;
    margin: 0 auto;
  }
  .personalInfo .personalInfoForm label > span {
    font-size: 18px;
    font-weight: lighter;
    margin-left: 10px;
    cursor: pointer;
  }
  .personalInfo .personalInfoForm input {
    border: 1px solid #cfa79d;

  }
  .personalInfo .personalInfoForm .emailContainer {
    position: relative;
  }


  .personalInfo .personalInfoForm .flag-dropdown {
    height: 39px;
    border: 1px solid #cfa79d;
  }
  .personalInfo .personalInfoForm .show {
    opacity: 1;
    visibility: visible;
    text-align: left;
  }
  .personalInfo .personalInfoForm .hide {
    opacity: 0;
    visibility: hidden;
    text-align: left;
  }

  .personalInfo .personalInfoForm label .PasswordIcon {
    margin-left: 10px;
    cursor: pointer;
  }
  .personalInfo .personalInfoForm .passwordContainer {
    position: relative;
  }
  .personalInfo .personalInfoForm .passwordContainer > div {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.7);
    color: #fff;
    border-radius: 20px;
    top: 40px;
    z-index: 3;
    width: 400px;
    padding: 20px;
    transition: all 0.4s ease-in-out;
  }
  .personalInfo .personalInfoForm .optionalLang span {
    font-weight: lighter;
    color: #b9b9b9;
  }
  .personalInfo .personalInfoForm .languageContainer {
    text-align: left;
  }
  .languageContainer .lang {
    width: 120px;
    height: 45px;
    line-height: 45px;
    background-color: #cfa79d;
    border-radius: 21px;
    font-size: 14px;
    display: inline-block;
    margin: 10px 5px;
    text-align: center;
    color: #fff;
  }

  .languageContainer .lang > img {
    margin-left: 20px;
    cursor: pointer;
  }


  .personalInfo .personalInfoForm .genderBox .genderSection {
    display: inline-block;
    cursor: pointer;
    text-align: center;
    margin: 20px 10px;
    width: 17%;
  }
  .personalInfo .personalInfoForm .genderBox  .icon {
    transform: scale(0.7);
  }
  .personalInfo
    .personalInfoForm
    .genderBox
    .genderSection:nth-of-type(4)
    .icon {
    transform: translateX(-0.5vw) scale(0.7);
  }

  .personalInfo .agreeTermsContainer {
    text-align: left !important;
  }
  .personalInfo .personalInfoForm .agreeTerms {
    font-size: 16px;
    color: #d5d5d5;
  }

  .personalInfo .personalInfoForm .agreeTermsIcon {
    cursor: pointer;
    display: inline-block;
    transform: scale(0.7) !important;
  }

  .personalInfo .personalInfoForm .agreeTerms span a {
    cursor: pointer;
    text-decoration: underline !important;
    color: #cfa79d !important;
  }
  .personalInfo button,
  .personalInfo input[type="button"] {
    border: 1px solid #cfa79d;
    width: 130px;
    font-size: 13px;
    color: #cfa79d;
    font-weight: bold;
    margin: 50px 10px 0px;
    border-radius: 19px;
    height: 30px;
  }
  .personalInfo button:hover {
    background: #cfa79d;
    color: #fff;
  }
}

.personalInfo .personalInfoForm .required {
  border: 1px solid red !important;
}

.personalInfo .form-control {
  border-color: #cfa79d !important;
  box-shadow: none !important;
}

@media screen and (max-width: 992px) {
  .personalInfo .ant-picker-input > input:focus {
    border: none !important;
    margin: 0 !important;
  }
  .personalInfo .ant-space-vertical {
    width: 100%;
  }
  .personalInfo .ant-picker-input > input:placeholder-shown {
    border: none !important;
    margin: 0 !important;
  }
  .personalInfo .ant-picker-suffix {
    display: none !important;
  }
  .personalInfo .ant-picker-clear {
    display: none !important;
  }
  .personalInfo .ant-picker-input input {
    border: none !important;
  }
}
@media (max-width:480px){
  .personalInfo .genderDateBox{
    flex-direction: column;
  }
} */
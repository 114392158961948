.accountHeader {
	height: 100px;
	background-color: #cfa79d;
	margin-top: 55px;
	position: relative;
}
.accountHeader .imageBox {
	display: flex;
	align-items: center;
}
.accountHeader .initialsContainer {
	display: inline-block;
	width: 70px;
	height: 70px;
	border: 2px solid #fbf7ec;
	border-radius: 50%;
	text-align: center;
	position: relative;
	cursor: pointer;
	margin: 0 10px;
}
.accountHeader .humburgerContainer {
	position: absolute;
	top: 50%;
	left: 15px;
	transform: translate(0px, -50%);
	cursor: pointer;
}
.accountHeader .humburgerIcon {
	display: none;
}
.accountHeader .initials {
	background-color: #fbf7ec;
	color: #898989;
	width: 60px;
	height: 60px;
	line-height: 60px;
	text-align: center;
	font-size: 25px;
	font-weight: 500;
	border-radius: 50%;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.accountHeader .welcomeBox {
	display: inline-block;
	color: #fafafa;
	font-weight: bold;
	font-size: 16px;
}
.accountHeader .welcomeBox div {
	color: #fbf7ec;
	font-weight: 500;
}

.accountHeader .videoBox > div {
	position: relative;
}
.accountHeader .videoBox > div > span {
	position: absolute;
	top: 50%;
	left: 55%;
	transform: translate(-55%, -50%);
	cursor: pointer;
}
.accountHeader .btnn {
	border: 1px solid #cfa79d;
	border-radius: 60px;
	color: #cfa79d;
	font-size: 14px;
	padding: 0;
	background-color: #fff;
	margin: 10px;
	padding: 2px 10px;
	cursor: pointer;
}
.headerVideo .modal-body {
	display: flex;
	justify-content: center;
	align-items: center;
}
.headerVideo .modal-body #videoPopupWindow {
	width: 90% !important;
	height: 50%;
	margin: 0 auto !important;
}
.accountHeader .loader {
	width: 90px;
	height: 90px;
}
@media (max-width: 768px) {
	.accountHeader {
		margin-top: 0;
	}
	.accountHeader .videoBox {
		top: 20px !important;
	}
	.accountHeader .humburgerContainer.client {
		display: block;
	}
	.accountHeader .humburgerIcon {
		display: block;
		margin: 10px;
	}
	.accountHeader .welcomeBox {
		display: none;
	}
}


.accountBankDetails{
        height: calc(100vh - 165px);
        overflow-y: scroll;
        flex: 1;
}
.accountBankDetails .heading{
        font-weight: bold;
        font-size: 23px;
        padding-left: 20px;
        padding-top: 30px;
}

.accountBankDetails .ant-form-item{
        flex-direction: column;
}
.accountBankDetails .ant-form-item-label{
        text-align: left;
        font-weight: 500;
        font-size: 18px;
}
.accountBankDetails form{
        width: 50%;
        text-align: center;
        margin: auto;
}

.accountBankDetails .btn{
        color:#fff !important;
        border: none;
}
.accountBankDetails .ant-input,
.accountBankDetails .ant-input:focus {
        border: 1px solid #CFA79D !important;
}
@media (max-width:768px){
        .accountBankDetails form{
                width: 80%;
                text-align: center;
                margin: auto;
        }       
}
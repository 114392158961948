.add-blog label{
    font-size: 22px;
    font-weight: bold;
    color: #727271;
}
.ant-form-item{
    display: flex;
    flex-direction: column;
}
.add-blog .ant-form-item-label{
    text-align: left;
}
.add-blog textArea{
    height: 500px !important;
    resize: none;
}
.add-blog .ant-upload-list-item-info{
    display: none;
}
.add-blog .thumbnail-figure{
    width: 70px;
    height: 70px;
}

.add-blog .thumbnail-figure img{
    width: 100%;
    height: 100%;
}


.prcAndAv #pricePerHour {
	border: none;
	outline: none;
	box-shadow: none;

}

.prcAndAv .bold {
	font-weight: bold;
}
.prcAndAv input[type="number"] {
	-moz-appearance: textfield;
}

.prcAndAv input {
	box-shadow: none !important;
}
.prcAndAv .ant-picker:hover,
.ant-picker-focused {
	border-color: #cfa79d !important;
	border-right-width: 1px !important;
}

.prcAndAv .ant-picker {
	border-color: #cfa79d !important;
	border-right-width: 1px !important;
}
.prcAndAv .ant-picker-focused {
	border-color: #40a9ff;
	border-right-width: 1px !important;
	outline: 0;
	box-shadow: none;
}
.prcAndAv .ant-picker-input {
	border: none !important;
}



	.prcAndAv .logo.sm {
		display: none;
	}

	.prcAndAv .logo.lg {
		width: 20px;
		height: 20px;
		margin-top: 10px;
		margin-left: 20px;
		margin-bottom: 20px;
		padding: 10px;
	}
	.prcAndAv .progressBar.lg {
		width: 297px;
		height: 7px;
		margin: 0px auto;
		background-color: #d5d5d5;
		transform: translateY(-3vh);
		border-radius: 7px;
	}

	.prcAndAv .progressBar.lg .value {
		background: #cfa79d;
		border-radius: 7px;
		width: 90%;
		height: 7px;
	}
	.prcAndAv .divider {
		height: 1px;
		background-color: #edeeef;
	}
	.prcAndAv .heading {
		font-size: 22px;
		font-weight: bold;
		text-align: left;
		margin-left: 70px;
		margin-top: 20px;
		color: #707070;
	}
	.prcAndAv .subHeading {
		font-size: 16px;
		font-weight: bold;
	}

	.prcAndAvContainer {
		width: 60%;
		margin: 0px auto 20px;
		padding: 50px 20px 20px;
		border-radius: 61px;
		box-shadow: 0 -5px 24px rgba(113, 113, 113, 0.13);
	}

	.prcAndAv label {
		font-weight: bold;
		font-size: 16px;
		margin-top: 10px;
		color: #707070;
	}

	.prcAndAv .paypalBox {
		cursor: pointer;
	}
	.prcAndAv .paypalHeading {
		font-size: 13px;
		color: #a8a7a7;
		margin-left: 5px;
		cursor: pointer;
	}
	.prcAndAv input {
		width: 90%;
		max-width: 257px;
		height: 40px;
		border-radius: 6px;
		border: 1px solid #cfa79d;
		box-shadow: 0 4px 8px rgba(44, 39, 56, 0.04);
	}
	.prcAndAv input::placeholder {
		padding-left: 10px;
	}

	.prcAndAv .error {
		color: red;
		margin-left: 5px;
	}

	.prcAndAv .errBorder {
		border: 1px solid red;
	}

	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	.prcAndAv .input,
	.prcAndAv .pricing {
		text-align: center;
	}
	.prcAndAv #pricePerHour {
	text-align: center;
	}
	.prcAndAv #pricePerHour:focus-visible {
		outline: none;
	}
	.prcAndAv .currencyBox {
		display: inline-block;
		max-width: 60px;
		width: 20%;
		font-weight: 500;
		font-size: 10px;
		transform: translateY(-3px);
		border-radius: 6px;
		border: 1px solid #cfa79d;
		box-shadow: 0 4px 8px rgba(44, 39, 56, 0.04);
	}
	.prcAndAv .pricing > span {
		max-width: 227px;
		width: 75%;
		font-weight: 500;
		font-size: 16px;
		color: #cfa79d;
		line-height: 40px;
		margin-left: 5px;
		display: inline-block;
		border: 1px solid #cfa79d;
		border-radius: 6px;
	}
	.prcAndAv .pricing > span .arrowLeft {
		float: left;
		cursor: pointer;
        transform: translateX(10px);
	}
	.prcAndAv .pricing > span .arrowRight {
		float: right;
		cursor: pointer;
		transform: translateX(-10px);
	}
	.prcAndAv .weeks {
		margin-top: 20px;
		text-align: center;
	}
	.prcAndAv .weekDay {
		border-radius: 7px;
		font-size: 11px;
		padding: 0 8px;
		box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
		cursor: pointer;
		text-align: center;
		color: #7b7b7b;
		display: inline-block;
		height: 23px;
		margin-left: 4px;
		line-height: 23px;
	}
	.prcAndAv .dayName {
		width: 103px;
		line-height: 23px;
		text-align: center;
		margin: 10px auto;
		border-radius: 7px;
		font-size: 12px;
		box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
	}

	.prcAndAv .timeTableCounter {
		margin-top: 20px;
	}
	.prcAndAv .timeTableCounter div:first-of-type,
	.prcAndAv .timeTableCounter div:nth-of-type(2),
	.prcAndAv .timeTableCounter div:nth-of-type(3) {
		display: inline-block;
		margin: 10px 5px;
	}
	.prcAndAv .timeTableCounter > div:first-of-type,
	.prcAndAv .timeTableCounter > div:nth-of-type(3) {
		cursor: pointer;
		
	}
	.prcAndAv .periodPicker {
		text-align: left;
	}
	.prcAndAv .periodPicker h6 {
		margin-left: 5px;
		font-size: 16px;
		color: #8e8e8e;
	}
	.prcAndAv .periodPicker > div {
		display: block;
		cursor: pointer;
	}
	.prcAndAv .periodPicker > div {
		margin: 10px 5px;
	}
	.prcAndAv .endTimeContainer {
		font-size: 16px;
		font-weight: 500;
		color: #8e8e8e;
	}
	.prcAndAv .endTimeContainer > span {
		margin-left: 10px;
		font-weight: lighter;
	}
	
	.prcAndAv .timeTable {
		text-align: left;
		height: 28vh;
		overflow-y: scroll;
		flex-wrap: wrap;
	}
	.prcAndAv .timeTable::-webkit-scrollbar-track {
		background: #f1f1f1;
	}
	.prcAndAv .timeTable::-webkit-scrollbar-thumb {
		background: #cfa79d;
	}
	.prcAndAv .timeTable::-webkit-scrollbar {
		width: 5px;
	}
	.prcAndAv .timeTable .timeSlot {
		/* width: 46%; */
		margin-left: 2%;
		margin-top: 10px;
		cursor: pointer;
		height: max-content;
		display: inline-block;
		font-size: 15px;
		text-align: center;
		border-radius: 11px;
		background-color: #cfa79d;
		color: #fff;
	}

	.prcAndAv .timeTable .timeSlot > img {
		float: right;
		margin-top: 10px;
		margin-right: 10px;
		cursor: pointer;
	}

	
/* 
@media (min-width: 992px) and (max-width: 1199px) {
	.prcAndAvContainer {
		width: 50%;
	}
}

@media (min-width: 1200px) {
	.prcAndAvContainer {
		width: 36%;
	}
} */

.ant-picker-time-panel-column {
	text-align: center;
}

.ant-modal-footer {
	display: none !important;
}
.ant-modal-content {
	height: 100% !important;
}
.messageSuccess .iconContainer {
	width: 100%;
}

@media (max-width: 768px) {
	.ant-modal {
		width: 87% !important;
	}
}

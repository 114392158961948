.quick-signin .input,.quick-signin .input:hover,.quick-signin .input:focus{
    border: 1px solid #cfa79D !important;
    outline: none;
    margin-bottom: 10px;
}
.quick-signin textarea.input{
    resize:none;
    width: 80%;
    height: 100px;
}

.quick-signin .textAreaInput{
    margin:10px auto;
    display: flex;
    justify-content: center;
}
.quick-signin .semi-rounded-btn{
    color: #cfa79D !important;
    background-color: #fff !important;
    border: none !important;
    margin: 20px auto;
    /* border-radius: 20%; */
    display: block;
    width: 50%;
    height: 46px;
    line-height: 1;
}
.quick-signin .semi-rounded-btn.pink{
    background-color: #cfa79D !important;
    color: #fff !important;
}
.quick-signin .semi-rounded-btn.pink:hover{
    background-color: #fff !important;
    color: #cfa79D !important;
}
.quick-signin .form-label .required{
    color:red !important
}
.quick-signin .semi-rounded-btn>a{
    text-decoration: none !important;

}
.quick-signin .semi-rounded-btn:hover{
    background-color: #cfa79D !important;
    color: #fff !important;
}
.quick-signin .form-label{
    font-weight: 500;
    font-size: 18px;
}
.quick-signin .actionCenter .btn:first-of-type{
    background-color: #fff !important;
    color: #cfa79D !important;
}
.quick-signin .email-container{
    position: relative;
}
.quick-signin .email-container .emailCheckSign{
    position: absolute;
    top: -5px;
    right: 0;
}
.quick-signin .fade.alert.alert-danger.text-center{
    text-align: center !important;
}
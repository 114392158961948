.floatingBar {
	border-radius: 18px;
	background-color: #ededed;
	width: 90%;
	position: fixed;
	bottom: 40px;
	left: 50%;
	transform: translateX(-50%);
	height: 50px;
	line-height: 50px;
	z-index: 55555555;
	padding: 5px;
}

.floatingBar span {
	width: 25%;
	display: inline-block;
	text-align: center;
	position: relative;
	cursor: pointer;
}
.floatingBar span > div.active {
	width: 8px;
	height: 8px;
	border-radius: 50%;
	display: inline-block;
	position: absolute;
	left: 50%;
	transform: translate(-50%, 0);
	background-color: #cfa79d;
}

.floatingBarContainer .chattingIcon {
	background-color: #ededed;
	width: 60px;
	height: 60px;
	line-height: 60px;
	text-align: center;
	display: inline-block;
	border-radius: 50% !important;
	position: fixed;
	bottom: 20px;
	right: 30px;
	display: none;
	cursor: pointer;
	z-index: 999999;
}

#chat {
	position: relative;
}
#chat .modal-header {
	font-size: 25px;
	text-align: center;
	width: 95%;
	margin: 0 auto;
}
#chat .modal-header > div {
	margin: 0px auto;
	width: 100%;
}
#chat .modal-content {
	width: 70%;
	margin: 0 auto;
	height: 450px;
}

#chat .modal-body::-webkit-scrollbar {
	display: none;
}

#chat .chatContainer {
	height: 300px;
	overflow-y: scroll;
}

#chat .chatContainer::-webkit-scrollbar {
	display: none;
}
#chat .msgContainer.sender {
	direction: rtl;
	width: fit-content;
	margin: 10px 0 0 auto;
}
#chat .initials {
	border-radius: 50%;
	height: 40px;
	width: 40px;
	line-height: 40px;
	text-align: center;
	background-color: #707070;
	color: #fff;
	display: inline-block;
	margin: 0 5px;
	transform: translateY(-2px);
}
#chat .msg {
	border-radius: 21px;
	box-shadow: 0 3px 25px #eaeaea;
	padding: 10px 20px;
	width: fit-content;
	display: inline-block;
}

#chat .msg.sender {
	background-color: rgba(46, 204, 139, 0.16);
}
#chat .modal-body .sendBar {
	position: absolute;
	bottom: 20px;
	left: 50%;
	transform: translateX(-50%);
	width: 80%;
}
#chat .modal-body .sendBar >input{
	padding-right: 11%;
}

#chat textarea {
	background-color: #ededed;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
	border-radius: 20px;
	border: none;
	outline: none;
	position: relative;
	resize: none;
	height: 40px;
}

#chat .modal-body .sendBar > button {
	display: inline-block;
	position: absolute;
	right: 0px;
	top: -8px;
	transform: scale(0.8);
	cursor: pointer;
	border: none;
	background: none;
}

@media (min-width: 769px) {
	.floatingBar {
		display: none;
	}

	/* .floatingBarContainer .chattingIcon {
		display: block;
	} */
}
.floatingBarContainer .chattingIcon {
	display: block;
}
@media (max-width: 768px) {
	#chat {
		margin: 0 auto;
		max-width: 100%;
	}

	#chat .modal-content {
		border: none;
		border-radius: 0;
		height: 90vh;
		width: 100%;
	}
	#chat .chatContainer {
		height: 65vh;
	}
}
.notification-message .title {
	color: #fff;
}

@-webkit-keyframes redFlash {
	from,
	to {
		border-color: transparent;
	}
	50% {
		border-color: red;
	}
}
@keyframes redFlash {
	from,
	to {
		/* border-color: transparent; */
		opacity: 1;
	}
	50% {
		/* border-color: red; */
		opacity: 0.5;
	}
}

.redFlash {
	/* border: 2px solid red; */
	/* add 'border-color: transparent' if you wish no border to show initially */
	-webkit-animation: redFlash 1s step-end infinite;
	animation: redFlash 1s step-end infinite;
}
@media (max-width: 768px) {
	.toTop {
		bottom: 105px !important;
	}
}

.guestNav{
    padding:10px;
    border-bottom: 1px solid #EDEEEF;
}
.guestNav .progressBar {
    margin: 0px auto;
    width: 279px;
    border-radius: 7px;
    height: 7px;
    transform: translateY(-3vh);
    background-color: #d5d5d5;
  }
  .guestNav .progressBar .value {
    background-color: #cfa79d;
    border-radius: 7px;
    height: 100%;
  }
@media (max-width:768px){
    .guestNav{
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .guestNav .progressBar{
      margin-top: 20px;
    }
}
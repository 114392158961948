.FormGroup {
  margin: 0 0 20px;
  padding: 0;
  border-style: none;
  border-radius: 4px;
}

.FormRow {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 15px;
}

.StripeElement--webkit-autofill {
  background: transparent !important;
}

.StripeElement {
  width: 100%;
  padding: 11px 15px 11px 0;
}
.paymentForm {
  margin-top: 10px;
  margin-bottom: 10px;
}
.paymentForm .btn {
  margin-top: 20px;
  background-color: #cfa79d;
  color: #fff;
}
.paymentForm .btn:first-of-type {
  background-color: #fff !important;
  color: #cfa79d !important;
}
.paymentForm .btn:disabled {
  cursor: not-allowed;
}
.paymentForm .loader {
  width: 25px;
  height: 25px;
  border: 5px solid #f3f3f3;
  border-top: 5px solid #cfa79d;
  margin: 0 auto;
}
.paymentForm .FormGroup {
  background: #fff;
  color: #000 !important;
  border-top: none;
}
.FormRow {
  border-top: none;
}
.ElementsApp .InputElement.is-invalid {
  color: #000 !important;
}

.coachCard {
  margin: 20px auto;
}
.coachCard .avatar {
  border-radius: 50%;
  width: 180px;
  height: 180px;
  max-width: 180px;
  max-height: 100%;
  border: 5px solid #cfa79d;
  box-shadow: 9px 9px 6px rgba(0, 0, 0, 0.16);
}
.coachCard .nextAvSession {
  font-weight: bold;
  cursor: pointer;
}
.coachCard .imgContainer {
  text-align: center;
}

.coachCard .img-container {
  width: 100%;
  position: relative;
  padding-left: 1rem;
  padding-right: 1rem;
}

.coachCard .watchVideoImg {
  position: absolute;
  width: 40px;
  bottom: 17px;
  right: 10px;
}
@media (max-width: 480px) {
  .coachCard .img-container {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }
  .coachCard .watchVideoImg {
    right: 0px;
  }
}

.coachCard .data {
  margin-top: 10px;
}

@media (max-width: 991px) {
  .coachCard .data {
    text-align: center;
  }
}
.coachCard .data .fullName {
  font-size: 22px;
  font-weight: bold;
  font-family: "Noto Serif JP", serif;
}
.coachCard .data .fullName > div {
  transform: scale(0.7);
  margin-left: 10px;
  display: inline-block;
}
.coachCard .data .profession {
  font-size: 18px;
}
.coachCard .data .specialization {
  color: #cfa79d;
  font-size: 14px;
}
.coachCard .data .coachRate > div {
  transform: scale(2);
  display: inline-block;
  margin: 0 10px;
}
.coachCard .bio {
  color: #a3a3a3;
  font-size: 16px;
  margin: 10px auto;
  word-break: break-word;
}
.coachCard .keyword {
  display: inline-block;
  color: #989898;
  background-color: #edeeef;
  font-size: 14px;
  border-radius: 17px;
  padding: 5px 15px;
  margin-right: 10px;
  margin-top: 10px;
}

.coachCard .pricePerHour {
  color: #cfa79d;
  font-weight: 500;
  margin-top: 10px;
}
.coachCard .watchVideoBtn {
  border: 1px solid #cfa79d;
  margin: 10px auto 0;
  height: 29px;
  border-radius: 26.7px;
  font-weight: bold;
  font-size: 10px;
  box-shadow: 2px 3px 8px rgba(122, 66, 56, 0.4);
}
.coachCard .watchVideoBtn span {
  margin-left: 5px;
  display: inline-block;
  transform: translateY(-2px);
}
.coachCard .bookCoachBtn {
  background-color: #cfa79d;
  margin: 10px auto 0;
  height: 29px;
  border-radius: 26.7px;
  font-weight: bold;
  font-size: 10px;
  color: #fff !important;
  box-shadow: 2px 3px 8px rgba(122, 66, 56, 0.4);
}

.coachCard .bookCoachBtn:hover {
  color: #fff;
}

.coachCard .availableTimes,
.doctorDetails .availableTimes {
  text-align: center;
}
.coachCard .availableTimes .time,
.doctorDetails .availableTimes .time {
  background-color: #f8f2df;
  border-radius: 17px;
  cursor: pointer;
  width: auto;
  padding: 2px;
  margin-bottom: 10px;
  transition: all 0.4s ease-in;
  -webkit-transition: all 0.4s ease-in;
  -moz-transition: all 0.4s ease-in;
  -o-transition: all 0.4s ease-in;
  font-size: 12px;
}

.coachCard .availableTimes .time:hover,
.doctorDetails .availableTimes .time:hover {
  background-color: #cfa79d;
}
.coachCard a {
  color: #000;
}

.coachCard a:hover {
  color: #000;
  text-decoration: none;
}
.coachCard .divider {
  height: 1px;
  background-color: #edeeef;
  margin-top: 10px;
}

.coachCard .sm {
  display: none;
}
.watchVideoContainer.sm {
  display: none;
}
.pricePerHour.sm {
  display: none;
}
@media (max-width: 880px) {
  .watchVideoContainer.sm {
    display: inline-block;
  }
  .pricePerHour.sm {
    display: inline-block;
  }
  .coachCard .lg {
    display: none !important;
  }
  .coachCard .data .fullName {
    font-size: 18px;
  }
  .coachCard .data .fullName > div {
    transform: scale(0.5);
    margin-left: 4px;
  }
  .coachCard .sm {
    display: inline-block;
  }
  .coachCard .availableTimes {
    display: none;
  }
  .coachCard .ButtonsContainer.sm {
    margin-left: auto;
    margin-right: 10px;
  }
  .coachCard .ButtonsContainer.sm > div {
    display: inline-block;
    margin: 0 5px;
  }
  .coachCard .bookCoachBtn {
    transform: translateY(-5px);
  }
}
@media (min-width: 769px) and (max-width: 991px) {
  .coachCard .watchVideoBtn,
  .coachCard .bookCoachBtn {
    width: 110px !important;
    margin: 5px;
  }
}

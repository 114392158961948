.TransactionCard {
	width: 40%;
	border: 1px solid rgba(0, 0, 0, 0.1);
	border-radius: 10px;
	margin: 20px auto;
	text-align: left;
	padding: 10px 10px 30px;
}

.TransactionCard .clientName,
.TransactionCard .price {
	display: inline;
}

.TransactionCard .clientName {
	color: #000;
	font-weight: bold;
	font-size: 18px;
}
.TransactionCard .paymentNumber {
	color: #919191;
	font-weight: 500;
}
.TransactionCard .price {
	float: right;
	font-weight: bold;
	color: #CFA79D;
}
.TransactionCard .date {
	color: #CFA79D;
	font-size: 14px;
}
.TransactionCard .btn,
.TransactionCard .btn:hover {
	float: right;
	background-color: #CFA79D;
	border-radius: 26px;
	color: #fff;
	text-decoration: none;
}
#transactionCard .modal-content {
	border-radius: 82px !important;
	height: 600px;
	width: 100% !important;
}
#transactionCard .modal-content .modal-header {
	padding: 20px 50px;
	border-bottom: none !important;
}
#transactionCard .modal-content .modal-body {
	background-color: #e8e8e8;
	padding: 30px;
	box-shadow: 0 -5px 24px rgba(113, 113, 113, 0.13);
}

#transactionCard .modal-content .modal-body .receipte .upSawtooth {
	background: linear-gradient(-135deg, #e8e8e8 10px, transparent 0) 0 10px,
		linear-gradient(135deg, #e8e8e8 10px, #fff 0) 0 10px;
	background-color: #e8e8e8;
	background-position: right top !important;
	background-repeat: repeat-x !important;
	background-size: 20px 20px;
	width: 100%;
	height: 15px;
	transform: translateY(-15px);
}
#transactionCard .modal-content .modal-body .receipte {
	width: 60%;
	height: 360px;
	background-color: #fff;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background: linear-gradient(-135deg, #fff 10px, transparent 0) 0 10px,
		linear-gradient(135deg, #fff 10px, #e8e8e8 0) 0 10px;
	background-color: #fff;
	background-position: left bottom !important;
	background-repeat: repeat-x !important;
	background-size: 20px 20px;
}
#transactionCard .modal-content .modal-body .receipte .receipteContainer {
	padding: 0 20px;
}

#transactionCard .modal-content .modal-body .receipte .logo {
	text-align: center;
	transform: scale(1.5);
	margin-bottom: 20px;
}
#transactionCard .modal-content .modal-body .receipte .divider {
	width: 100%;
	height: 1px;
	background-color: #707070;
	margin: 0 auto;
}

#transactionCard .modal-content .modal-body .receipte .session .title {
	color: #3c3c3b;
	text-align: center;
	font-weight: 500;
	font-size: 20px;
	font-weight: 500;
	margin-bottom: 10px;
}

#transactionCard .modal-content .modal-body .receipte .session .fullName {
	font-weight: 500;
	font-size: 20px;
	color: #CFA79D;
}

#transactionCard .modal-content .modal-body .receipte .session .specialization {
	font-size: 14px;
	color: #707070;
}
#transactionCard .modal-content .modal-body .receipte .session .fullName > span,
#transactionCard
	.modal-content
	.modal-body
	.receipte
	.session
	.specialization
	> span,
#transactionCard
	.modal-content
	.modal-body
	.receipte
	.session
	.discount
	> span {
	float: right;
}
#transactionCard .modal-content .modal-body .receipte .session .discount {
	margin: 20px 0;
	font-size: 18px;
	font-weight: 500;
}
#transactionCard
	.modal-content
	.modal-body
	.receipte
	.session
	.discount
	> span {
	color: #CFA79D;
}
#transactionCard .modal-content .modal-body .receipte .total {
	color: #707070;
	font-weight: 500;
	font-size: 18px;
	margin-top: 10px;
	margin-bottom: 10px;
	text-align: center;
}
#transactionCard .modal-content .modal-body .receipte .total > span {
	text-align: center;
	color: #CFA79D;
	font-size: 20px;
}

#transactionCard .modal-content .modal-body .receipte .thankYou {
	text-align: center;
	color: #707070;
	font-size: 23px;
	font-weight: 500;
	margin-top: 10px;
}
@media (max-width: 768px) {
	.TransactionCard,
	#transactionCard {
		width: 100%;
		max-width: 100%;
	}
	#transactionCard .modal-content {
		border-radius: 0 !important;
		width: 100%;
		height: 100vh;
		max-height: 100vh;
		border: none;
	}
	#transactionCard .modal-content .modal-body {
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
		height: 100vh;
	}
}

.accountDiscounts {
	height: calc(100vh - 165px);
	overflow-y: scroll;
}
.accountDiscounts::-webkit-scrollbar {
	display: none;
}
.accountDiscounts .heading {
	font-weight: bold;
	font-size: 23px;
	margin-top: 20px;
	padding-left: 20px;
	padding-top: 30px;
}
.accountDiscounts .heading span {
	color: #cfa79d;
}
.accountDiscounts .content {
	text-align: center;
	margin-top: 40px;
}
.accountDiscounts .invite {
	font-weight: 600;
	font-size: 21px;
	margin-top: 5px;
}
.accountDiscounts p {
	font-weight: 500;
	font-size: 18px;
}
.accountDiscounts .codeBox {
	color: #cfa79d;
}

.accountDiscounts .codeBox > div {
	font-size: 30px;
	font-weight: 900;
	color: #cfa79d;
}

@media (max-width: 768px) {
	.accountDiscounts {
		height: calc(100vh - 250px);
		margin-bottom: 130px;
	}
	.accountDiscounts .content {
		padding-bottom: 40px;
	}
}

input,
input:focus {
  border-color: #cfa79d !important;
}
/* input:focus {
	box-shadow: none !important;
} */
textarea {
  border-color: #cfa79d !important;
}
textarea:focus {
  box-shadow: none !important;
}

form .mt-4 {
  background: #ae8f7b center center !important;
  position: relative;
  left: 20%;
  color: #ffff !important;
  font-weight: 500 !important;
}
.btn:focus {
  box-shadow: none !important;
}
.contact .container {
  position: relative;
  margin-top: 10%;
}
.contact .title {
  width: 90%;
}
.contact .closeBtn {
  position: absolute;
  top: 0;
  right: 5%;
  cursor: pointer;
}
@media (max-width: 768px) {
  .contact .form {
    width: 75% !important;
  }
}

.teamPage{
    min-height: 100vh;
	display: flex;
	flex-direction: column;
    justify-content: space-between;
}
.teamPage .teamCard{
    width: 300px;
    border-radius: 20px;
    box-shadow:10px 2px 15px #E2E2E2;
}
.teamPage .teamCard .avatarContainer{
    width: 100px;
    margin: auto;
}

.teamPage .teamCard .avatarContainer >img{
    border-radius: 50%;
    width: 100%;
    border: 3px solid #cfa79d;
}
.teamPage .teamCard .name,.teamPage .teamCard .title{
    font-weight: 600;
    font-size: 18px;
}
.teamPage .teamCard .bio{
    color: 9A9A9A;
    font-size: 14px;
}
.teamPage .teamCard .weloIconContainer{
    width: 30px;
}
.teamPage .teamCard .weloIconContainer>img{
    width: 100%;
}
.teamPage .content{
 justify-content: center;
}
.teamPage .mainTitle{
    margin-top: 20px;
    padding: 0 40px;
}
.teamPage .mainTitle>span{
    background-color:#fff;
    display:inline-flex;
    padding:0 20px;
    color: #AE8F7B;
    font-weight: 600;
    font-size: 18px;
    transform:translateY(-22px)
}
.teamPage .mainTitle::before{
    display: inline-block;
    content: "";
    width: 100%;
    height: 2px;
    background-color: #AE8F7B;
}
@media (max-width:768px){
    .teamPage .content{
        flex-direction: column;
        margin: auto;
    }
    .teamPage .teamCard{
        box-shadow: none;
        border-bottom: 2px solid #E2E2E2;
        border-radius: 0;
    }
}
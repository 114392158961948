.reviewBox {
	margin: 10px auto;
	padding-bottom: 20px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.reviewBox .header {
	font-size: 20px;
	font-weight: 700;
}
.reviewBox p {
	border-left: 6px solid #CFA79D;
	padding-left: 10px;
	font-size: 14px;
	min-height: 20px;
}
.reviewBox .clientInfo span:first-of-type {
	color: #CFA79D;
	font-weight: 600;
}
.reviewBox .clientInfo span:nth-of-type(2) {
	font-size: 14px;
	margin-left: 5px;
}
.reviewBox .clientInfo .starContainer {
	margin-left: 20px;
}

.reviewBox .clientInfo .starContainer .star {
	display: inline-block;
	transform: scale(2) translateY(-2px);
	margin: 0 8px;
}

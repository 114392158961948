.coachBookCard {
	width: 90%;
	margin: 10px auto;
}
.coachBookCard .startTime {
	display: inline-block;
	margin-bottom: 10px;
	font-weight: bold;
	font-size: 14px;
}
.coachBookCard .bookContainer {
	width: 100%;
	background-color: #fbf7ec;
	height: 200px;
	float: right;
}
.coachBookCard .bookContainer.coach {
	border: 1px solid #f6f0dd;
}
.coachBookCard .bookContainer.client {
	background-color: #fbf7ec;
}
.coachBookCard .bookContainer .img {
	width: 60px;
	height: 60px;
	line-height: 60px;
	border-radius: 50%;
	background: #cfa79d;
	color: #898989;
	margin: 10px;
	text-align: center;
	border: 2px solid #fff;
	box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
	display: inline-block;
}

.coachBookCard .bookContainer .price {
	color: #cfa79d;
	font-weight: bold;
	float: right;
	margin: 10px;
}

.coachBookCard .bookContainer .infoContainer {
	display: inline-block;
}
.coachBookCard .bookContainer .infoContainer.coach {
	transform: translateY(15px);
}
.coachBookCard .bookContainer .name a,
.coachBookCard .bookContainer .name {
	color: #cfa79d;
	font-weight: bold;
}
.coachBookCard .red-dot{
	background-color: red;
	width: 10px;
	height: 10px;
	border-radius: 50%;
}
.coachBookCard .bookContainer .specialization {
	color: #707070;
	font-size: 14px;
	font-weight: 500;
}
.coachBookCard .bookContainer .rate > div {
	transform: scale(1.5);
	display: inline-block;
	margin: 0 5px;
}
.coachBookCard .bookContainer .divider {
	height: 1px;
	background: rgba(196, 139, 48, 0.18);
	margin-top: 50px;
}
.coachBookCard .bookContainer .timeInfo {
	padding: 15px;
}
.coachBookCard .bookContainer .timeInfo .timeDetails {
	margin-left: 5px;
	font-size: 14px;
	font-weight: 500;
	color: #cfa79d;
}
.coachBookCard .bookContainer .timeInfo .btn {
	float: right;
	margin: 0;
	border: 1px solid #cfa79d;
	color: #fff;
	background-color: #cfa79d;
	border-radius: 60px;
	font-size: 14px;
	padding: 0;
	width: max-content;
	height: 27px;
}
@media (max-width: 450px) {
	.coachBookCard {
		width: 100%;
	}

	.coachBookCard .bookContainer {
		height: 250px;
	}

	.coachBookCard .bookContainer .infoContainer {
		padding-left: 20px;
	}

	.coachBookCard .startTime {
		font-size: 12px;
	}
}

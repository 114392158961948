.event-booking-modal .ant-modal-body {
  background: #f3f3f3;
  border-radius: 15px !important;
}
.event-booking-modal .initials {
  width: 70px;
  height: 70px;
  line-height: 70px;
  text-align: center;
  border-radius: 50%;
  display: inline-block;
  background-color: #d4f0e4;
  color: #898989;
  font-weight: 500;
}
.event-booking-modal .name {
  color: #cfa79d;
  font-weight: bold;
}
.event-booking-modal .specialization {
  color: #707070;
  font-size: 14px;
  font-weight: 500;
}
.event-booking-modal .apply-code {
  position: absolute;
  right: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #cfa79d;
  color: #fff;
  border: 1px solid #cfa79d;
  /* border-top-right-radius: 10px;
  border-bottom-right-radius: 10px; */
}
.event-booking-modal .apply-code:disabled,
.event-booking-modal .apply-code:disabled:hover {
  background-color: #ddd;
  color: #fff;
  border-color: #cfa79d;
  border-left: none;
}

.event-booking-modal .confirm-reseve {
  background-color: #fff;
  color: #cfa79d;
  margin: 50px auto;
  border-color: #cfa79d;
  display: flex;
  justify-content: center;
  border-radius: 20px;
  align-items: center;
}

.event-booking-modal .confirm-reseve:hover {
  background-color: #cfa79d;
  color: #fff;
}

.event-booking-modal .invalid-coupon {
  color: red;
  text-align: center;
}

.event-booking-modal .promoCodeInput {
  /* border-radius: 10px; */
  outline: none;
  outline: #cfa79d;
  border: 1px solid #cfa79d;
}

.event-booking-modal .promoCodeControl {
  display: flex;
  gap: 8px;
}

.event-booking-modal .ant-select {
  border-radius: 10px !important;
}

.fixedNavbar {
  position: fixed;
  width: 100%;
  top: 0;
  background-color: #fff;
  z-index: 100;
}
.NavBarLg .logo {
  display: inline-block;
  padding: 13px 0;
}
.NavBarLg .filtersIcon
{
  display: none !important;
}

.NavBarLg .actionsLink{
  color: #000;
  text-decoration: none;
}
.NavBarLg .items {
  position: absolute;
  top: 13px;
  right: 1px;
  text-align: right;
  width: 95%;
}
.NavBarLg input::-webkit-calendar-picker-indicator {
  display: none !important;
}
.NavBarLg .searchBox {
  position: relative;
}
.NavBarLg .searchBox > input {
  border-radius: 26px;
  border: none;
  background-color: #ededed;
  width: 20%;
  padding: 5px 15px 5px 20px;
}

.NavBarLg .searchBox > input::-webkit-calendar-picker-indicator {
  display: none;
}
.NavBarLg .searchBox > input:focus {
  outline: none !important;
}
.NavBarLg .searchBox > input::placeholder {
  font-size: 10px;
  color: #898989;
  font-weight: 600;
}
.NavBarLg .searchBox .searchLogo {
  position: absolute;
  top: -2px;
  right: 0px;
  transform: scale(0.7);
  cursor: pointer;
  margin: 0 10px;
}
.NavBarLg .tabs {
  color: #cfa79d;
  display: inline-block;
  border: 1px solid #cfa79d;
  width: 12%;
  height: 32px;
  font-size: 12px;
  font-weight: 700;
  line-height: 32px;
  border-radius: 26px;
  cursor: pointer;
  margin: 0 3px;
  text-align: center;
}

.NavBarLg .tabs.active {
  background-color: #cfa79d;
  color: #fff;
}
.NavBarLg .searchLogo {
  background-color: transparent !important;
  border: none !important;
}
.NavBarLg .filtersIcon,
.NavBarLg .dropDownIcon {
  margin: 0 10px;
  display: inline-block;
  cursor: pointer;
}

.NavBarLg .filtersIcon {
  transform: scale(1.4);
  margin-left: 20px;
}

.NavBarLg .dropDownIcon {
  transform: scale(0.7);
}
.NavBarLg .divider {
  height: 1px;
  background-color: #edeeef;
}
/*Filters page styles*/
.NavBarLg .filterPage {
  width: 100%;
  position: fixed;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.3);
}
@media (max-width: 768px) {
  .NavBarLg {
    display: none;
  }
}

/*Start weekly calender customizing*/

.weeklyCalendar .calendarHeader {
  display: inline-block;
  font-weight: bold;
  color: #ffffff;
  text-align: center;
  vertical-align: middle;
  font-size: 1rem;
  font-family: "Montserrat";
}

.weeklyCalendar .calendarNavIcon {
  color: #ffffff;
  display: inline-block;
  font-size: 1rem;
}

.weeklyCalendar .calendarButtons {
  width: 100%;
  margin: 0 auto;
  display: inline-block;
  display: -moz-inline-box;
  *display: inline; /* For IE7 */
  zoom: 1; /* Trigger hasLayout */
  text-align: center;
}

.weeklyCalendar .weekdayButton {
  display: inline-block;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-family: "Montserrat";
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.weeklyCalendar .topText {
  font-weight: bold;
}

.weeklyCalendar .bottomText {
  display: inline-block;
  line-height: 2.5rem;
  height: 2.5rem;
  width: 2.5rem;
  /*    padding: 1rem;*/
  /*    padding-top: 1rem;*/
}

.weeklyCalendar .selected {
  -moz-border-radius: 50%;
  border-radius: 50%;
  background-color: #ffff;
  color: #cfa79d;

}
.weeklyCalendar .active {
  -moz-border-radius: 50%;
  border-radius: 50%;
  background-color: rgb(255 255 255 / 70%);
  color: #cfa79d;
}

.weeklyCalendar .weekdayButton:focus {
  outline: none !important;
}
@media (min-width: 361px) and (max-width: 769px) {
  .weeklyCalendar .weekdayButton {
    padding: 0.375rem 4px;
  }
}

@media (max-width: 360px) {
  .weeklyCalendar .weekdayButton {
    padding: 0.375rem 1px;
  }
}

.viewEvents {
  margin-top: 80px;
  position: relative;
}

.viewEvents .ant-menu-item {
  background: #fff;
}

.viewEvents .ant-menu-light .ant-menu-item:hover,
.viewEvents .ant-menu-light .ant-menu-item-active,
.viewEvents
  .ant-menu-light
  .ant-menu:not(.ant-menu-inline)
  .ant-menu-submenu-open,
.viewEvents .ant-menu-light .ant-menu-submenu-active,
.viewEvents .ant-menu-light .ant-menu-submenu-title:hover,
.viewEvents .ant-menu-item-selected {
  background-color: #fbf7ec !important;
  color: #cfa79d;
}
.viewEvents .ant-menu-item-selected::after {
  border-color: #cfa79d;
}
.viewEvents .ant-menu-submenu-title,
.viewEvents .ant-menu-submenu-title:hover {
  background-color: #e6e6e1;
  color: #cfa79d;
}
.viewEvents .ant-drawer-body {
  padding: 0 !important;
}
@media (max-width: 768px) {
  .viewEvents {
    margin-top: 0px;
  }
}

.reserved-events {
  width: calc(100vw - 260px);
  height: 100vh !important;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: #000; */
}
@media (max-width: 991px) {
  .reserved-events {
    width: 100vw;
  }
}

.drawer-container {
  height: 100vh;
  position: relative;
}

.viewEvents .open-close-btn {
  width: 200px;
  height: 200px;
  cursor: pointer;
  position: fixed;
  left: -100px;
  top: 50px;
  /* transform: translateY(-50%); */
  z-index: 9999;
  border: none;
  color: #cfa79d;
  background-color: transparent;
}

.open-close-btn__icon {
  font-size: 100px !important;
}

.drawer-container .drawer {
  position: absolute;
  height: 100vh;
  transition: 2s;
  position: fixed;
  /* top: 10px; */
}

.open-close-btn__left,
.open-close-btn__left:hover {
  width: 60px;
  height: 60px;
  cursor: pointer;
  border: none;
  color: #cfa79d;
  background-color: transparent;
}

.filter-loading {
  width: 100%;
  height: 100vh;
  background: #eee;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999999;
  overflow: hidden;
  /* top: 50%;
  left: 50%; */
  /* transform: translate(-50%, -50%); */
}

.filter-loading .loader {
  width: 100px;
  height: 100px;
  border: 20px solid #f3f3f3;
  border-top: 20px solid #cfa79d;
}

@media (min-width: 992px) {
  .filter-loading {
    width: calc(100% - 256px);
  }
}

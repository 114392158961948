#filterModal {
  float: right;
  margin-top: 0px;
  margin-bottom: 0;
  border-right: none;
}
#filterModal::-webkit-scrollbar {
  display: none !important;
}
  /* .filterBar .SVGCross {
    cursor: pointer;
    position: relative;
    left: 425px;
  } */
#filterModal .modal-content {
  border: none;
  border-radius: 0;
}
.filterModal .filterBar .smContainer {
  display: none;
}

.filterModal .filterBar::-webkit-scrollbar {
  display: none;
}
.filterModal .slidecontainer .PriceInput {
  border-radius: 26px;
  border: 1px solid #cfa79d;
  width: 100%;
  height: 40px;
  padding: 5px 15px 5px 20px;
  margin-top: 23px;
}
.filterModal .slidecontainer .PriceInput:focus-visible {
  outline: none !important;
}
.filterModal .filterBar {
  background-color: #fff;
  width: 100%;
  float: right;
  padding: 10px;
  height: 100vh;
  overflow-y: scroll;
}
.filterBar label {
  font-weight: 500;
  font-size: 19px;
  display: block;
  margin-top: 10px;
}
.filterBar input::-webkit-calendar-picker-indicator {
  display: none;
}
.filterBar .categoryBox {
  border-radius: 20px;
  border: 1px solid #cfa79d;
  width: 70%;
}

.filterBar #subSpecializationList,
.filterBar #LanguagesList {
  border-radius: 20px;
  border: 1px solid #cfa79d;
  width: 70%;
  display: inline-block;
  margin-bottom: 10px;
}

.filterBar .addSubSpecializationBtn {
  background-color: #cfa79d;
  border-radius: 26px;
  color: #fff;
  float: right;
}
.filterBar .addSubSpecializationBtn:hover {
  color: #fff;
}
.filterBar .subSpecialization {
  display: inline-block;
  padding: 5px 10px;
  margin: 10px;
  text-align: center;
  background-color: #cfa79d;
  border-radius: 17px;
  font-size: 15px;
  font-weight: 600;
  color: #fff;
  text-align: center;
}
.filterBar .subSpecializationBox .Cross {
  cursor: pointer;
  transform: scale(0.8);
  margin-left: 5px;
}
.filterBar .slidecontainer {
  width: 70%; /* Width of the outside container */
  display: inline-block;
}

/* The slider itself */
.filterBar .slider {
  -webkit-appearance: none; /* Override default CSS styles */
  appearance: none;
  width: 100%; /* Full-width */
  height: 3px; /* Specified height */
  background: #d3d3d3; /* Grey background */
  outline: none; /* Remove outline */
  opacity: 1; /* Set transparency (for mouse-over effects on hover) */
  -webkit-transition: 0.2s; /* 0.2 seconds transition on hover */
  transition: opacity 0.2s;
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.filterBar .slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  outline: none; /* Remove outline */
  border: none;
  width: 20px;
  height: 20px;
  background: url("../../Assets/Group\ 4354.png") center center no-repeat;
  cursor: pointer;
}

.filterBar .slider::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background: url("../../Assets/Group\ 4354.png") center center no-repeat;
  cursor: pointer;
}
.filterBar .price {
  color: #b5b5b5;
  float: right;
}
.filterBar .currencyBox {
  width: 70%;
  border: 1px solid #cfa79d;
}
.filterBar .genderBox {
  margin-bottom: 20px;
}
.filterBar .genderBox .genderSection {
  display: inline-block;
  cursor: pointer;
  margin: 20px 10px;
}
.filterBar .personalInfoForm .genderBox .genderSection:nth-of-type(4) .icon {
  transform: translateX(-20px) scale(0.7);
}
.filterBar .genderBox .genderSection .icon {
  transform: scale(0.7);
}
.filterBar .btnContainer {
  text-align: center;
  width: 100%;
}

.filterBar .btnContainer .btn {
  border: 1px solid #cfa79d;
  border-radius: 26px;
  border: 1px solid #cfa79d;
  padding: 1px 20px;
  margin: 10px;
}
.filterBar .btnContainer .btn:first-of-type {
  background: #fff;
  color: #cfa79d;
}

.filterBar .btnContainer .btn:nth-of-type(2) {
  color: #fff;
  background-color: #cfa79d;
}
/* @media (min-width: 375px) and (max-width: 812px) {
  .filterBar .SVGCross {
    cursor: pointer;
    position: relative !important;
    left: 318px !important;
  }
} */
@media (max-width: 769px) {
  .filterModal {
    width: 100%;
    min-height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    overflow-y: scroll;
    z-index: 20;
  }

  .filterModal .filterBar {
    width: 100%;
    min-height: 100vh;
    padding-bottom: 170px;
  }
  .filterBar .SVGCross {
    cursor: pointer;
    position: relative !important;
    left: 328px !important;
    /* right: 0px !important; */
  }
  .filterModal .filterBar .smContainer {
    display: block;
  }
  .filterModal .filterBar .smContainer div:first-of-type {
    margin: 0;
    position: absolute;
    top: 20px;
    left: 45%;
    transform: translate(0, -45%);
    font-size: 16px;
    font-weight: bold;
  }
  /* .filterBar .SVGCross {
    cursor: pointer;
    position: relative;
    left: 425px;
  } */
  .filterModal .searchBox {
    position: relative;
    margin-top: 10px;
  }
  .filterModal .searchBox > input {
    border-radius: 26px;
    border: none;
    background-color: #ededed;
    width: 100%;
    height: 40px;
    padding: 5px 15px 5px 20px;
  }

  .filterModal .searchBox > input:focus {
    outline: none !important;
  }
  .filterModal .searchBox > input::placeholder {
    font-size: 12px;
    color: #898989;
    font-weight: 600;
  }
  .filterModal .searchBox .searchLogo {
    position: absolute;
    top: 5px;
    right: 0px;
    transform: scale(0.7);
    cursor: pointer;
    margin: 0 10px;
  }
  .filterBar .btnContainer {
    position: fixed;
    bottom: 3%;
    left: 50%;
    transform: translate(-50%, -3%);
    margin: auto;
  }
  .filterBar .btnContainer .btn:first-of-type,
  .filterBar .btnContainer .btn:nth-of-type(2) {
    width: 130px;
    height: 30px;
    line-height: 30px;
    padding: 0;
  }
}

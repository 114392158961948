.waitingRoom .avatarContainer {
  width: 100px;
  height: 100px;
}
.waitingRoom .avatarContainer>img{
  width:100%;
  height:100%;
  border: 5px solid #cfa79d;
	box-shadow: 9px 9px 6px rgba(0, 0, 0, 0.16);

}
.waitingRoom .btn,.waitingRoom .btn:hover {
	background-color:  #cfa79d;
  color: #fff;
	margin: 10px auto;
  font-size: 14px;
	border-radius: 26.7px;
	box-shadow: 2px 3px 8px rgba(122, 66, 56, 0.4);
   padding: 1px 20px;
}
.waitingRoom .btn.chatBtn{
	background-color: transparent;
	color:#cfa79d;
	border: 1px solid #cfa79d;
}
.waitingRoom .btn.disabled ,.waitingRoom .btn.disabled:hover {
	background-color:  #e2c4bc;
}
.waitingRoom .initials{
  background-color: #fbf7ec;
	color: #898989;
	width: 70px;
	height: 70px;
	line-height: 70px;
	text-align: center;
	font-size: 25px;
	font-weight: 500;
	border-radius: 50%;
    display: inline-block;
}

.waitingRoom video{
	width: 20% !important;
}

.unreadMsgDot{
	display: inline-block;
	content: "";
	width: 10px;
	height: 10px;
	border-radius: 50%;
	background-color: red;
}

 .emailCheckSign .right  {
	transform: scale(0.5);
	position: absolute;
	right: 0;
	top: -3px;
}
 .emailContainer {
	position: relative;
}
.emailCheckSign .wrong  {
	transform: scale(0.8);
	position: absolute;
	right: 7px;
	top: 5px;
}
.ant-modal-content , 
.ant-modal-header{
    border-radius: 15px !important;
  }

  .changeEmail .required {
    border: 1px solid red !important;
  }
  .ant-modal-footer {
    display: none !important;
  }
  .ant-modal-content
  {
      height:fit-content !important;
  }
  .ant-modal-body
  {
      position: relative;
      top: 7%;
  }

  .ant-modal-body .btn {
	border-radius: 26px;
	background-color: #CFA79D !important;
	color: #fff !important;
	width: 40% !important;
	height: 30px;
	line-height: 15px;
	font-weight: bold;
	margin: 20px auto 0 !important;
	box-shadow: 4px 6px 10px rgba(186, 186, 186, 0.4);
}
  @media (max-width: 768px) {
      .ant-modal {
  width: 87% !important;
      }
  }
  
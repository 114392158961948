.accountMyReviews {
	height: calc(100vh - 165px);
	overflow-y: scroll;
}

.accountMyReviews::-webkit-scrollbar {
	display: none;
}
.accountMyReviews .heading {
	font-weight: bold;
	font-size: 23px;
	padding-top: 20px;
}
.accountMyReviews .icon {
	display: inline-block;
	transform: scale(0.7) translateY(-5px);
}

.accountMyReviews .form::-webkit-scrollbar {
	display: none;
}
.accountMyReviews .form {
	width: 70%;
	margin: 20px auto 0 10px;
}
@media (max-width: 768px) {
	.accountMyReviews {
		height: calc(100vh - 250px);
	}
	.accountMyReviews .form {
		width: 100%;
	}
}

.accountMyReviews .reviewConainer {
	display: flex;
	height: 60vh;
	justify-content: center;
	align-content: center;
	align-items: center;
}

/* .ant-modal-content {
	border-radius: 15px !important;
}
.ant-modal-footer {
	display: none !important;
}
.welcomeModal .ant-modal-content {
	height: 300px;
}
.welcomeModal .ant-modal-body {
	position: relative;
	top: 7%;
}
@media (max-width: 768px) {
	.ant-modal {
		width: 87% !important;
	}
	.welcomeModal .ant-modal-body {
		height: unset !important;
	}
} */

.welcomeModal .ant-modal-content {
	padding-bottom: 40px;
}

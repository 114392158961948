.doctorDetailCalendar {
	font-size: 14px;
	font-weight: 500;
	margin-bottom: 10px;
}

.doctorDetailCalendar .availabilityTitle {
	font-weight: bold;
	color: #707070;
	margin: 20px auto;
	font-size: 20px;
}
.doctorDetailCalendar .calendarHeaderTitle {
	text-align: center;
}
.doctorDetailCalendar .calendarHeaderContainer .CalendarControls {
	cursor: pointer;
	transform: translateY(10px);
}
.doctorDetailCalendar .timezoneText {
	font-weight: 600;
}
@media (max-width: 769px) {
	.doctorDetailCalendar .availabilityTitle {
		margin: 20px;
	}
}

#dropdownModal {
  float: right;
  margin-top: 56px;
}
#dropdownModal .dropDownMenuBar .dropDownItem {
  font-weight: 500;
  font-size: 16px;
  margin: 5px 10px;
  cursor: pointer;
}

#dropdownModal .dropDownMenuBar .dropDownItem.signout {
  font-weight: 600;
}

#dropdownModal .dropDownMenuBar .dropDownItem.signout > span {
  margin-right: 6px;
}
#dropdownModal a {
  color: #000;
}

#dropdownModal a:hover {
  color: #000;
  text-decoration: none;
}
@media (max-width: 768px) {
  #dropdownModal {
    display: none;
  }
}

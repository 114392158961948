.doctorDetails .pageContainer .availabilityBox {
	text-align: center;
}
.doctorDetails .pageContainer .availabilityBox img {
	border-radius: 50%;
	margin: 0 auto;
	width: 120px;
	height: 120px;
}
.doctorDetails .nextAvSession{
	font-weight: bold;
	cursor: pointer;
}
.doctorDetails .initials {
	border-radius: 50%;
	background-color: #d7f4e8;
	margin: 0 auto;
	width: 120px;
	height: 120px;
	line-height: 120px;
	text-align: center;
	color: #898989;
	font-size: 25px;
	font-weight: 500;
	box-shadow: 0 4px 8px rgba(44, 39, 56, 0.08);
}
.doctorDetails .smBox {
	display: inline-block;
	margin: 0 5px;
}
.doctorDetails .watchVideoBtn {
	border: 1px solid #cfa79d;
	margin: 10px auto 0;
	height: 29px;
	border-radius: 26.7px;
	font-weight: bold;
	font-size: 10px;
	background-color: #fff;
}
.doctorDetails .watchVideoBtn span {
	margin-left: 5px;
	display: inline-block;
	transform: translateY(-2px);
}
.doctorDetails .priceBox {
	color: #cfa79d;
	font-weight: bold;
	font-size: 14px;
	margin-top: 20px;
}

.doctorDetails .bookCoachBtn {
	background-color: #cfa79d;
	margin: 0 auto;
	height: 29px;
	border-radius: 26.7px;
	font-weight: bold;
	font-size: 10px;
	color: #fff !important;
}

.doctorDetails .bookCoachBtn:hover {
	color: #fff;
}
.doctorDetails .detailsBox {
	padding-right: 10px;
}
.doctorDetails .fullName {
	color: #3c3c3b;
	font-weight: bold;
	font-size: 25px;
	font-family: "Noto Serif JP", serif;
}
.doctorDetails .specialization {
	color: #3c3c3b;
	font-weight: 500;
	font-size: 15px;
}
.doctorDetails .coachRate > div {
	transform: scale(2);
	display: inline-block;
	margin: 0 10px;
}

.doctorDetails .subSpecializationBox {
	margin: 20px 0;
	width: 100%;
}
.doctorDetails .subSpecializationBox span {
	color: #989898;
	background-color: #edeeef;
	font-size: 14px;
	border-radius: 17px;
	padding: 5px 15px;
	margin-right: 10px;
	margin-top: 10px;
	display: inline-block;
}
.doctorDetails .bioBox .bio {
	margin-top: 40px;
	font-size: 20px;
	font-weight: bold;
	color: #707070;
	font-family: "Noto Serif JP", serif;
}
.doctorDetails .bookCoachContainer.sm {
	display: none;
}
.doctorDetails .timeContainer {
	margin-top: 20px;
}
.doctorDetails .timeContainer .time {
	background-color: #edeeef;
	border-radius: 17px;
	cursor: pointer;
	width: 56px;
	margin-bottom: 10px;
	transition: all 0.4s ease-in;
	-webkit-transition: all 0.4s ease-in;
	-moz-transition: all 0.4s ease-in;
	-o-transition: all 0.4s ease-in;
	text-align: center;
}

.doctorDetails .timeContainer .time:hover {
	background-color: #cfa79d;
	color: #eee;
}

@media (max-width: 768px) {
	.doctorDetails .initials.smBox {
		width: 90px;
		height: 90px;
		line-height: 90px;
		font-size: 19px;
	}
	.doctorDetails .fullName {
		font-size: 16px;
	}
	.doctorDetails .specialization {
		font-size: 14px;
	}
	.doctorDetails .details.smBox {
		transform: translateY(20px);
	}
	

	.doctorDetails .bookCoachContainer.sm {
		position: fixed;
		bottom: 110px;
		right: 20px;
		display: flex;
		align-items: center;
		flex-direction: column;
	}

	.doctorDetails .watchVideoBtn {
		margin-top: 0;
		margin-right: 10px;
	}
}
@media (min-width: 769px) {
	.doctorDetails .pageContainer {
		margin-top: 100px;
	}
}

.testt {
	width: 100% !important;
	word-break: break-word;
}

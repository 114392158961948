.lobbyForm {
	max-width: 300px;
	margin: 50px auto;
}

.lobbyForm button {
	background: #333e5a;
	color: #fff;
	font-size: 16px;
	padding: 0.4em;
	border-radius: 6px;
	border: 1px solid transparent;
}
.lobbyForm button:hover {
	filter: brightness(150%);
}

.lobbyForm > div {
	width: 100%;
	margin-bottom: 1em;
}
.lobbyForm > div > label {
	display: block;
	margin-bottom: 0.3em;
}
.lobbyForm > div > input {
	display: block;
	width: 100%;
	font-size: 16px;
	padding: 0.4em;
	border-radius: 6px;
	border: 1px solid #333e5a;
}

.room {
	height: 100vh !important;
}

.room > h3 {
	text-align: center;
	font-weight: 300;
	margin-bottom: 1em;
}

.local-participant {
	width: 100%;
	height: 100vh;
	position: absolute;
	top: 0;
	left: 0;
}
.remote-participants {
	height: 100vh;
	width: 100%;
	background: #333e5a;
	overflow: hidden;
	color:#fff;
	display:flex;
	justify-content: center;
	align-items: center;
	font-size: 25px;
}
.noSessionsPage {
	background: #333e5a;
	height: 100vh;
	width: 100%;
	text-align: center;
	color: #eee;
	font-size: 30px;
	text-transform: uppercase;
}
.noSessionsPage span {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
.remote-participants.waitingMsg {
	color: #eee;
	background: #333e5a;
	font-size: 25px;
}
.remote-participants.waitingMsg > span {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
.participant {
	display: inline-block;
}
.participant:last-child {
	margin-right: 0;
}
.participant h3 {
	text-align: center;
	padding-bottom: 0.5em;
	color: #fff;
}

.video {
	height: 100vh;
	display: block;
	margin: 0 auto;
	border-radius: 6px;
}

.remote-participants .participant {
	width: 100% !important;
}
.remote-participants .participant .videoBox{
	position: relative !important;
	height: calc(100vh - 58px);
}
.remote-participants .participant video {
	width: 100% !important;
	height: 100%;
}

.local-participant .participantRoom .participant {
	background: none !important;
	width: 100%;
}

.local-participant .participantRoom .participant .videoBox {
	width: 20%;
	float: right;
	margin-top: 20px;
	margin-right: 20px;
	position: relative !important;
}
.local-participant .participantRoom .participant .videoBox video{
	width:100%
}

.participant .nextSessionCounterBox {
	background-color: #eeeed6d2;
	text-align: center;
	display: inline-block;
	padding: 5px;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
}
.participant .nextSessionCounterBox .btn {
	display: block;
	color: #eeeed6;
	border: 1px solid #eeeed6;
	border-radius: 60px;
}
.participant .red_text {
	color: red;
}

.InvalidtokenModal{
	font-size: 17px;
	font-weight: 500;
}
.testModal .label{
	display: block;
	font-weight: 600;
}
.testModal .levelContainer{
	width: 60%;
	margin: auto;
	height: 10px;
	background-color: #EDEDED;
}
.testModal .levelContainer .levelBar{
	height: 100%;
	max-width: 100%;
	background: #0C72EA;
}
.remote-participants .remoteName{
	font-size:30px;
	color:#fff;
	height:100% !important;
	background:#000
}
.remote-participants .participant{
	height: 100%;
}
.participant .micOffFigure{
	width:20px;
}
 .participant .micOffFigure>img{
 	 width:100%;
	 filter: invert(13%) sepia(100%) saturate(7124%) hue-rotate(7deg) brightness(103%) contrast(115%);
}
.room .statusBar{
	color:#fff;
	position: absolute;
	bottom: 0px;
	height: 40px;
	left: 0;
	width:100%;
	background:rgba(0,0,0,.4);
	z-index:9
}


.videoChattingRoom{
	height: 100%;
	width: 40%;
	position: absolute;
	top:0;
	right:0;
	background-color: #242424;
	color:#fff;
	padding:20px;
	z-index:99999999
}
.videoChattingRoom .title{
	font-size: 18px;
	font-weight: 600;
}
.videoChattingRoom .closeText{
	cursor: pointer;
}
.videoChattingRoom .chatArea{
	height: calc(100% - 80px);
	overflow-y: auto;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.videoChattingRoom .chatArea::-webkit-scrollbar {
	display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  .videoChattingRoom .chatArea {
	-ms-overflow-style: none;  /* IE and Edge */
	scrollbar-width: none;  /* Firefox */
  }
.videoChattingRoom .chatArea .chatBlock .chatInitials{
	background: #66B5F9;
	border-radius: 10px;
	padding: 10px;
    height: fit-content;
}

.videoChattingRoom .chatArea .chatBlock .message{
	background-color: #383838;
	border-radius: 10px;
	padding: 10px;
	margin:0 10px;
}
.videoChattingRoom .chatInput{
	background-color: transparent;
	color:#8C8C8C;
	border: 1px solid #4B4B4B;
	border-radius: 10px;
	outline: none;
	padding: 10px;
	margin: 10px;
}
.room .statusBar .name{
	display: flex;
	align-items: flex-start;
}
@media (max-width:768px){
	.videoChattingRoom{
			width: 100%;
	}
	.room .statusBar .name{
		font-size: 12px;
	}	
}
.footer {
	background: #ae8f7b center center;
	background-size: cover;
	padding: 60px 0px;
	margin-top: 40px;
}
.footer .logo {
	transform: translateX(-15px);
}
.footer .weloDescription,
.footer .copyRight {
	font-weight: 500;
	font-size: 16px;
}

.footer .socialMediaIcon,
.footer .FooterItems {
	cursor: pointer;
}
.footer .icon{
	width: 25px;
}
.footer .fb-icon,
.footer .insta-icon {
	width: 2rem;
	height: 2rem;
	padding: 0.25rem;
	background: #fff;
	border-radius: 0.25rem;
	margin: 10px;
}
.footer .title {
	margin-bottom: 20px;
	font-weight: 600;
	font-size: 16px;
	font-family: "Noto Serif JP", serif;
	margin-top: 30px;
}
.footer a {
	color: #000;
}

.footer a:hover {
	color: #000;
	text-decoration: none;
}
@media (max-width: 768px) {
	/* .footer {
		display: none;
	} */
}

.accountPersonalInfo {
	height: calc(100vh - 165px);
	overflow-y: scroll;
}
.accountPersonalInfo .Pen {
	position: relative !important;
	left: 479px !important;
}
.accountPersonalInfo .disable {
	/* background-color: red !important; */
	background-color: #cfa79d94 !important;
}
.accountPersonalInfo::-webkit-scrollbar {
	display: none;
}

.accountPersonalInfo .heading {
	font-weight: bold;
	font-size: 23px;
	margin-top: 20px;
}
.accountPersonalInfo form {
	width: 50%;
	margin: 20px auto 0;
}
.accountPersonalInfo .picLabel {
	text-align: center;
	width: 100%;
	margin-bottom: 20px;
}

.accountPersonalInfo label {
	font-weight: bold;
	font-size: 17px;
	margin-top: 5px;
}
.accountPersonalInfo input {
	border: 1px solid #cfa79d;
}
.accountPersonalInfo .emailContainer {
	position: relative;
}

.accountPersonalInfo .emailCheckSign .right {
	transform: scale(0.5);
	position: absolute;
	right: 0;
	top: 1px;
}

.accountPersonalInfo .emailCheckSign .wrong {
	transform: scale(0.8);
	position: absolute;
	right: 7px;
	top: 8px;
}

.accountPersonalInfo .btnContainer {
	text-align: center;
	margin-bottom: 50px;
}
/* .accountPersonalInfo .leave .btn-primary {
	border-radius: 26px ;
	background-color: #CFA79D;
	color: #fff;
	width: 30%;
	height: 30px;
	line-height: 15px;
	font-weight: bold;
	margin: 20px auto 0;
	margin-top: 8px;
	margin-bottom: 8px;
	box-shadow: 4px 6px 10px rgba(186, 186, 186, 0.4);
} */
.accountPersonalInfo .btn:hover {
	color: #fff;
}
.accountPersonalInfo .button {
	border: none;
	cursor: pointer;
	color: #0370ff;
	background-color: transparent;
	font-size: 12px;
	font-weight: bold;
	margin-top: 10px;
}
.accountPersonalInfo .hidden {
	border: none;
	color: #0370ff;
	background-color: transparent;
	font-size: 12px;
	font-weight: bold;
	margin-top: 10px;
}
.accountPersonalInfo .button:hover {
	border: none;
	cursor: pointer;
	color: #0370ff;
	text-decoration: underline;
}
.accountPersonalInfo label {
	font-weight: bold;
	font-size: 17px;
	margin-top: 16px;
}
 .danger {
	border: 1px solid red !important;
}
@media (max-width: 768px) {
	.accountPersonalInfo .avatarCropper > div:nth-of-type(2) {
		margin-top: 20px;
	}

	.accountPersonalInfo form {
		width: 90% !important;
		padding-bottom: 35px;
	}
	.accountPersonalInfo .Pen {
		position: relative !important;
		left: 246px !important;
	}
}
.accountPersonalInfo .next {
	width: 75px;
	height: 32px;
	font-size: 12px;
}
.accountPersonalInfo .avatarCropper > div:nth-of-type(2) {
	background-color: transparent !important;
}
.accountPersonalInfo .avatarCropper > div:nth-of-type(2) button {
	position: relative;
	top: -640%;
	left: 197px;
}
.accountPersonalInfo .avatarCropper div[name] > button:nth-of-type(1) {
	display: none !important;
}
.accountPersonalInfo .avatarCropper > div:nth-of-type(2) div {
	position: relative !important;
	bottom: 60px !important;
	width: 100%;
}
.accountPersonalInfo .photoUploader {
	width: 208px;
	height: 208px;
	border-radius: 7px !important;
	margin: auto;
	border: 4px dashed #cfa79d;
	margin-bottom: 40px;
	padding: 5px;
}

.addPhoto .photoUploader.error {
	border: 4px dashed red;
}
.addPhoto .photoUploaderContainer {
	position: relative !important;
}
.addPhoto .photoUploader svg {
	color: #cfa79d !important;
}
.addPhoto .photoUploader svg ~ div {
	display: none;
}
.addPhoto .closeIconn {
	position: absolute;
	top: -20px;
	right: 30px;
	cursor: pointer;
}

.addPhoto .coachImgInstrc .photoTips {
	font-size: 16px;
	font-weight: bold;
}
.addPhoto .coachImgInstrc > div:nth-of-type(2) > span,
.addPhoto .coachImgInstrc > div:nth-of-type(3) > span {
	color: #cfa79d;
}

.addPhoto .photoUploader {
	width: 208px;
	height: 208px;
	border-radius: 7px !important;
	margin: auto;
	border: 4px dashed #cfa79d;
	margin-bottom: 40px;
	padding: 5px;
}


.addPhoto .avatarCropper > div:nth-of-type(2) {
	position: absolute;
	top: 330px !important;
	background-color: #fff !important;
}

.addPhoto .avatarCropper > div:nth-of-type(2) span {
	background-color: #cfa79d !important;
}
div[name] {
	position: absolute;
	left: -50px !important;
	top: 73px !important;
}

/* @media (max-width: 768px) {
	.addPhoto {
		text-align: center;
	}
	.addPhoto .logo.sm {
		margin-top: 20px;
	}
	.addPhoto .logo.lg {
		display: none;
	}
	.addPhoto .photoUploader svg {
		color: #cfa79d !important;
	}
	.addPhoto .photoUploader svg ~ div {
		display: none;
	}
	.addPhoto .addPhotoTitle {
		font-size: 22px;
		font-weight: bold;
		text-align: left;
		margin-left: 20px;
		margin-bottom: 10px;
		color: #707070;
	}
	.addPhoto .coachImgInstrc {
		text-align: left;
		font-size: 15px;
		margin-bottom: 40px;
		margin-left: 40px;
	}
	.addPhoto .coachImgInstrc .photoTips {
		font-size: 16px;
		font-weight: bold;
	}
	.addPhoto .coachImgInstrc > div:nth-of-type(2),
	.addPhoto .coachImgInstrc > div:nth-of-type(3) {
		font-size: 16px;
		color: #cacaca;
	}
	.addPhoto .coachImgInstrc > div:nth-of-type(2) > span,
	.addPhoto .coachImgInstrc > div:nth-of-type(3) > span {
		color: #cfa79d;
	}
	.addPhoto .photoUploader {
		width: 208px;
		height: 208px;
		border-radius: 7px !important;
		margin: auto;
		border: 4px dashed #cfa79d;
		margin-bottom: 40px;
		padding: 5px;
	}

	.addPhoto .photoUploader.error {
		border: 4px dashed red;
	}
	.avatarCropper > div:nth-of-type(2) {
		position: absolute;
		top: 333px !important;
		background-color: #fff !important;
	}
	.avatarCropper > div:nth-of-type(2) span {
		background-color: #cfa79d !important;
		width: 22px !important;
		height: 22px !important;
	}
	.addPhoto div[name] {
		position: absolute;
		left: -50px !important;
		top: 40px !important;
	}
	.addPhoto button {
		border: 1px solid #cfa79d;
		width: 130px;
		font-size: 13px;
		color: #cfa79d;
		font-weight: bold;
		margin: 50px 10px 0;
		border-radius: 19px;
		height: 30px;
	}
	.addPhoto button:hover {
		background: #cfa79d;
		color: #fff;
	}

	.addPhoto .progressBar {
		margin: 40px auto;
		width: 279px;
		border-radius: 21px;
		height: 7px;
		background-color: #d5d5d5;
	}

	.addPhoto .progressBar.lg {
		display: none;
	}

	.addPhoto .progressBar .value {
		background-color: #cfa79d;
		border-radius: 21px;
		width: 50%;
		height: 100%;
	}
	.addPhoto .KeyPage {
		position: relative !important;
		top: 0px !important;
		left: 4.5px !important;
	}
}
@media (min-width: 769px) {
	.addPhoto {
		text-align: center;
	}
	.addPhoto .logo.sm {
		display: none;
	}
	.addPhoto .logo.lg {
		width: 20px;
		height: 20px;
		margin-top: 10px;
		margin-left: 20px;
		margin-bottom: 20px;
		padding: 10px;
	}
	.addPhoto .divider {
		height: 1px;
		background-color: #edeeef;
	}
	.addPhoto .photoUploader svg {
		color: #cfa79d !important;
	}
	.addPhoto .photoUploader svg ~ div {
		display: none;
	}
	.addPhoto .addPhotoTitle {
		font-size: 22px;
		font-weight: bold;
		text-align: left;
		margin-left: 70px;
		margin-top: 20px;
		color: #707070;
		position: absolute;
		top: 80px;
	}

	.addPhoto .coachImgInstrc {
		text-align: left;
		font-size: 15px;
		margin-bottom: 40px;
		margin-left: 40px;
	}
	.addPhoto .coachImgInstrc div:first-of-type {
		font-size: 16px;
		font-weight: bold;
	}
	.addPhoto .coachImgInstrc > div:nth-of-type(2),
	.addPhoto .coachImgInstrc > div:nth-of-type(3) {
		font-size: 16px;
		color: #cacaca;
	}
	.addPhoto .coachImgInstrc > div:nth-of-type(2) > span,
	.addPhoto .coachImgInstrc > div:nth-of-type(3) > span {
		color: #cfa79d;
	}

	.addPhoto .photoUploaderContainer {
		width: 40%;
		transform: translateY(-10vh);
		padding-top: 50px;
		padding-bottom: 20px;
		border-radius: 61px;
		margin: 80px auto 0;
		box-shadow: 0 -5px 24px rgba(113, 113, 113, 0.13);
	}

	.addPhoto .photoUploader {
		width: 208px;
		height: 208px;
		border-radius: 7px !important;
		margin: auto;
		border: 4px dashed #cfa79d;
		margin-bottom: 40px;
		padding: 5px;
	}

	.addPhoto .photoUploader.error {
		border: 4px dashed red;
	}
	.avatarCropper > div:nth-of-type(2) {
		position: absolute;
		top: 350px !important;
		background-color: #fff !important;
	}
	.avatarCropper > div:nth-of-type(2) span {
		background-color: #cfa79d !important;
	}
	.addPhoto div[name] {
		position: absolute;
		left: -50px !important;
		top: 1px !important;
	}
	.addPhoto button {
		border: 1px solid #cfa79d;
		width: 130px;
		font-size: 13px;
		color: #cfa79d;
		font-weight: bold;
		margin: 50px 10px 0;
		border-radius: 19px;
		height: 30px;
	}
	.addPhoto button:hover {
		background: #cfa79d;
		color: #fff;
	}

	.addPhoto .progressBar {
		width: 279px;
		border-radius: 21px;
		height: 7px;
		background-color: #d5d5d5;
	}
	.addPhoto .progressBar.sm {
		display: none;
	}

	.addPhoto .progressBar.lg {
		margin: 0px auto;
		transform: translateY(-3vh);
	}
	.addPhoto .progressBar .value {
		background-color: #cfa79d;
		border-radius: 21px;
		width: 50%;
		height: 100%;
	}
	.addPhoto .KeyPage {
		position: relative !important;
		top: 0px !important;
		left: 4.5px !important;
	}
}
@media (min-width: 493px) and (max-width: 768px) {
	.addPhoto .KeyPage {
		position: relative !important;
		top: 24px !important;
		left: 4.5px !important;
	}
}

@media (min-width: 769px) and (max-width: 1230px) {
	.addPhoto .KeyPage {
		position: relative !important;
		top: -24px !important;
		left: 4.5px !important;
	}
}

.closeIcon {
	position: absolute;
	top: 30px;
	right: 30px;
	cursor: pointer;
}
.addPhoto .KeyPage .btn {
	color: rgba(207, 167, 157, 0.7);
	border-color: rgba(207, 167, 157, 0.7);
}
.addPhoto .KeyPage .btn:hover {
	background: rgba(207, 167, 157, 0.7);
	color: #fff;
} */

.app {
  font-family: "Montserrat", sans-serif;
  color: #505050;
}

.page {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  right: 0;
}
.ant-picker-content
  > ul:nth-of-type(2)
  .ant-picker-time-panel-cell.ant-picker-time-panel-cell-disabled {
  display: none !important;
}
.slide-enter,
.slide-exit {
  transition: transform 400ms ease-out;
}
.flex-1 {
  flex: 1;
}
.slide-enter {
  transform: translateX(100%);
}

.slide-enter.slide-enter-active {
  transform: translateX(0%);
}

.slide-exit {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transform: translateX(0%);
}

.slide-exit-active {
  transform: translateX(-100%);
}
.rc-time-picker-panel-narrow {
  max-width: fit-content !important;
}
.rc-time-picker-panel-select {
  width: 50% !important;
}
.rc-time-picker-panel-select ul li {
  padding: 0 !important;
  text-align: center;
}
a {
  color: #505050;
}

a:hover {
  color: #505050;
  text-decoration: none !important;
}
body::-webkit-scrollbar-track {
  background: #f1f1f1;
}
body::-webkit-scrollbar-thumb {
  background: #7a4238;
}
body::-webkit-scrollbar {
  width: 5px;
}

body {
  overflow-x: hidden;
}
/* .ant-switch:hover{
	background-color: transparent !important;
} */
.ant-switch.ant-switch-checked {
  background-color: #cfa79d;
}
.cursorPointer {
  cursor: pointer;
}
.overflow_hidden {
  overflow-y: hidden !important;
  height: 100vh;
}
.checkEmailText {
  color: #707070;
  font-size: 14px;
  font-weight: 500;
}
.pendingBox {
  position: fixed;
  left: 5px;
  top: 160px;
  background-color: #fbf7ec;
  color: #cfa79d;
  border-radius: 21px;
  z-index: 99999999;
  padding: 20px;
}
.sessionCountdown .btn.sessionActive {
  background-color: #cfa79d;
  color: #fbf7ec;
}

.sessionCountdown .btn {
  border: 1px solid #cfa79d;
  border-radius: 60px;
  color: #cfa79d;
  font-size: 14px;
  padding: 0;
  width: 120px;
  height: 27px;
  margin: 10px;
}
.ant-input-password,
.ant-select-selector {
  border: 1px solid #cfa79d !important;
}
.ant-input-password input {
  height: 100%;
}
.mainColor {
  color: #cfa79d;
}

.fw-100 {
  font-weight: 100;
}
.fw-200 {
  font-weight: 200;
}
.fw-300 {
  font-weight: 300;
}
.fw-400 {
  font-weight: 400;
}
.fw-500 {
  font-weight: 500;
}
.fw-600 {
  font-weight: 600;
}
.fw-700 {
  font-weight: 700;
}
.fw-800 {
  font-weight: 800;
}
.fw-900 {
  font-weight: 900;
}

.f-12 {
  font-size: 12px;
}
.f-14 {
  font-size: 14px;
}
.f-18 {
  font-size: 18px;
}
.f-21 {
  font-size: 21px;
}
.f-28 {
  font-size: 28px;
}
.f-32 {
  font-size: 32px;
}
.guruDetails {
  display: flex;
  flex: 1;
  flex-direction: column;
  border-radius: 70px;
  padding: 10%;
  box-shadow: 0 -5px 24px rgba(113, 113, 113, 0.13);
}
.guruDetails .guruAvatar {
  border-radius: 50%;
  width: 80px;
}

.guruDetails .guruInfo {
  border-bottom: 1px solid;
}
.authSolidSection {
  background: #efefef !important;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
}
.authPage {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.authPage .authForm {
  width: 70%;
  text-align: center;
  border-radius: 70px;
  box-shadow: 0 -5px 24px rgba(113, 113, 113, 0.13);
  display: flex;
  flex-flow: column;
  padding: 40px 20px;
  margin: 20px auto;
}
.authPage .authForm.fullScreen {
  width: 40%;
}
.authPage .input {
  border: 1px solid #cfa79d !important;
  margin: 10px auto;
  border-radius: 21px;
  height: 42px;
}
.primary-btn,
.primary-btn:hover,
.primary-btn:focus {
  background-color: #cfa79d;
  border-radius: 20px;
  color: #cfa79d;
  color: #fff;
  outline: none;
  border: none;
  padding: 0px 50px;
  margin: 5px;
}

.secondary-btn,
.secondary-btn:hover,
.secondary-btn a,
.secondary-btn a:hover,
.secondary-btn:focus {
  border: 1px solid #cfa79d;
  border-radius: 20px;
  outline: none;
  color: #cfa79d;
  padding: 0px 50px;
  margin: 5px;
}

@media (min-width: 768px) {
  .sessionCountdownContainer {
    position: fixed;
    top: 58px;
    right: 20px;
    z-index: 10;
    /* width: 100%; */
    text-align: center;
  }

  .sessionCountdownContainer.calendarSessionHeader {
    top: 90px;
  }

  .sessionCountdown {
    background: #fff;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    padding: 10px 25px;
    border: 1px solid #edeeef;
    border-top: none;
  }
}
.w-100 {
  width: 100%;
}
.authPage label {
  font-size: 18px;
  text-align: left;
  font-weight: 500;
  display: block;
}
.text-underline {
  text-decoration: underline;
}
@media (max-width: 768px) {
  .authPage .authForm,
  .guruDetails {
    box-shadow: none;
  }
  .authSolidSection {
    border-top-left-radius: 60px;
    border-top-right-radius: 60px;
  }
  .authPage .authForm,
  .authPage .authForm.fullScreen {
    width: 90%;
  }
  .sessionCountdownContainer {
    position: fixed;
    bottom: 0;
    z-index: 10;
    width: 100%;
    text-align: center;
    border: 1px solid #edeeef;
    z-index: 99999;
  }

  .sessionCountdown {
    background: #fff;
    padding: 5px 15px 0px;
  }
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}

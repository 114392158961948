#bookPages .modal-body .addSessionDetails {
  padding: 20px 50px;
}
#bookPages .modal-body .addSessionDetails .dateTimeTitle {
  color: #fff;
  font-weight: 600;
  font-size: 18px;
  margin: 20px;
}
#bookPages .nextSession {
  text-decoration: underline;
  font-weight: 500;
  cursor: pointer;
}
#bookPages .sessionTopic .optional {
  color: #b3b3b3;
}
#bookPages .promoCodeField::placeholder {
  color: #ececec;
}
#bookPages .promoApplyBtn .loader {
  width: 25px;
  height: 25px;
  border: 5px solid #f3f3f3;
  border-top: 5px solid #cfa79d;
}
.promoCodeContainer {
  position: relative;
}
.promoCodeContainer .promoCodeSign {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}
#bookPages .close:focus {
  outline: none;
  border: none;
}
#bookPages .modal-title .addSessionDetails > div {
  font-weight: 300;
}
#bookPages .modal-content {
  border: none;
  /* border-radius: 82px; */
  /* height: 550px; */
}
/* #bookPages #addSessionDetails {
position: relative;
top: 33%;
} */
#bookPages .modal-content .modal-header {
  border: none;
  padding: 20px 20px 20px 30px;
}
#bookPages .modal-body {
  padding: 0;
  width: 100%;
  margin: 0;
}
#bookPages .package-payment-form {
  height: 65vh;
  overflow-y: auto;
}
#bookPages .title,
#bookPages .Progress {
  display: inline-block;
}
#bookPages .Progress {
  margin-left: 40px;
}
#bookPages .Progress .filledStroke,
#bookPages .Progress .dottedStroke {
  display: inline-block;
  width: 100px;
  transform: translateY(-4px);
}
#bookPages .Progress .filledStroke {
  background-color: #cfa79d;
  height: 1px;
  margin: 0 5px;
  display: inline-block;
}
#bookPages .Progress .dottedStroke {
  border: 1px dashed #cfa79d;
  margin: 0 5px;
  display: inline-block;
}
#bookPages .title > div:first-of-type {
  font-weight: 400;
}
#bookPages .title > div:nth-of-type(2) {
  font-weight: lighter;
}
#bookPages .modal-body .addSessionDetails,
#bookPages .modal-body .confirmSessionDetails,
#bookPages .modal-body .sessionPayment {
  height: 100%;
  /* background-color: #171717; */
  background-color: #e8e8e8;
  /* border-radius: 82px; */
  border-radius: 0.3rem;
  padding: 20px;
  width: 100%;
}
#bookPages .modal-body .PersonInfo {
  margin: 10px auto;
  text-align: left;
}
#bookPages .modal-body .PersonInfo .initials {
  background-color: red;
  width: 70px;
  height: 70px;
  line-height: 70px;
  text-align: center;
  border-radius: 50%;
  display: inline-block;
  background-color: #d4f0e4;
  color: #898989;
  font-weight: 500;
}
#bookPages .modal-body .PersonInfo .info {
  display: inline-block;
  text-align: left;
  margin-left: 10px;
  transform: translateY(10px);
}
#bookPages .modal-body .PersonInfo .info .name {
  color: #cfa79d;
  font-weight: bold;
}
#bookPages .modal-body .PersonInfo .info .specialization {
  font-weight: 400;
  font-size: 13px;
}
#bookPages .modal-body .sessionInfo {
  background-color: #fff;
  border-radius: 5px;
  text-align: left;
  padding: 10px 30px;
  /* margin-bottom: 30px; */
  font-size: 13px;
  font-weight: 500;
  box-shadow: 6px 10px 15px rgba(40, 40, 40, 0.1);
  cursor: pointer;
}
#bookPages .modal-body .sessionInfo .time,
#bookPages .modal-body .sessionInfo .date {
  display: inline-block;
}

#bookPages .modal-body .sessionInfo .time {
  float: right;
  transform: translateY(20px);
}

#bookPages .modal-body .btnContainer {
  text-align: center;
  margin-top: 10px;
}
#bookPages .modal-body .btn {
  width: 150px;
  height: 30px;
  /* line-height: 30px; */
  padding: 0;
  /* border-radius: 20px; */
  font-size: 14px;
  font-weight: 500;
  /* box-shadow: 4px 6px 10px rgba(186, 186, 184, 0.4); */
  margin: 10px !important;
}

#bookPages .modal-body .btn:first-of-type {
  background-color: #fff;
  color: #cfa79d;
}
#bookPages .modal-body .btn:first-of-type:hover {
  background-color: #fafafa;
  color: #ca9d91;
}

#bookPages .modal-body .btn:nth-of-type(2) {
  background-color: #cfa79d;
  color: #fff;
}
#bookPages .modal-body .btn:nth-of-type(2):hover {
  background-color: #ca9d91;
  color: #fff;
}
/*End global section*/
/*Start add session details page*/

/*Start calendar customization*/
#bookPages .modal-body .addSessionDetails .datepicker-header {
  display: none;
}
#bookPages .modal-body .addSessionDetails .dayName,
#bookPages .modal-body .addSessionDetails .nextDayName,
#bookPages .modal-body .addSessionDetails .prevDayName {
  position: absolute;
  font-size: 22px;
  color: #474747;
  z-index: 3;
  top: 119px;
}
#bookPages .modal-body .addSessionDetails .nextDayName {
  top: 159px;
  opacity: 0.4;
}
#bookPages .modal-body .addSessionDetails .prevDayName {
  top: 80px;
  opacity: 0.4;
}
#bookPages .modal-body .addSessionDetails .datePicker .pickTimeSlot.lg {
  display: inline-block;
  width: 47%;
  text-align: center;
  font-weight: bold;
  transform: translateY(20px);
}
#bookPages .modal-body .addSessionDetails .datepicker {
  background-color: #e8e8e8 !important;
  position: relative;
  top: -20px;
  width: 100% !important;
}

#bookPages
  .modal-body
  .addSessionDetails
  .datepicker.ios
  .datepicker-viewport::after {
  background: linear-gradient(
    #e8e8e8,
    rgba(245, 245, 245, 0) 60%,
    rgba(245, 245, 245, 0) 30%,
    #e8e8e8
  );
}
#bookPages .modal-body .addSessionDetails .datepicker.ios {
  height: 250px;
}
#bookPages .modal-body .addSessionDetails .datepicker.ios .datepicker-navbar {
  border: none;
}

#bookPages .modal-body .addSessionDetails .datepicker-navbar-btn:hover,
#bookPages .modal-body .addSessionDetails .backButton:hover {
  border: 1px solid #cfa79d !important;
  background-color: #e8e8e8 !important;
  text-decoration: none;
}

#bookPages
  .modal-body
  .addSessionDetails
  .datepicker-navbar-btn:nth-of-type(2) {
  display: none;
}
#bookPages .modal-body .addSessionDetails .datePicker .divider:nth-of-type(2) {
  margin-top: 37px;
}
/*End calendar customiztion*/
/*Start time table*/
#bookPages .modal-body .addSessionDetails .timeStamp {
  width: 20%;
}
#bookPages .modal-body .addSessionDetails .timeSlots {
  height: 220px;
  overflow-y: scroll;
  overflow-x: hidden;
  /* transform: translateY(-20px); */
}
#bookPages .modal-body .addSessionDetails .timeSlots::-webkit-scrollbar-thumb {
  background: #cfa79d;
}
#bookPages .modal-body .addSessionDetails .timeSlots::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
#bookPages .modal-body .addSessionDetails .timeSlot {
  width: 100px !important;
  height: 40px;
  font-size: 14px;
  background-color: #dbdbdb;
  text-align: center;
  line-height: 40px;
  border-radius: 6px;
  margin: 10px auto;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
#bookPages .modal-body .addSessionDetails .active {
  background-color: #cfa79d !important;
  color: #eee !important;
}

#bookPages .modal-body .addSessionDetails .timeSlot:hover {
  background-color: #cfa79d;
  color: #707070;
}

#bookPages .modal-body .addSessionDetails .pickTimeSlot.sm {
  display: none;
}
/*End time table*/
#bookPages .modal-body .addSessionDetails .btn:first-of-type {
  background-color: #cfa79d;
  color: #fff;
}
#bookPages .modal-body .addSessionDetails .btn:first-of-type:hover {
  background-color: #ca9d91;
  color: #fff;
}
/*End add session details Page*/
/*Add session details*/

#bookPages .modal-body .sessionDateTime {
  margin-top: 20px;
  margin-bottom: 20px;
  position: relative;
}

#bookPages .modal-body .sessionTopic > div:first-of-type {
  color: #707070;
  font-size: 14px;
  font-weight: 500;
}
#bookPages .modal-body .sessionTopic .form-control {
  margin-top: 10px;
  resize: none;
  height: 95px;
}
#bookPages .modal-body .sessionTopic > div:nth-of-type(2) {
  color: #707070;
  margin: 20px 0;
  font-weight: 500;
  font-size: 14px;
}

#bookPages .modal-body .sessionTopic > div:nth-of-type(2) > span {
  margin-right: 10px;
  cursor: pointer;
}
/*End session details*/
/*Start session payment page*/

#bookPages .modal-body .total {
  text-align: left;
  color: #707070;
  font-size: 22px;
  font-weight: 500;
}
#bookPages .modal-body .total > span {
  color: #cfa79d;
}
#bookPages iframe {
  margin: 20px auto;
  width: 100%;
  border: none;
  height: 600px;
}

#sessionDetails .modal-body .sessionInfo {
  cursor: auto !important;
}

/*End session payment page*/
/*Start booking confirmed page*/
#bookPages .modal-body .bookingConfirmed .CompleteBookImg {
  text-align: center;
}

/* #bookPages .modal-body .bookingConfirmed .PersonInfo {
	margin-top: 80px;
} */

#bookPages .modal-body .bookingConfirmed .btn {
  /* margin-top: 20px; */
  background-color: #cfa79d;
  color: #fff;
}

#bookPages .modal-body .bookingConfirmed .bookingStatusMessage {
  text-align: center;
  font-family: "Montserrat", sans-serif;
  color: #505050;
  font-size: 25px;
}
#bookPages .package-payment-form {
  background: #e8e8e8;
  padding: 1rem;
}
#bookPages .package-card {
  width: 100%;
  height: max-content;
  cursor: pointer;
}
#bookPages .package-card.selected-package {
  border: 1px solid #cfa79d !important;
}
@media (max-width: 768px) {
  /* .fade.modal.show{
      position: absolute;
      bottom: 0;
   } */

  #bookPages .modal-content {
    height: auto !important;
  }
  #bookPages .modal-body .btn {
    margin: 0 5px;
  }

  #bookPages {
    width: 100%;
    max-width: 100%;
  }
  #bookPages .modal-body .total {
    position: relative;
    top: 15% !important;
    margin-bottom: 30px !important;
  }
  #bookPages .modal-content .modal-header {
    padding: 20px 20px 20px 30px;
  }
  #bookPages .filledStroke {
    width: 26px !important;
  }
  #bookPages .dottedStroke {
    width: 26px !important;
  }
  .modal-dialog {
    margin: 0 !important;
  }

  #bookPages .package-payment-form {
    height: 300px;
    overflow-y: auto;
  }

  #bookPages .modal-body .PersonInfo {
    text-align: left;
    margin: 20px 0 0 20px;
  }
  #bookPages .modal-body .sessionDateTime {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  #bookPages .modal-body .addSessionDetails,
  #bookPages .modal-body .confirmSessionDetails,
  #bookPages .modal-body .sessionPayment {
    border-radius: 0;
    border-bottom-left-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
  }
}

.calendar-nav-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  justify-content: space-around;
}
@media screen and (max-width: 780px) {
  .calendar-nav-button {
    display: none;
  }
}
.calendar-nav-button {
  background: none;
  border: none;
  filter: brightness(0) invert(24%) sepia(1%) saturate(0%) hue-rotate(158deg)
    brightness(95%) contrast(82%);
}
.calendar-nav-button:disabled {
  /* cursor: not-allowed; */
  opacity: 0.25;
  font-weight: 600;
}
.calendar-nav-button:focus {
  outline: none;
}
.calendar-nav-button .caret-icon {
  font-size: 48px;
  font-weight: 900;
  text-align: center;
  color: #b3b3b3;
  margin: 0;
}
.date-container-container {
  flex: 10;
  width: 270px;
  display: flex;
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  scroll-behavior: smooth;
}
.date-box-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  transition: 0.6s;
}

.availability-container {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  max-width: 500px;
  margin: 0 auto;
  max-height: 40vh;
  flex: 0 0 100%;
  max-width: 100%;
  /* overflow-y: scroll; */
}

.availability-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  /* padding: 25px 15px; */
  cursor: pointer;
  border-top: 1px solid #ccc;
}

.availability-row.selected {
  /* background-color: #b3b3b3; */
  background-color: #cfa79d;
}

.availability-container .availability-row:first-child {
  border-top: none;
}

.availability-row-time {
  transition: 0.2s;
  padding: 10px 15px !important;
}
.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.availability-row-time .availability-name {
  flex: 1;
  margin: 0 0 0 20px;
  font-weight: 400;
  font-size: 15px;
  /* color: #fff; */
  color: #474747;
  pointer-events: none;
}

.availability-row-time.selected .availability-name {
  color: #fff;
}
.availability-row-time.selected .caret-icon {
  color: #fff;
}

.availability-image {
  height: 60px;
  width: 60px;
  border-radius: 100%;
  background-color: gray;
}

.calendar-nav-button:focus {
  outline: none;
}
.calendar-nav-button .caret-icon {
  font-size: 48px;
  font-weight: 900;
  text-align: center;
  color: #b3b3b3;
  margin: 0;
}
.caret-icon {
  font-weight: 900;
  font-size: 20px;
  border: none;
  background: none;
  color: #474747;
  margin-bottom: 0;
  width: 15px;
  pointer-events: none;
}
#userDate {
  font-size: 18px;
  font-weight: bold;
  text-align: left;
  color: #474747;
  align-self: flex-start;
  margin-bottom: 10px;
}
.calendar-nav-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  justify-content: space-around;
}
.calendar-text-button {
  padding: 5px 10px;
  background-color: #000;
  font-size: 18px;
  font-weight: 500;
  color: #474747;
  border-radius: 10px;
  border: 1px solid #000;
  transition: 0.2s;
  margin: 10px 0;
  white-space: nowrap;
}
.calendar-text-button.hyper {
  margin-right: auto;
  font-size: 14px;
  border: none !important;
  background: none;
  text-decoration: underline;
}
.calendar-text-button.disabled {
  background-color: transparent;
  border: 1px solid #5d5d5d;
  color: #5d5d5d;
  cursor: not-allowed;
  text-decoration: none;
}
.calendar-text-button.disabled:hover {
  background-color: transparent;
  border: 1px solid #5d5d5d;
  color: #5d5d5d;
}
.calendar-text-button:focus {
  outline: none;
}
.date-box-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 5px 0;
  transition: 0.6s;
}
.date-box {
  display: flex;
  flex-direction: column;
  /* border: 1px solid #777; */
  border: 1px solid #cfa79d;
  border-radius: 10px;
  background-color: #eee;
  /* background-color: #e8e8e8; */
  color: #474747;
  /* color: #b3b3b3;
   background-color: #121212; */
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 0 3px 10px 3px;
  transition: 0.2s;
  width: 50px;
  height: 50px;
  -webkit-box-shadow: 0px 2px 2px 1px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 2px 2px 1px rgba(0, 0, 0, 0.1);
}
.date-box.today {
  /* border: 4px solid #cfa79d; */
  border: 3px solid #cfa79d;
}
.date-box.selected {
  background-color: #cfa79d;
  /* background-color: #fff; */
  border: 1px solid #cfa79d;
  color: #eee;
}
.date-day-name {
  font-weight: bold;
  pointer-events: none;
}
.date-day-number {
  pointer-events: none;
}
.shrink-space {
  transition: 1.5s;
}
.date-container-container {
  flex: 10;
  width: 270px;
  display: flex;
  overflow-x: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  scroll-behavior: smooth;
}
.date-container-container::-webkit-scrollbar {
  display: none;
}
.wizard-container {
  display: none;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
}
.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.timeSlotsContainer {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  /* min-width: 0; */
  /* word-wrap: break-word; */
  background-color: #fff;
  /* background-clip: border-box; */
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  width: 100%;
  /* background-color: #181818; */
  background-color: #e8e8e8;
  margin-bottom: 20px;
  /* border: 1px solid #171717; */
  /* -webkit-box-shadow: 0px 5px 10px 2px rgb(0 0 0 / 40%);
   box-shadow: 0px 5px 10px 2px rgb(0 0 0 / 40%); */
}
.priceBeforeDiscount {
  text-decoration: line-through !important;
  color: red !important;
}

.mySessions .title{
    color:#CFA79D;
    margin-bottom:40px;
}
.mySessions .sessionsSwitch{
    width: 250px;
    height: 50px;
    margin: auto;
    border-radius:30px;
    border:1px solid #CFA79D;
}
.mySessions .sessionsSwitch .item{
    width: 50%;
    height: 100%;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    color:#CFA79D;
    background:#fff;
    cursor: pointer;
}
.mySessions .sessionsSwitch .active{
    background: #CFA79D;
    color: #fff;
}

@media (min-width:768px){
    .mySessions{
        margin-top: 80px;
    }
    .mySessions .sessionsSwitch{
        width: 400px;
    }
}
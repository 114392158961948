.event-chatting-modal .chatting-container{
    width: 95%;
    background: #FAFAFA;
    margin: auto;
    border: 1px solid #707070;
    border-radius: 15px;
}

.event-chatting-modal .chatting-box{
	height: 200px;
	overflow-y: auto;
	width: 95%;
	margin: 10px auto;
	background: #fff;
	border-radius: 10px;
	border: 1px solid #CFA79D;
	text-align:left;
	padding: 1rem;
	-ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}



.event-chatting-modal .chatting-box::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}
.event-chatting-modal .chatting-box .initials{
	width: 40px;
	height: 40px;
	display: inline-block;
	border-radius: 50%;
	background: #e8e8e8;
	line-height: 40px;
    font-weight: 500;
	text-align: center;
}
.event-chatting-modal .chatting-box  .chatting-slot{
	display: flex;
	align-items: self-start;
}
.event-chatting-modal .send-box{
	width: 95%;
	margin: 10px auto;
	background: #fff;
	border-radius: 10px;
	border: 1px solid #CFA79D;
}
.event-chatting-modal .send-box textarea{
	height: 50px;
	margin-top: 10px;
	border: none;
	outline:none;
	border-radius: 15px;
	color: #CFA79D;
	resize: none;
	width: 100%;
}
.event-chatting-modal .isNotSender .msg-container{
	background: #CFA79D;
	color: #fff;
	border:1px solid #fff;
	overflow: auto;
}

.event-chatting-modal .send-box .send-button{
	border: none;
	background: #fff;
	outline: none;
}
.event-chatting-modal .send-box textarea::-webkit-scrollbar {
	display: none;
  }
  
/* Hide scrollbar for IE, Edge and Firefox */
.event-chatting-modal .send-box textarea {
	-ms-overflow-style: none;  /* IE and Edge */
	scrollbar-width: none;  /* Firefox */
}
.event-chatting-modal .send-box .divider{
	border:1px solid #EDDEDA
}


.event-chatting-modal .msg-container{
	height: fit-content;
	border-radius: 10px;
	border: 1px solid #CFA79D;
	display: flex;
	flex: 1;
}
@media (max-width: 769px) {
	.event-chatting-modal .chatting-box,.event-chatting-modal .send-box{
		width:100%;
	}
}
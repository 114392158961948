.accountTransactionHistory {
	height: calc(100vh - 165px);
	overflow-y: scroll;
}
.accountProfession::-webkit-scrollbar {
	display: none;
}

.accountTransactionHistory::-webkit-scrollbar {
	display: none;
}
.accountTransactionHistory #dateOfBirth {
	border: none;
}
.accountTransactionHistory .ant-space-item {
	margin-right: 10px;
}
.accountTransactionHistory .header {
	box-shadow: 0 12px 15px rgba(0, 0, 0, 0.13);
	border-radius: 25px;
	padding-bottom: 20px;
}
.accountTransactionHistory .header .title {
	padding: 10px;
	padding-top: 20px !important;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	font-weight: 500;
	font-size: 18px;
}
.accountTransactionHistory .header .title span {
	transform: scale(0.7);
	display: inline-block;
	margin-right: 10px;
}

.accountTransactionHistory .header .dateFilter {
	text-align: center;
	margin: 10px 0;
	color: #919191;
	font-weight: 500;
}
.accountTransactionHistory .header .dateFilter input {
	display: inline-block;
	width: 25%;
	margin: 0 10px;
	border: 1px solid #cfa79d;
}
/* .accountTransactionHistory .ant-space-item
{
	display: inline-block;
	margin: 0 10px;
	border: 1px solid #CFA79D;
	border-radius: 7px;
} */
.accountTransactionHistory .header .dateFilter .btn {
	background-color: #cfa79d;
	font-weight: bold;
	border-radius: 26px;
	color: #fff;
	text-decoration: none;
}
.accountTransactionHistory .body {
	text-align: center;
	margin-top: 10px;
}

.accountTransactionHistory .body .totallEarning,
.accountTransactionHistory .body .fromTo {
	color: #919191;
	font-weight: 500;
}

.accountTransactionHistory .body .totallEarning span,
.accountTransactionHistory .body .fromTo span:nth-of-type(3) {
	color: #cfa79d;
}

.accountTransactionHistory .body .fromTo span {
	color: #000;
	font-weight: 600;
}
@media (max-width: 768px) {
	.accountTransactionHistory {
		height: calc(100vh - 250px);
		padding-bottom: 30px;
	}
	.accountTransactionHistory #lastTime {
		margin-right: 33px !important;
	}
	.accountTransactionHistory .lastTime {
		margin-top: 10px;
		margin: 10px auto;
	}

	.accountTransactionHistory .header .dateFilter .btn {
		display: block;
		width: 40%;
		margin: 10px auto;
	}

	.accountTransactionHistory .header .dateFilter .form-control {
		display: block;
		width: 90%;
	}
	.accountTransactionHistory .header .dateFilter {
		text-align: center !important;
		padding-left: 20px;
	}
}

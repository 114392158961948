.plainNavbar .logo.sm ,.privacypolicy .logo.sm,.refundPolicy .logo.sm{
	display: none;
}
.plainNavbar .logo.lg ,.privacypolicy .logo.lg , .refundPolicy .logo.lg{
	margin: 20px;
}
.plainNavbar .divider ,.privacypolicy .divider,.refundPolicy .divider{
	background-color: #edeeef;
	height: 2px;
	width: 99%;
	margin: 20px auto;
}


@media (max-width: 769px) {
	.plainNavbar .logo.lg,.privacypolicy .logo.lg ,.refundPolicy .logo.lg{
		display: none;
	}
	.plainNavbar .logo.sm ,.privacypolicy .logo.sm, .refundPolicy .logo.sm{
		display: block;
		margin: 10px 20px;
	}
}

#videoPopup .modal-content {
	background-color: #fff;
	margin: 0 auto;
	position: relative;
}
#videoPopup .close {
	position: absolute;
	top: 10px;
	right: 48px;
}
#videoPopup video{
	width:100%
}

#videoPopup .title {
	font-size: 23px;
	font-weight: 600;
	margin: 0 30px;
}
/* #videoPopup .image {
	display: flex !important;
	justify-content: flex-end !important;
	transform: translate(7px, -7px);
} */

#videoPopup .infoContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 10px;
}
#videoPopup .infoContainer img {
	margin-right: 10px;
}
#videoPopup .userInfo {
	font-weight: bold;
	color: #cfa79d;
	font-size: 18px;
}

#videoPopup .button {
	border: none;
	background-color: transparent;
	border-radius: 26px !important;
	background-color: #fff !important ;
	color: #fff !important;
	/* width: 40% !important;
	height: 40px !important; */
	line-height: 15px !important;
	/* margin: 20px auto 0; */
	margin-top: 15px !important;
	margin-bottom: 8px !important;
	border: 1px solid #cfa79d !important;
	padding: 8px 20px !important;
	color: #cfa79d !important;
	box-shadow: 4px 6px 10px rgba(186, 186, 186, 0.4) !important;
}
#videoPopup .bookingBtn {
	border-radius: 26px !important;
	background-color: #cfa79d !important ;
	color: #fff !important;
	line-height: 15px !important;
	margin-top: 8px !important;
	margin-bottom: 8px !important;
	border: none !important;
	padding: 8px 13px !important;

	box-shadow: 4px 6px 10px rgba(186, 186, 186, 0.4) !important;
	margin-left: 15px;
}
#videoPopup .initials {
	background-color: #fbf7ec;
	color: #898989;
	width: 70px;
	height: 70px;
	line-height: 70px;
	text-align: right;
	font-size: 25px;
	font-weight: 500;
	border-radius: 50%;
}

#videoPopup .title > div:nth-of-type(2) {
	font-weight: lighter;
}

#videoPopup .modal-header {
	border-bottom: none;
}

#videoPopup .modal-body {
	background-color: #e8e8e8;
	padding: 20px 40px;
}

#videoPopup .video-react {
	padding-top: 0 !important;
	height: 250px !important;
}
@media (max-width: 768px) {
	#videoPopup .modal-content {
		border-radius: 0px;
		height: 100vh;
		border: none !important;
	}
	#videoPopup .modal-open.modal,
	#videoPopup .fade.modal.show {
		padding-right: 0 !important;
		padding-top: 0 !important;
	}

	#videoPopup {
		max-width: none !important;
	}
	#videoPopup .modal-body {
		border-bottom-left-radius: 0px;
		border-bottom-right-radius: 0px;
		padding-bottom: 50px;
	}
	.modal-dialog-centered {
		min-height: 0rem !important;
	}
	#videoPopup .actions {
		margin-top: 50px !important;
	}
}

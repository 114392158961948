.sessionCalendar .hamburgerIcon,
.sessionCalendar .filterIcon {
	cursor: pointer;
}

.sessionCalendar a {
	color: #fff;
}
.sessionCalendar a:hover {
	color: #fff;
	text-decoration: none;
}
.sessionCalendar .header {
	background-color: #cfa79d;
	height: 80px;
	color: #fff;
	text-align: center;
	padding: 10px;
	display: none;
}

.sessionCalendar .header > span:first-of-type {
	float: left;
}

.sessionCalendar .header > span:nth-of-type(2) {
	font-size: 20px;
	font-weight: bold;
	color: #fff;
}
.sessionCalendar .header > span:nth-of-type(3) {
	float: right;
}

.sessionCalendar .calendar {
	background-color: #cfa79d;
	color: #fff;
	height: 180px;
}

.sessionCalendar .nextSessionCounterBox {
	width: 300px;
	height: 140px;
	position: fixed;
	top: 60px;
	right: 20px;
	background-color: #fff;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
	border-radius: 20px;
	text-align: center;
}
.sessionCalendar .nextSessionCounterBox > div:first-of-type {
	margin-top: 10px;
	font-weight: bold;
	font-size: 12px;
	color: #cfa79d;
}

.sessionCalendar .odometer-block {
	margin-top: 10px;
	font-weight: bold;
	font-size: 25px;
	color: #cfa79d;
}
.sessionCalendar .red_text .odometer-block {
	color: red !important;
}
.sessionCalendar .odometer-block .hour::after {
	content: " :";
}

.sessionCalendar .odometer-block .min::after {
	content: " :";
}
.sessionCalendar .nextSessionCounterBox > div:nth-of-type(3).btn {
	width: 60px;
}
.sessionCalendar .nextSessionCounterBox .btn {
	border: 1px solid #cfa79d;
	border-radius: 60px;
	color: #cfa79d;
	font-size: 14px;
	padding: 0;
	width: 120px;
	height: 27px;
	margin: 10px;
}

.sessionCalendar .nextSessionCounterBox .btn:nth-of-type(2) {
	background-color: #cfa79d94;
	border: none;
}

.sessionCalendar .nextSessionCounterBox .btn:nth-of-type(2) {
	color: #fff;
}
.sessionCalendar .nextSessionCounterBox .btn.sessionActive {
	background-color: #cfa79d;
	color: #fbf7ec;
}

.sessionCalendar .row:first-of-type .col-12.col-md-6:first-of-type {
	padding: 0;
}
.sessionCalendar .calendar:nth-of-type(2) {
	height: 155px;
}
.sessionCalendar .margin {
	height: 25px;
	background-color: #464140;
}
.sessionCalendar .fullDate {
	margin-left: 25px;
	font-size: 13px;
	font-weight: bold;
	color: gray;
}
.sessionCalendar .calendarFooter {
	background-color: #fff;
	width: 100%;
	transform: translateY(-25px);
	transition: all 1s ease;
	padding: 20px;
}

.sessionPageCurve {
	transform: translateY(-21px);
	text-align: center;
	cursor: pointer;
}
@media (max-width: 769px) {
	.sessionCalendar {
		padding-bottom: 50vh;
	}
	.sessionCalendar .header {
		display: block;
	}
	.sessionCalendar .sessions {
		border-top-left-radius: 33px;
		border-top-right-radius: 33px;
	}
	.sessionCalendar .nextSessionCounterBox {
		top: 280px;
	}
}

@media (min-width: 769px) {
	.sessionCalendar {
		margin-top: 57px;
	}
}
/* .video-react,
.video-react video,
.video-react-poster {
	border-radius: 50%;
}

.video-react-big-play-button {
	width: 30px;
}
.video-react-big-play-button {
	background: transparent !important;
	border: none !important;
	line-height: 1.5em !important;
	width: 2em !important;
} */

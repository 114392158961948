.blog .blogs-section {
	width: 100%;
}

.blog .circlePlus-figure {
	width: 80px;
	height: 80px;
	margin: auto;
	cursor: pointer;
}
.blog .circlePlus-figure > img {
	width: 100%;
	height: 100%;
}
.blog .title {
	font-size: 18px;
	font-weight: bold;
	color: #cfa79d;
}
.blog .article-title {
	font-size: 22px;
	font-weight: 500;
	color: #3c3c3b;
}
.blog .icon-container {
	width: 20px;
}
.blog .icon-container > img {
	width: 100%;
}

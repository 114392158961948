.accountBookingMessage .heading {
	font-weight: bold;
	font-size: 23px;
	margin-top: 20px;
}
.accountBookingMessage .form {
	width: 35%;
	margin: 20px auto 0;
}
.accountBookingMessage .form .label {
	font-size: 20px;
	font-weight: 700;
}
.accountBookingMessage .form textarea {
	width: 100%;
	height: 150px;
	resize: none;
	border: 1px solid #cfa79d;
	margin-top: 20px;
}

.accountBookingMessage .btnContainer {
	text-align: center;
}
.accountBookingMessage .btn {
	border-radius: 26px;
	background-color: #cfa79d;
	color: #fff;
	width: 40%;
	height: 30px;
	line-height: 15px;
	font-weight: bold;
	margin: 20px auto;
	box-shadow: 4px 6px 10px rgba(186, 186, 186, 0.4);
}
.accountBookingMessage .btn:hover {
	color: #fff;
}
@media (max-width: 768px) {
	.accountBookingMessage .form {
		width: 100%;
	}
}
